/* Enhanced JobDetails.module.css */

.jobDetails {
  background-color: var(--light-foreground);
  border-radius: 12px;
  box-shadow: var(--shadow-lg-light);
  overflow: hidden;
}

[data-mui-color-scheme='dark'] .jobDetails {
  background-color: var(--dark-foreground);
  box-shadow: var(--shadow-lg-dark);
}

/* Header Section */
.title {
  background: linear-gradient(135deg, var(--light-primary), var(--light-accent));
  color: white;
  padding: 1.5rem 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}

[data-mui-color-scheme='dark'] .title {
  background: linear-gradient(135deg, var(--dark-primary), var(--dark-accent));
}

/* Grid Layout */
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 1.5rem;
}

/* Section Styles */
.section {
  background: var(--light-foreground-accent);
  border: 1px solid var(--light-border);
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  margin: 1rem;
}

.section:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md-light);
}

[data-mui-color-scheme='dark'] .section {
  background: var(--dark-foreground-accent);
  border-color: rgba(255, 255, 255, 0.1);
}

[data-mui-color-scheme='dark'] .section:hover {
  box-shadow: var(--shadow-md-dark);
}

.sectionTitle {
  color: var(--light-primary);
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0 0 1rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.sectionTitle::before {
  content: "";
  display: block;
  width: 4px;
  height: 1.1rem;
  background: var(--light-primary);
  border-radius: 2px;
}

[data-mui-color-scheme='dark'] .sectionTitle {
  color: var(--dark-secondary);
}

[data-mui-color-scheme='dark'] .sectionTitle::before {
  background: var(--dark-secondary-accent);
}

/* Cleaners Selection */

.cleanersSelection {
  background: var(--light-foreground-accent);
  border: 1px solid var(--light-border);
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  margin: 1rem;
}

.cleanersSelection:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md-light);
}

[data-mui-color-scheme='dark'] .cleanersSelection {
  background: var(--dark-foreground-accent);
  border-color: rgba(255, 255, 255, 0.1);
}

[data-mui-color-scheme='dark'] .cleanersSelection:hover {
  box-shadow: var(--shadow-md-dark);
}

.cleanersSelectionTitle {
  color: var(--light-primary);
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0 0 1rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.cleanersSelectionTitle::before {
  content: "";
  display: block;
  width: 4px;
  height: 1.1rem;
  background: var(--light-primary);
  border-radius: 2px;
}

[data-mui-color-scheme='dark'] .cleanersSelectionTitle {
  color: var(--dark-secondary);
}

[data-mui-color-scheme='dark'] .cleanersSelectionTitle::before {
  background: var(--dark-secondary-accent);
}

/* Status Container */
.statusContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Enhanced Select Styles */
.select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--light-border);
  border-radius: 8px;
  background-color: var(--light-foreground);
  color: var(--light-text);
  font-size: 1rem;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.select:focus {
  outline: none;
  border-color: var(--light-primary);
  box-shadow: 0 0 0 3px var(--light-focus-ring);
}

[data-mui-color-scheme='dark'] .select {
  background-color: var(--dark-dropdowns);
  border-color: var(--dark-divider);
  color: var(--dark-text);
}

/* Button Styles */
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  gap: 0.5rem;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.buttonPrimary {
  background: var(--light-primary);
  color: white;
}

.buttonPrimary:hover:not(:disabled) {
  filter: brightness(110%);
}

.buttonSecondary {
  background: var(--light-secondary);
  color: white;
}

.buttonSecondary:hover:not(:disabled) {
  filter: brightness(110%);
}

[data-mui-color-scheme='dark'] .buttonPrimary {
  background: var(--dark-primary);
}

[data-mui-color-scheme='dark'] .buttonSecondary {
  background: var(--dark-secondary);
}

/* Expandable Sections */
.expandableSection {
  border: 1px solid var(--light-border);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 1rem;
  margin: 1rem;
}

[data-mui-color-scheme='dark'] .expandableSection {
  border-color: var(--dark-border);
}

.expandableHeader {
  padding: 1rem 1.5rem;
  background: var(--light-foreground-accent);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.expandableHeader:hover {
  background: var(--light-accent);
  color: white;
}

[data-mui-color-scheme='dark'] .expandableHeader {
  background: var(--dark-foreground-accent);
}

[data-mui-color-scheme='dark'] .expandableHeader:hover {
  background: var(--dark-accent);
}

.expandableContent {
  padding: 1.5rem;
  background: var(--light-foreground);
}

[data-mui-color-scheme='dark'] .expandableContent {
  background: var(--dark-foreground);
}

/* Cleaner Cards */
.cleanersList {
  display: grid;
  gap: 1rem;
}

.cleanerItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: var(--light-foreground-accent);
  border-radius: 8px;
  border: 1px solid var(--light-border);
}

[data-mui-color-scheme='dark'] .cleanerItem {
  background: var(--dark-foreground);
  border-color: var(--dark-border);
}

/* Task Items */
.taskItem {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem;
  background: var(--light-foreground-accent);
  border-radius: 6px;
  border: 1px solid var(--light-border);
}

[data-mui-color-scheme='dark'] .taskItem {
  background: var(--dark-foreground-accent);
  border-color: var(--dark-border);
}

.taskCompleted {
  border-left: 4px solid var(--success);
}

/* Status Badges */
.statusBadge {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-weight: 500;
  font-size: 0.875rem;
  color: white;
}

.statusBadge::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: currentColor;
  margin-right: 0.5rem;
}

/* Completion Details */

.completionSection {
  margin: 1rem;
}

.completionTitle {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--light-text);
  margin-bottom: 1rem;
}

[data-mui-color-scheme='dark'] .completionTitle {
  color: var(--dark-text);
}

.completionGrid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

.completionSummary {
  background: var(--light-foreground);
  border: 1px solid var(--light-border);
  border-radius: 8px;
  padding: 1.5rem;
}

[data-mui-color-scheme='dark'] .completionSummary {
  background: var(--dark-foreground);
  border-color: rgba(255, 255, 255, 0.1);
}

.summaryDetails {
  display: grid;
  gap: 0.5rem;
}

.summaryRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  border-bottom: 1px solid var(--light-border);
}

[data-mui-color-scheme='dark'] .summaryRow {
  border-color: rgba(255, 255, 255, 0.1);
}

.summaryRow:last-child {
  border-bottom: none;
}

.summaryRow span:first-child {
  color: var(--light-text-light);
  font-weight: 500;
}

[data-mui-color-scheme='dark'] .summaryRow span:first-child {
  color: var(--dark-text-light);
}

/* Cleaners Section in Completion */
.cleanersSection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 1.5rem;
  background: var(--light-foreground);
  border: 1px solid var(--light-border);
  border-radius: 8px;
}

[data-mui-color-scheme='dark'] .cleanersSection {
  background: var(--dark-foreground);
  border-color: rgba(255, 255, 255, 0.1);
}

.cleanerCompletionCard {
  background: var(--light-foreground-accent);
  border: 1px solid var(--light-border);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
}

[data-mui-color-scheme='dark'] .cleanerCompletionCard {
  background: var(--dark-foreground-accent);
  border-color: rgba(255, 255, 255, 0.1);
}

.cleanerName {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--light-text);
  margin-bottom: 0.75rem;
}

[data-mui-color-scheme='dark'] .cleanerName {
  color: var(--dark-text);
}

.cleanerTimeDetails {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 0.75rem;
  margin: 0.75rem 0;
}

.cleanerTimeDetails > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background: var(--light-foreground);
  border-radius: 4px;
  border: 1px solid var(--light-border);
}

[data-mui-color-scheme='dark'] .cleanerTimeDetails > div {
  background: var(--dark-foreground);
  border-color: rgba(255, 255, 255, 0.1);
}

.cleanerNotes {
  background: var(--light-foreground-accent);
  padding: 0.75rem;
  border-radius: 4px;
  margin: 0.75rem 0;
  border: 1px solid var(--light-border);
}

[data-mui-color-scheme='dark'] .cleanerNotes {
  background: var(--dark-foreground-accent);
  border-color: rgba(255, 255, 255, 0.1);
}

.cleanerNotes h5 {
  margin: 0 0 0.5rem 0;
  color: var(--light-text);
  font-weight: 600;
}

[data-mui-color-scheme='dark'] .cleanerNotes h5 {
  color: var(--dark-text);
}

.ratingsContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.75rem;
}

.efficiencyChip {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-weight: 500;
  font-size: 0.875rem;
  color: white;
  min-width: 60px;
}

/* Completion Photos */
.completionNotes {
  margin: 1.5rem;
  padding: 1.5rem;
  background: var(--light-foreground-accent);
  border: 1px solid var(--light-border);
  border-radius: 8px;
}

[data-mui-color-scheme='dark'] .completionNotes {
  background: var(--dark-foreground-accent);
  border-color: rgba(255, 255, 255, 0.1);
}

.completionLabel {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--light-text);
  margin-bottom: 1rem;
}

[data-mui-color-scheme='dark'] .completionLabel {
  color: var(--dark-text);
}

/* Add-on Cards */

.addonSection {
  background: var(--light-foreground-accent);
  border: 1px solid var(--light-border);
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  margin: 1rem;
}

.addonSection:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md-light);
}

[data-mui-color-scheme='dark'] .addonSection {
  background: var(--dark-foreground-accent);
  border-color: rgba(255, 255, 255, 0.1);
}

[data-mui-color-scheme='dark'] .addonSection:hover {
  box-shadow: var(--shadow-md-dark);
}

.addonSectionTitle {
  color: var(--light-primary);
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0 0 1rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.addonSectionTitle::before {
  content: "";
  display: block;
  width: 4px;
  height: 1.1rem;
  background: var(--light-primary);
  border-radius: 2px;
}

[data-mui-color-scheme='dark'] .addonSectionTitle {
  color: var(--dark-secondary);
}

[data-mui-color-scheme='dark'] .addonSectionTitle::before {
  background: var(--dark-secondary-accent);
}

.addonItem {
  background: var(--light-foreground);
  border: 1px solid var(--light-border);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
}

[data-mui-color-scheme='dark'] .addonItem {
  background: var(--dark-foreground);
  border-color: var(--dark-border);
}

.addonHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.initialCleanBadge {
  background: linear-gradient(135deg, var(--light-primary), var(--light-accent));
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  margin-top: 1rem;
}

[data-mui-color-scheme='dark'] .initialCleanBadge {
  background: linear-gradient(135deg, var(--dark-primary), var(--dark-accent));
}

/* Cost Breakdown */
.costBreakdown {
  background: var(--light-foreground-accent);
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1rem;
}

[data-mui-color-scheme='dark'] .costBreakdown {
  background: var(--dark-foreground);
}

.totalCost {
  font-weight: 600;
  color: var(--light-primary);
  border-top: 1px dashed var(--light-border);
  padding-top: 0.5rem;
  margin-top: 0.5rem;
}

[data-mui-color-scheme='dark'] .totalCost {
  color: var(--success);
  border-color: var(--dark-border);
}

.divider {
  border-top: 1px solid var(--light-border);
  margin: 1rem 0;
}

[data-mui-color-scheme='dark'] .divider {
  border-color: rgba(255, 255, 255, 0.1);
}