/* CustomerManagement.module.css */
.container {
    padding: 24px;
    font-family: var(--font-family-base);
  }
  
  .title {
    color: var(--light-text);
    margin-bottom: 24px;
  }
  
  [data-mui-color-scheme="dark"] .title {
    color: var(--dark-text);
  }

  .searchContainer {
    margin-bottom: 24px;
  }
  
  .searchField {
    background-color: var(--light-foreground);
    border-radius: var(--border-radius-base);
  }
  
  [data-mui-color-scheme="dark"] .searchField {
    background-color: var(--dark-text-area);
  }

  .uploadSection {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .csvInfo {
    color: var(--light-text-light);
    white-space: nowrap;
  }
  
  [data-mui-color-scheme="dark"] .csvInfo {
    color: var(--dark-text-light);
  }
  
  .actions {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .createButton,
  .uploadButton {
    background-color: var(--light-primary);
    color: white;
    transition: background-color var(--transition-duration) var(--transition-ease);
  }
  
  .createButton:hover,
  .uploadButton:hover {
    background-color: var(--light-accent);
  }
  
  [data-mui-color-scheme="dark"] .createButton,
  [data-mui-color-scheme="dark"] .uploadButton {
    background-color: var(--dark-primary);
  }
  
  [data-mui-color-scheme="dark"] .createButton:hover,
  [data-mui-color-scheme="dark"] .uploadButton:hover {
    background-color: var(--dark-accent);
  }
  
  .customersList {
    background-color: var(--light-foreground);
    border-radius: var(--border-radius-base);
    box-shadow: var(--shadow-sm-light);
    margin-bottom: 24px;
  }
  
  [data-mui-color-scheme="dark"] .customersList {
    background-color: var(--dark-foreground);
    box-shadow: var(--shadow-sm-dark);
  }
  
  .customerItem {
    border-bottom: 1px solid var(--light-border);
    transition: background-color var(--transition-duration) var(--transition-ease);
  }
  
  .customerItem:last-child {
    border-bottom: none;
  }
  
  [data-mui-color-scheme="dark"] .customerItem {
    border-bottom-color: var(--dark-border);
  }
  
  .customerItem:hover {
    background-color: var(--light-foreground-accent);
  }
  
  [data-mui-color-scheme="dark"] .customerItem:hover {
    background-color: var(--dark-foreground-accent);
  }
  
  .customerText {
    color: var(--light-text);
  }
  
  [data-mui-color-scheme="dark"] .customerText {
    color: var(--dark-text);
  }
  
  .itemActions {
    display: flex;
    gap: 8px;
  }
  
  .editButton,
  .deleteButton {
    color: var(--light-text-light);
    transition: color var(--transition-duration) var(--transition-ease);
  }
  
  .editButton:hover {
    color: var(--light-primary);
  }
  
  .deleteButton:hover {
    color: var(--error);
  }
  
  [data-mui-color-scheme="dark"] .editButton,
  [data-mui-color-scheme="dark"] .deleteButton {
    color: var(--dark-text-light);
  }
  
  [data-mui-color-scheme="dark"] .editButton:hover {
    color: var(--dark-primary);
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    padding: 16px;
    background-color: var(--light-foreground);
    border-top: 1px solid var(--light-border);
  }
  
  [data-mui-color-scheme="dark"] .pagination {
    background-color: var(--dark-foreground);
    border-top-color: var(--dark-border);
  }
  
  .editForm {
    min-width: 400px;
    padding: 16px 0;
  }
  
  .loader {
    display: flex;
    justify-content: center;
    padding: 48px;
  }
  
  .alert {
    margin: 24px;
  }
  
  /* Dialog overrides */
  :global(.MuiDialog-paper) {
    background-color: var(--light-background);
  }
  
  [data-mui-color-scheme="dark"] :global(.MuiDialog-paper) {
    background-color: var(--dark-background);
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .container {
      padding: 16px;
    }
  
    .actions {
      flex-direction: column;
    }
  
    .editForm {
      min-width: 300px;
    }

    .uploadSection {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      }
    
      .csvInfo {
        margin-left: 0;
      }
  }

  .MuiAutocomplete-popper li:first-of-type {
    padding-top: 8px;
  }

  .google-places-autocomplete {
    position: relative;
    z-index: 1050;
  }
  
  .google-places-autocomplete .MuiTextField-root {
    width: 100%;
  }
  
  .pac-container {
    z-index: 1500 !important;
  }