/* src/styles/CreateCustomerModal.module.css */

/* Add these rules at the top of your CSS file */
:global(.MuiDialog-root) {
  z-index: 1400 !important;
}

:global(.MuiDialog-container) {
  z-index: 1400 !important;
}

/* Base Modal Styling */
:global(.MuiDialog-paper) {
  background-color: var(--light-foreground);
  border-radius: 12px;
  box-shadow: var(--shadow-lg-light);
  overflow: hidden;
  margin: 32px;
  max-height: calc(100% - 64px);
}

/* Title Section with Gradient */
.modalTitle {
  background: linear-gradient(135deg, var(--light-primary), var(--light-accent));
  color: white;
  padding: 1.5rem 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}

/* Content Layout */
.modalContent {
  position: relative;
  padding: 1.5rem;
  display: grid;
  gap: 1.5rem;
}

/* Form Sections */
.formSection {
  background: var(--light-foreground-accent);
  border: 1px solid var(--light-border);
  border-radius: 8px;
  padding: 1.5rem;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.formSection:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md-light);
}

/* Section Headers */
.sectionTitle {
  color: var(--light-primary);
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0 0 1rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.sectionTitle::before {
  content: "";
  display: block;
  width: 4px;
  height: 1.1rem;
  background: var(--light-primary);
  border-radius: 2px;
}

/* Input Fields */
.inputField {
  background-color: var(--light-background);
  border: 1px solid var(--light-border);
  border-radius: 8px;
  padding: 0.25rem;
  width: 100%;
  transition: all 0.2s ease;
}

.inputField:hover {
  background-color: var(--light-foreground-accent);
}

.inputField:focus {
  border-color: var(--light-primary);
  box-shadow: 0 0 0 2px var(--form-input-focus-ring);
}

/* Address Grid */
.addressGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
}

/* Enhanced Google Places Autocomplete */
.googlePlacesAutocomplete {
  position: relative;
  z-index: 1401 !important;
}

:global(.pac-container) {
  z-index: 1402 !important;
  background-color: var(--light-foreground);
  box-shadow: var(--shadow-lg-light);
  border-radius: 8px;
  border: 1px solid var(--light-border);
  padding: 8px 0;
  margin-top: 4px;
  font-family: inherit;
}

:global(.pac-item) {
  padding: 12px 16px;
  cursor: pointer;
  color: var(--light-text);
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
  border-top: none;
}

:global(.pac-item:hover) {
  background: linear-gradient(to right, var(--light-foreground-accent), transparent);
}

:global(.pac-icon) {
  display: none;
}

:global(.pac-item-query) {
  font-size: 14px;
  color: var(--light-text);
  font-weight: 500;
  padding-right: 8px;
}

:global(.pac-matched) {
  color: var(--light-primary);
  font-weight: 600;
}

:global(.pac-secondary-text) {
  font-size: 13px;
  color: var(--light-text-light);
}

/* Dark mode styles for Google Places */
[data-mui-color-scheme="dark"] :global(.pac-container) {
  background-color: var(--dark-foreground);
  border-color: var(--dark-border);
  box-shadow: var(--shadow-lg-dark);
}

[data-mui-color-scheme="dark"] :global(.pac-item) {
  color: var(--dark-text);
}

[data-mui-color-scheme="dark"] :global(.pac-item:hover) {
  background: linear-gradient(to right, var(--dark-foreground-accent), transparent);
}

[data-mui-color-scheme="dark"] :global(.pac-item-query) {
  color: var(--dark-text);
}

[data-mui-color-scheme="dark"] :global(.pac-matched) {
  color: var(--dark-primary);
}

[data-mui-color-scheme="dark"] :global(.pac-secondary-text) {
  color: var(--dark-text-light);
}

/* Add a custom location icon before each address */
:global(.pac-item)::before {
  content: '';
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%233b8de4'%3E%3Cpath d='M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.8;
}

[data-mui-color-scheme="dark"] :global(.pac-item)::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%236baaed'%3E%3Cpath d='M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z'/%3E%3C/svg%3E");
}

/* Input field specific styles */
.addressInput {
  composes: inputField;
  padding-left: 40px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%233b8de4'%3E%3Cpath d='M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z'/%3E%3C/svg%3E");
  background-position: 12px center;
  background-repeat: no-repeat;
  background-size: 20px;
}

[data-mui-color-scheme="dark"] .addressInput {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%236baaed'%3E%3Cpath d='M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z'/%3E%3C/svg%3E");
}

/* Modal Actions */
.modalActions {
  padding: 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  background: var(--light-foreground-accent);
  border-top: 1px solid var(--light-border);
}

/* Dark Mode Styles */
[data-mui-color-scheme="dark"] :global(.MuiDialog-paper) {
  background-color: var(--dark-foreground);
  box-shadow: var(--shadow-lg-dark);
}

[data-mui-color-scheme="dark"] .modalTitle {
  background: linear-gradient(135deg, var(--dark-primary), var(--dark-accent));
}

[data-mui-color-scheme="dark"] .formSection {
  background: var(--dark-foreground-accent);
  border-color: var(--dark-border);
}

[data-mui-color-scheme="dark"] .sectionTitle {
  color: var(--dark-secondary);
}

[data-mui-color-scheme="dark"] .sectionTitle::before {
  background: var(--dark-secondary-accent);
}

[data-mui-color-scheme="dark"] .inputField {
  background-color: var(--dark-text-area);
  border-color: var(--dark-border);
}

[data-mui-color-scheme="dark"] .inputField:hover {
  background-color: var(--dark-text-hover);
}

[data-mui-color-scheme="dark"] :global(.pac-container) {
  background-color: var(--dark-dropdowns);
  border-color: var(--dark-border);
}

[data-mui-color-scheme="dark"] .modalActions {
  background: var(--dark-foreground-accent);
  border-color: var(--dark-border);
}

/* Animation */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modalContent {
  animation: slideIn 0.3s ease-out;
}

/* Custom fields section */
.customFields {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Notes section */
.notesSection {
  margin-top: 24px;
}

/* Custom Scrollbar */
.modalContent::-webkit-scrollbar {
  width: 8px;
}

.modalContent::-webkit-scrollbar-track {
  background: transparent;
}

.modalContent::-webkit-scrollbar-thumb {
  background-color: var(--light-text-lighter);
  border-radius: 4px;
}

[data-mui-color-scheme="dark"] .modalContent::-webkit-scrollbar-thumb {
  background-color: var(--dark-text-lighter);
}

/* Cancel Button Styles */
.cancelButton {
  background-color: var(--error) !important;
  color: white !important;
  border: 1px solid var(--error) !important;
  transition: all 0.2s ease;
}

.cancelButton:hover {
  background-color: #f06c6c !important; /* Increase visibility */
  border-color: var(--error) !important;
  box-shadow: var(--shadow-md-light) !important;
}

[data-mui-color-scheme="dark"] .cancelButton {
  background-color: var(--error) !important;
  color: white !important;
  border: 1px solid var(--error) !important;
  transition: all 0.2s ease;
}

[data-mui-color-scheme="dark"] .cancelButton:hover {
  background-color: #f06c6c !important; /* Increase visibility */
  border-color: var(--error) !important;
  box-shadow: var(--shadow-md-dark) !important;
}

/* Submit Button Styles */
.submitButton {
  background: linear-gradient(135deg, var(--light-primary), var(--light-accent)) !important;
  color: white !important;
  transition: all 0.2s ease;
}

.submitButton:hover {
  background: linear-gradient(135deg, var(--light-accent), var(--light-primary)) !important;
  box-shadow: var(--shadow-md-light) !important;
}

[data-mui-color-scheme="dark"] .submitButton {
  background: linear-gradient(135deg, var(--dark-primary), var(--dark-accent)) !important;
  color: white !important;
  transition: all 0.2s ease;
}

[data-mui-color-scheme="dark"] .submitButton:hover {
  background: linear-gradient(135deg, var(--dark-accent), var(--dark-primary)) !important;
  box-shadow: var(--shadow-md-dark) !important;
}

.validationAlert {
  margin-bottom: 16px;
  border-radius: 8px;
  animation: slideIn 0.3s ease-out;
}
