/* EmailPreviewModal.module.css */

.emailModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
  padding: 1rem;
}

.emailModalContent {
  background-color: var(--light-background);
  color: var(--light-text);
  padding: 2rem;
  border-radius: var(--border-radius-base);
  max-width: 800px;
  width: 100%;
  max-height: 90vh;
  position: relative;
  box-shadow: var(--shadow-lg-light);
  border: 1px solid var(--light-border);
  transition: all var(--transition-duration) var(--transition-ease);
  display: flex;
  flex-direction: column;
}

.emailModalTitle {
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--light-text);
  flex-shrink: 0;
}

.emailForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  padding-right: 1rem;
}

.formGroup {
  flex: 1;
  margin-bottom: 1rem;
}

.formGroup label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--light-text);
  font-size: 0.875rem;
  font-weight: 500;
}

.templateSelect {
  width: 100%;
  padding: 0.75rem;
  background-color: var(--light-foreground);
  color: var(--light-text);
  border: 1px solid var(--light-border);
  border-radius: var(--border-radius-base);
  font-size: 0.875rem;
  transition: all var(--transition-duration) var(--transition-ease);
}

.subjectInput {
  width: 100%;
  padding: 0.75rem;
  background-color: var(--light-foreground);
  color: var(--light-text);
  border: 1px solid var(--light-border);
  border-radius: var(--border-radius-base);
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.editorContainer {
  position: relative;
  margin-bottom: 20px;
}

.editorContainer :global(.quill) {
  background-color: var(--light-foreground);
  border: 1px solid var(--light-border);
  border-radius: var(--border-radius-base);
}

.editorContainer :global(.ql-toolbar) {
  position: relative;
  top: 0;
  z-index: 10;
  border-top-left-radius: var(--border-radius-base);
  border-top-right-radius: var(--border-radius-base);
  border-bottom: 1px solid var(--light-border);
  background-color: var(--light-foreground-accent);
}

.editorContainer :global(.ql-picker) {
  position: relative;
}

.editorContainer :global(.ql-picker.ql-lineHeight) {
  min-width: 120px;  /* Adjust this value as needed */
}

.editorContainer :global(.ql-picker.ql-lineHeight .ql-picker-label) {
  padding-right: 25px !important;  /* Make room for the arrow */
}

.editorContainer :global(.ql-picker-options) {
  position: absolute !important;
  top: 100% !important;
  left: 0 !important;
  margin-top: 5px;
}

.editorContainer :global(.ql-toolbar .ql-stroke) {
  stroke: white;
}

.editorContainer :global(.ql-toolbar .ql-fill) {
  fill: white;
}

.editorContainer :global(.ql-toolbar .ql-picker) {
  color: white;
}

.editorContainer :global(.ql-toolbar .ql-picker-label) {
  color: white;
}

.editorContainer :global(.ql-snow.ql-toolbar button:hover),
.editorContainer :global(.ql-snow .ql-toolbar button:hover) {
  color: white;
}

.editorContainer :global(.ql-container) {
  border-bottom-left-radius: var(--border-radius-base);
  border-bottom-right-radius: var(--border-radius-base);
  min-height: 200px;
}

/* Fix line height preview */
.editorContainer :global([style*="line-height"]) {
  line-height: inherit !important;
}

.editorContainer :global(.ql-editor p) {
  line-height: inherit;
}

.editorContainer :global(.ql-editor) {
  line-height: 1;  /* default line height */
}

.editorContainer :global(.ql-picker.ql-lineHeight .ql-picker-label::before),
.editorContainer :global(.ql-picker.ql-lineHeight .ql-picker-item::before) {
  content: 'Line Height';
}

.editorContainer :global(.ql-picker.ql-lineHeight .ql-picker-label[data-value="1"]::before),
.editorContainer :global(.ql-picker.ql-lineHeight .ql-picker-item[data-value="1"]::before) {
  content: 'Single';
}

.editorContainer :global(.ql-picker.ql-lineHeight .ql-picker-label[data-value="1.5"]::before),
.editorContainer :global(.ql-picker.ql-lineHeight .ql-picker-item[data-value="1.5"]::before) {
  content: '1.5';
}

.editorContainer :global(.ql-picker.ql-lineHeight .ql-picker-label[data-value="2"]::before),
.editorContainer :global(.ql-picker.ql-lineHeight .ql-picker-item[data-value="2"]::before) {
  content: 'Double';
}

.editorContainer :global(.ql-picker.ql-lineHeight .ql-picker-label[data-value="2.5"]::before),
.editorContainer :global(.ql-picker.ql-lineHeight .ql-picker-item[data-value="2.5"]::before) {
  content: '2.5';
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--light-border);
  flex-shrink: 0;
}

.button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: var(--border-radius-base);
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  transition: background-color var(--transition-duration) var(--transition-ease);
}

.buttonPrimary {
  background-color: var(--light-primary);
  color: white;
}

.buttonPrimary:hover {
  background-color: var(--light-accent);
}

.buttonSecondary {
  background-color: var(--light-secondary);
  color: white;
}

.buttonSecondary:hover {
  background-color: var(--dark-secondary-accent);
}

/* Dark Mode */
[data-mui-color-scheme='dark'] .emailModalContent {
  background-color: var(--dark-background);
  color: var(--dark-text);
  box-shadow: var(--shadow-lg-dark);
  border-color: var(--dark-border);
}

[data-mui-color-scheme='dark'] .emailModalTitle {
  color: var(--dark-text);
}

[data-mui-color-scheme='dark'] .formGroup label {
  color: var(--dark-text);
}

[data-mui-color-scheme='dark'] .templateSelect,
[data-mui-color-scheme='dark'] .subjectInput {
  background-color: var(--dark-text-area);
  color: var(--dark-text);
  border-color: var(--dark-border);
}

[data-mui-color-scheme='dark'] .editorContainer :global(.quill) {
  background-color: var(--dark-text-area);
  border-color: var(--dark-border);
}

[data-mui-color-scheme='dark'] .editorContainer :global(.ql-toolbar) {
  background-color: var(--dark-foreground-accent);
  border-color: var(--dark-border);
}

[data-mui-color-scheme='dark'] .editorContainer :global(.ql-toolbar .ql-stroke) {
  stroke: white;
}

[data-mui-color-scheme='dark'] .editorContainer :global(.ql-toolbar .ql-fill) {
  fill: white;
}

[data-mui-color-scheme='dark'] .editorContainer :global(.ql-toolbar .ql-picker) {
  color: white;
}

[data-mui-color-scheme='dark'] .editorContainer :global(.ql-toolbar .ql-picker-label) {
  color: white;
}

[data-mui-color-scheme='dark'] .editorContainer :global(.ql-container) {
  border-color: var(--dark-border);
}

[data-mui-color-scheme='dark'] .buttonPrimary {
  background-color: var(--dark-primary);
}

[data-mui-color-scheme='dark'] .buttonPrimary:hover {
  background-color: var(--dark-accent);
}

[data-mui-color-scheme='dark'] .buttonSecondary {
  background-color: var(--dark-secondary);
}

[data-mui-color-scheme='dark'] .buttonSecondary:hover {
  background-color: var(--dark-secondary-accent);
}

[data-mui-color-scheme='dark'] .editorContainer :global(.ql-picker-options) {
  background-color: var(--dark-text-area) !important;
}

[data-mui-color-scheme='dark'] .editorContainer :global(.ql-picker-item) {
  color: var(--dark-text) !important;
}

[data-mui-color-scheme='dark'] .editorContainer :global(.ql-picker-item:hover) {
  background-color: var(--dark-accent) !important;
  color: white !important;
}

[data-mui-color-scheme='dark'] .editorContainer :global(.ql-lineHeight) {
  color: white !important;
}

[data-mui-color-scheme='dark'] .editorContainer :global(.ql-picker-label) {
  border-color: var(--dark-border) !important;
}

[data-mui-color-scheme='dark'] .editorContainer :global(.ql-picker-label::before) {
  color: white !important;
}

[data-mui-color-scheme='dark'] .editorContainer :global(.ql-picker.ql-expanded .ql-picker-label) {
  border-color: var(--dark-border) !important;
}

[data-mui-color-scheme='dark'] .editorContainer :global(.ql-picker.ql-expanded .ql-picker-options) {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}