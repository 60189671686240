/* Customer Profile Management */

.profileContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
 
  .profileHeader {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
  }

  .backButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: none;
    background: none;
    color: var(--light-primary);
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s ease;
  }
  
  .backButton:hover {
    color: var(--light-accent);
  }
  
  .backIcon {
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .customerName {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    color: var(--light-text);
  }

  .topSection {
    display: flex;
    justify-content: space-between;
    gap: 24px; /* Increased gap */
  }
  
  .topLeftCard, .topRightCard {
    flex: 1;
    margin: 0; /* Remove margin since we're using gap */
  }
  
  .metricsRow {
    display: flex;
    justify-content: space-between;
    gap: 24px; /* Increased gap */
    flex-wrap: wrap;
  }
  
  .jobInfoCard {
    margin: 16px 0px;
  }
  
  /* Add this new class */
  .statsBox {
    flex: 1;
    min-width: 200px;
    text-align: center;
    padding: 16px;
  }
  
  .servicesCard {
    margin: 16px 0px;
  }
 
  .container {
    width: 100%;
    max-width: 1600px; /* Increased from 1200px */
    margin: 0 auto;
    padding: 16px 24px; /* Adjusted padding */
  }
  
  .tabs {
    display: flex;
    gap: 2px;
    background-color: var(--light-foreground);
    padding: 2px;
    border-radius: 8px;
    margin-bottom: 24px;
    box-shadow: var(--shadow-sm);
    width: fit-content; /* Make tabs container only as wide as needed */
  }
  
  [data-mui-color-scheme='dark']  .tabs {
    background-color: var(--dark-foreground);
  }
  
  .tabButton {
    padding: 8px 16px; /* Reduced from 12px 24px */
    border: none;
    background: none;
    cursor: pointer;
    font-size: 14px; /* Reduced from 16px */
    font-weight: 500;
    color: var(--light-text);
    border-radius: 6px;
    transition: all 0.2s ease;
    min-width: 120px; /* Added to ensure consistent width */
  }
  
  [data-mui-color-scheme='dark']  .tabButton {
    color: var(--dark-text);
  }
  
  .tabButton:hover:not(.activeTab) {
    background-color: rgba(0, 0, 0, 0.04);
  }
  
  [data-mui-color-scheme='dark']  .tabButton:hover:not(.activeTab) {
    background-color: rgba(255, 255, 255, 0.04);
  }
  
  .activeTab {
    background-color: var(--light-primary);
    color: white;
  }
  
  [data-mui-color-scheme='dark']  .activeTab {
    background-color: var(--dark-primary);
  }
  
  .tabContent {
    margin-top: 24px;
  }
 
  
  /* Service Cards in EditRecurringService */

  /* Service Cards Container */
  .serviceCards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr)); /* Slightly wider cards */
    gap: 1.5rem; /* Increased gap */
    width: 100%;
    margin-top: 1rem;
  }

/* Individual Service Card */
.serviceCard {
  background-color: var(--light-foreground);
  border: 1px solid var(--light-border);
  border-radius: var(--border-radius-base);
  transition: all var(--transition-duration) var(--transition-ease);
  position: relative;
  overflow: hidden;
}

[data-mui-color-scheme='dark'] .serviceCard {
  background-color: var(--dark-foreground);
  border-color: var(--dark-border);
}

/* Selected Card State */
.selectedCard {
  border: 2px solid var(--light-primary);
  box-shadow: var(--shadow-md-light);
}

[data-mui-color-scheme='dark'] .selectedCard {
  border-color: var(--dark-primary);
  box-shadow: var(--shadow-md-dark);
}

/* Hover Effects */
.serviceCard:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-lg-light);
  border-color: var(--light-accent);
}

[data-mui-color-scheme='dark'] .serviceCard:hover {
  box-shadow: var(--shadow-lg-dark);
  border-color: var(--dark-accent);
}

/* Active State */
.serviceCard:active {
  transform: translateY(0);
  box-shadow: var(--shadow-sm-light);
}

[data-mui-color-scheme='dark'] .serviceCard:active {
  box-shadow: var(--shadow-sm-dark);
}

/* Service Type Badge */
.serviceCard::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 2rem 2rem 0;
  border-color: transparent var(--light-accent) transparent transparent;
  transition: border-color var(--transition-duration) var(--transition-ease);
}

[data-mui-color-scheme='dark'] .serviceCard::before {
  border-color: transparent var(--dark-accent) transparent transparent;
}

/* Responsive Design */
@media (max-width: 600px) {
  .serviceCards {
    grid-template-columns: 1fr;
  }
  
  .serviceCard {
    margin-bottom: 0.5rem;
  }
}

/* Card Content Layout */
.serviceCardContent {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.serviceCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.serviceCardBody {
  flex: 1;
}

.serviceCardFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--light-border);
}

[data-mui-color-scheme='dark'] .serviceCardFooter {
  border-top-color: var(--dark-border);
}

/* Animation for Selection */
@keyframes selectPulse {
  0% {
    box-shadow: 0 0 0 0 var(--light-focus-ring);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(70, 153, 235, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(70, 153, 235, 0);
  }
}

.selectedCard {
  animation: selectPulse 1s;
}

[data-mui-color-scheme='dark'] .selectedCard {
  animation: selectPulseDark 1s;
}

@keyframes selectPulseDark {
  0% {
    box-shadow: 0 0 0 0 var(--dark-focus-ring);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(70, 153, 235, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(70, 153, 235, 0);
  }
}

[data-mui-color-scheme='dark'] .backButton {
  color: var(--dark-text);
}

[data-mui-color-scheme='dark'] .backButton:hover {
  color: var(--dark-accent);
}

[data-mui-color-scheme='dark'] .customerName {
  color: var(--dark-text);
}