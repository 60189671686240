.actionCenter {
  gap: 16px;
  padding: 16px;
  border-radius: 12px;
  background-color: #191C20;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 232px;
  min-height: 94.5vh;
  max-height: 94.5vh;
  margin: 10px;
}

.actionCenterHeading {
  font-size: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
}

.headingActionButton {
 display: flex;
 flex-direction: row;
 align-items: center;
 cursor: pointer;
 padding: 4px;
 border-radius: 4px;
 width: max-content;
 gap: 8px;
}

.headingActionButtonSelected {
  background-color: #9DCBFC;
  color: #191C20;
}

.headingActionButtonLabel {
  font-size: 12px;
}

.actionCenterButtonBar {
  gap: 8px;
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
}

.actionCenterList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
}