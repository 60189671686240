/********************************************/
/*         TimeOffRequestModal.module.css    */
/********************************************/

/* 
   ================================
    1) OVERLAY & MODAL CONTAINER
   ================================
*/
.userModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(4px);
    padding: 1rem;
  }
  
  /* Default: Light Mode */
  .userModalContent {
    background-color: var(--light-background);
    color: var(--light-text);
    padding: 2rem;
    border-radius: var(--border-radius-base);
    max-width: 600px;
    width: 100%;
    max-height: 90vh;
    position: relative;
    box-shadow: var(--shadow-lg-light);
    border: 1px solid var(--light-border);
    transition: all var(--transition-duration) var(--transition-ease);
    display: flex;
    flex-direction: column;
  }
  
  /* Dark Mode Override */
  [data-mui-color-scheme='dark'] .userModalContent {
    background-color: var(--dark-background);
    color: var(--dark-text);
    box-shadow: var(--shadow-lg-dark);
    border: 1px solid var(--dark-border);
  }
  
  /* 
     ================================
      2) MODAL TITLE
     ================================
  */
  .userModalTitle {
    margin: 0 0 1.5rem 0;
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--light-text);
    flex-shrink: 0;
  }
  
  /* Dark Mode Override */
  [data-mui-color-scheme='dark'] .userModalTitle {
    color: var(--dark-text);
  }
  
  /* 
     ================================
      3) FORM WRAPPER
     ================================
  */
  .userForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: auto;
    padding-right: 1rem;
  }
  
  /* 
     ================================
      4) FORM SECTIONS & LAYOUT
     ================================
  */
  .formSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .formColumn {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .formColumn label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--light-text);
  }
  
  /* Dark Mode Override */
  [data-mui-color-scheme='dark'] .formColumn label {
    color: var(--dark-text);
  }
  
  /* 
     ================================
      5) INPUT FIELDS (Date, Time, etc.)
     ================================
  */
  /* Default: Light Mode */
  .input {
    width: 100%;
    padding: 0.75rem;
    background-color: var(--light-foreground);
    color: var(--light-text);
    border: 1px solid var(--light-border);
    border-radius: var(--border-radius-base);
    font-size: 0.875rem;
    transition: all var(--transition-duration) var(--transition-ease);
  }
  
  .input:focus {
    outline: none;
    border-color: var(--light-primary);
    box-shadow: 0 0 0 2px var(--light-focus-ring);
  }
  
  /* Calendar & clock icons in Chrome, Safari, Edge */
  .input[type="date"]::-webkit-calendar-picker-indicator,
  .input[type="time"]::-webkit-calendar-picker-indicator {
    filter: none;
    opacity: 0.7;
    cursor: pointer;
  }
  
  .input[type="date"]::-webkit-calendar-picker-indicator:hover,
  .input[type="time"]::-webkit-calendar-picker-indicator:hover {
    opacity: 1;
  }
  
  /* Dark Mode Override */
  [data-mui-color-scheme='dark'] .input {
    background-color: var(--dark-text-area);
    color: var(--dark-text);
    border: 1px solid var(--dark-border);
  }
  
  [data-mui-color-scheme='dark'] .input:focus {
    border-color: var(--dark-primary);
    box-shadow: 0 0 0 2px var(--dark-focus-ring);
  }
  
  [data-mui-color-scheme='dark'] .input[type="date"]::-webkit-calendar-picker-indicator,
  [data-mui-color-scheme='dark'] .input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
    opacity: 0.7;
  }
  
  /* 
     ================================
      6) TEXTAREA
     ================================
  */
  /* Default: Light Mode */
  .textArea {
    width: 100%;
    padding: 0.75rem;
    background-color: var(--light-foreground);
    color: var(--light-text);
    border: 1px solid var(--light-border);
    border-radius: var(--border-radius-base);
    font-size: 0.875rem;
    transition: all var(--transition-duration) var(--transition-ease);
    resize: vertical;
    min-height: 100px;
  }
  
  .textArea:focus {
    outline: none;
    border-color: var(--light-primary);
    box-shadow: 0 0 0 2px var(--light-focus-ring);
  }
  
  /* Dark Mode Override */
  [data-mui-color-scheme='dark'] .textArea {
    background-color: var(--dark-text-area);
    color: var(--dark-text);
    border: 1px solid var(--dark-border);
  }
  
  [data-mui-color-scheme='dark'] .textArea:focus {
    border-color: var(--dark-primary);
    box-shadow: 0 0 0 2px var(--dark-focus-ring);
  }
  
  /* 
     ================================
      7) BUTTONS
     ================================
  */
  .userButtonGroup {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid var(--light-border);
    flex-shrink: 0;
  }
  
  /* Dark Mode Override */
  [data-mui-color-scheme='dark'] .userButtonGroup {
    border-top: 1px solid var(--dark-border);
  }
  
  .button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: var(--border-radius-base);
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 500;
    transition: background-color var(--transition-duration) var(--transition-ease);
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Disabled button */
  .button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  /* Primary button - Light mode default */
  .userButtonPrimary {
    background-color: var(--light-primary);
    color: #fff;
  }
  
  .userButtonPrimary:hover {
    background-color: var(--light-accent);
  }
  
  /* Secondary button - Light mode default */
  .userButtonSecondary {
    background-color: var(--light-secondary);
    color: #fff;
  }
  
  .userButtonSecondary:hover {
    background-color: var(--light-accent);
    /* If you'd prefer a secondary accent, feel free to override here */
  }
  
  /* Dark Mode Overrides */
  [data-mui-color-scheme='dark'] .userButtonPrimary {
    background-color: var(--dark-primary);
  }
  
  [data-mui-color-scheme='dark'] .userButtonPrimary:hover {
    background-color: var(--dark-accent);
  }
  
  [data-mui-color-scheme='dark'] .userButtonSecondary {
    background-color: var(--dark-secondary);
  }
  
  [data-mui-color-scheme='dark'] .userButtonSecondary:hover {
    background-color: var(--dark-secondary-accent);
  }
  
  /* 
     ================================
      8) ERROR MESSAGE
     ================================
  */
  .errorMessage {
    color: var(--error);
    font-size: 0.875rem;
    margin-bottom: 1rem;
    padding: 0.75rem;
    border: 1px solid var(--error);
    border-radius: var(--border-radius-base);
    /* Slight tinted background based on your --error color */
    /* For example, if --error is #eb4646, the rgb is 235,70,70 */
    /* You could define a custom --error-rgb: 235,70,70; in root for DRY. */
    background-color: rgba(235, 70, 70, 0.1);
  }
  
  /* 
     ================================
      9) DATEPICKER & TIMEPICKER 
         (MUI Overrides Example)
     ================================
  */
  .timePicker :global(.MuiOutlinedInput-root) {
    background-color: var(--light-foreground);
    border-radius: var(--border-radius-base);
  }
  
  .timePicker :global(.MuiOutlinedInput-notchedOutline) {
    border-color: var(--light-border);
  }
  
  .timePicker :global(.MuiInputBase-input) {
    color: var(--light-text);
    font-size: 0.875rem;
    padding: 0.75rem;
  }
  
  .timePicker :global(.MuiIconButton-root) {
    color: var(--light-text);
  }
  
  /* Dark Mode Overrides */
  [data-mui-color-scheme='dark'] .timePicker :global(.MuiOutlinedInput-root) {
    background-color: var(--dark-text-area);
  }
  
  [data-mui-color-scheme='dark'] .timePicker :global(.MuiOutlinedInput-notchedOutline) {
    border-color: var(--dark-border);
  }
  
  [data-mui-color-scheme='dark'] .timePicker :global(.MuiInputBase-input) {
    color: var(--dark-text);
  }
  
  [data-mui-color-scheme='dark'] .timePicker :global(.MuiIconButton-root) {
    color: var(--dark-text);
  }
  
  /* 
     ================================
      10) DATEPICKER EXAMPLE
     ================================
  */
  .datePickerRoot {
    /* Example MUI date/time picker custom styling */
    :global(.MuiPickersLayout-root) {
      background-color: var(--light-background);
      color: var(--light-text);
    }
    :global(.MuiDateCalendar-root) {
      background-color: var(--light-background);
      width: 320px;
      margin: 16px;
    }
    :global(.MuiDayCalendar-weekDayLabel) {
      color: var(--light-text);
    }
    :global(.MuiPickersDay-root) {
      color: var(--light-text);
    }
    :global(.MuiPickersDay-today) {
      border-color: var(--light-primary);
    }
    :global(.MuiPickersDay-root.Mui-selected) {
      background-color: var(--light-primary);
      color: #fff;
    }
    :global(.MuiPickersDay-root:not(.Mui-selected):hover) {
      background-color: rgba(0, 0, 0, 0.1);
    }
    :global(.MuiPickersCalendarHeader-label) {
      color: var(--light-text);
    }
    :global(.MuiPickersArrowSwitcher-button) {
      color: var(--light-text);
    }
    :global(.MuiTextField-root),
    :global(.MuiOutlinedInput-root) {
      background-color: var(--light-foreground);
      color: var(--light-text);
    }
    :global(.MuiInputLabel-root) {
      color: var(--light-text);
    }
  }
  
  /* Dark Mode Overrides */
  [data-mui-color-scheme='dark'] .datePickerRoot :global(.MuiPickersLayout-root) {
    background-color: var(--dark-background);
    color: var(--dark-text);
  }
  [data-mui-color-scheme='dark'] .datePickerRoot :global(.MuiDateCalendar-root) {
    background-color: var(--dark-background);
  }
  [data-mui-color-scheme='dark'] .datePickerRoot :global(.MuiDayCalendar-weekDayLabel) {
    color: var(--dark-text);
  }
  [data-mui-color-scheme='dark'] .datePickerRoot :global(.MuiPickersDay-root) {
    color: var(--dark-text);
  }
  [data-mui-color-scheme='dark'] .datePickerRoot :global(.MuiPickersDay-root:not(.Mui-selected):hover) {
    background-color: rgba(255, 255, 255, 0.1);
  }
  [data-mui-color-scheme='dark'] .datePickerRoot :global(.MuiPickersCalendarHeader-label) {
    color: var(--dark-text);
  }
  [data-mui-color-scheme='dark'] .datePickerRoot :global(.MuiPickersArrowSwitcher-button) {
    color: var(--dark-text);
  }
  [data-mui-color-scheme='dark'] .datePickerRoot :global(.MuiTextField-root),
  [data-mui-color-scheme='dark'] .datePickerRoot :global(.MuiOutlinedInput-root) {
    background-color: var(--dark-text-area);
    color: var(--dark-text);
  }
  [data-mui-color-scheme='dark'] .datePickerRoot :global(.MuiInputLabel-root) {
    color: var(--dark-text);
  }

  .jobsSection {
    margin-top: 1rem;
    padding: 1rem;
    background-color: rgba(30, 41, 59, 0.8);
    border-radius: var(--border-radius-base);
  }
  
  .jobsSection h3 {
    margin-bottom: 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--dark-text);
  }
  
  .jobItem {
    padding: 0.75rem;
    background-color: rgba(30, 41, 59, 0.9);
    border-radius: var(--border-radius-base);
    margin-bottom: 0.5rem;
  }
  
  .jobTitle {
    font-weight: 500;
    color: var(--dark-text);
    margin-bottom: 0.25rem;
  }
  
  .jobTime {
    font-size: 0.875rem;
    color: var(--dark-text-muted);
    margin-bottom: 0.25rem;
  }
  
  .jobAddress {
    font-size: 0.875rem;
    color: var(--dark-text-muted);
  }
  
  /* Light mode overrides */
  :global([data-mui-color-scheme='light']) {
    .jobsSection {
      background-color: var(--light-foreground);
    }
  
    .jobsSection h3 {
      color: var(--light-text);
    }
  
    .jobItem {
      background-color: var(--light-foreground-accent);
    }
  
    .jobTitle {
      color: var(--light-text);
    }
  
    .jobTime,
    .jobAddress {
      color: var(--light-text-muted);
    }
  }