/* src/styles/CleanerJobView.module.css */
.cleanerJobView {
  min-height: 100vh;
  background-color: var(--light-background);
  color: var(--light-text);
  padding-bottom: 216px;
  margin: 0;
  padding-bottom: 160px; /* Add padding to account for fixed buttons */
  position: relative;
}

[data-mui-color-scheme="dark"] .cleanerJobView {
  background-color: var(--dark-background);
  color: var(--dark-text);
}

/* In your MobileNavigation.module.css */
.mobileNav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 56px; /* Explicit height */
  background-color: var(--light-foreground);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 8px 16px;
  z-index: 1001; /* Higher than action buttons */
  border-top: 1px solid var(--light-border);
}

.standaloneNav {
  padding: 8px 16px;
  justify-content: space-between;
}

/* iOS safe area support */
@supports (padding-bottom: env(safe-area-inset-bottom)) {
  .mobileNav {
    padding-bottom: calc(8px + env(safe-area-inset-bottom));
    height: calc(56px + env(safe-area-inset-bottom));
  }
}

.jobCard {
  margin: 0;
  padding: 16px;
  border-radius: 0;
  box-shadow: none;
  max-width: 100%;
  background-color: var(--light-foreground);
}

[data-mui-color-scheme="dark"] .jobCard {
  background-color: var(--dark-foreground);
}

.customerName {
  font-size: 1.25rem;
  margin-bottom: 4px;
  padding: 8px 0;
}

.address {
  font-size: 0.875rem;
  margin-bottom: 12px;
}

.addressLink {
  color: #1976d2;
  text-decoration: none;
}

.addressLink:hover {
  text-decoration: underline;
}

.sectionTitle {
  font-size: 1rem;
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: 600;
  color: var(--light-text);
}

.jobDetails {
  font-size: 0.875rem;
  margin-bottom: 8px;
}

[data-mui-color-scheme="dark"] .sectionTitle {
  color: var(--dark-text) !important;
}

.roomAccordion {
  margin-bottom: 4px;
  border-radius: 8px;
  background-color: var(--light-foreground);
  border: 1px solid var(--light-border);
}

[data-mui-color-scheme="dark"] .roomAccordion {
  background-color: var(--dark-foreground);
  border: 1px solid var(--dark-border);
}

.roomAccordion :global(.MuiAccordionSummary-content) {
  margin: 8px 0;
}

.taskList {
  padding-left: 12px;
  margin-top: 4px;
  color: var(--light-text);
}

[data-mui-color-scheme="dark"] .taskList {
  color: var(--dark-text);
}

.taskList li {
  margin-bottom: 4px;
  color: var(--light-text);
}

[data-mui-color-scheme="dark"] .taskList li {
  color: var(--dark-text);
}

.notesSection {
  margin-top: 16px;
}

.notesField {
  margin-top: 4px;
}

.notes, .importantNotes {
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 4px;
}

.importantNotes {
  background-color: rgba(255, 0, 0, 0.05); /* Light red background for important notes */
  border-left: 3px solid #ff0000;
}

.actionButtons {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 56px; /* Adjusted to be above the menu bar */
  padding: 16px;
  background-color: var(--light-foreground);
  box-shadow: var(--shadow-lg-light);
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 1000;
  margin-bottom: 5000px; /* Remove the extra margin */
  padding-bottom: 16px;
}

/* If you need iOS safe area support, adjust it like this */
@supports (padding: env(safe-area-inset-bottom)) {
  .actionButtons {
    padding-bottom: calc(16px + env(safe-area-inset-bottom));
  }
}

[data-mui-color-scheme="dark"] .actionButtons {
  background-color: var(--dark-foreground);
  box-shadow: var(--shadow-lg-dark);
}

.startButton,
.completeButton {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border-radius: 8px;
  background-color: var(--light-primary);
  color: var(--dark-text);
}

.startButton {
  background-color: var(--light-primary);
  color: var(--dark-text);
}

[data-mui-color-scheme="dark"] .startButton {
  background-color: var(--dark-primary);
  color: var(--dark-text);
}

.completeButton {
  background-color: var(--light-secondary);
  color: var(--dark-text);
}

[data-mui-color-scheme="dark"] .completeButton {
  background-color: var(--dark-secondary);
  color: var(--dark-text);
}

/* MobileHeader styles */
.mobileHeader {
  background-color: var(--light-foreground);
  border-bottom: 1px solid var(--light-border);
  color: var(--light-text);
}

[data-mui-color-scheme="dark"] .mobileHeader {
  background-color: var(--dark-foreground);
  border-bottom: 1px solid var(--dark-border);
  color: var(--dark-text);
}

/* MobileNavigation styles */
.mobileNav {
  background-color: var(--light-foreground);
  box-shadow: var(--shadow-lg-light);
  border-top: 1px solid var(--light-border);
}

[data-mui-color-scheme="dark"] .mobileNav {
  background-color: var(--dark-foreground);
  box-shadow: var(--shadow-lg-dark);
  border-top: 1px solid var(--dark-border);
}

.navItem {
  color: var(--light-text-light);
}

[data-mui-color-scheme="dark"] .navItem {
  color: var(--dark-text-light);
}

.navItem.active {
  color: var(--light-primary);
}

[data-mui-color-scheme="dark"] .navItem.active {
  color: var(--dark-primary);
}

/* Dialog styles */
.dialog {
  background-color: var(--light-foreground);
}

[data-mui-color-scheme="dark"] .dialog {
  background-color: var(--dark-foreground);
}

/* Loading, Error, and No Job states */
.loadingContainer,
.errorContainer,
.noJobContainer {
  background-color: var(--light-background);
  color: var(--light-text);
}

[data-mui-color-scheme="dark"] .loadingContainer,
[data-mui-color-scheme="dark"] .errorContainer,
[data-mui-color-scheme="dark"] .noJobContainer {
  background-color: var(--dark-background);
  color: var(--dark-text);
}

/* Form elements */
.notesField {
  background-color: var(--light-foreground);
  border: 1px solid var(--light-border);
  color: var(--light-text);
}

[data-mui-color-scheme="dark"] .notesField {
  background-color: var(--dark-text-area);
  border: 1px solid var(--dark-border);
  color: var(--dark-text);
}

/* Add transition for smooth dark mode switching */
.dashboard,
.cleanerJobView,
.jobCard,
.mobileNav,
.actionButtons,
.jobListItem,
.navItem,
.startButton,
.completeButton {
  transition: background-color var(--transition-duration) var(--transition-ease),
              color var(--transition-duration) var(--transition-ease),
              border-color var(--transition-duration) var(--transition-ease),
              box-shadow var(--transition-duration) var(--transition-ease);
}

/* Loading, Error, and No Job states */
.loadingContainer,
.errorContainer,
.noJobContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

/* Dialog adjustments for mobile */
:global(.MuiDialog-paper) {
  margin: 16px;
  width: calc(100% - 32px);
  max-height: calc(100% - 32px);
}

/* iOS safe area support */
@supports (padding: env(safe-area-inset-bottom)) {
  .cleanerJobView {
    padding-bottom: calc(216px + env(safe-area-inset-bottom));
  }
  .actionButtons {
    bottom: calc(64px + env(safe-area-inset-bottom));
  }
  .mobileNav {
    padding-bottom: env(safe-area-inset-bottom);
  }
}