/* src/styles/ExpenseHistory.module.css */
.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tabCard {
  background-color: var(--dark-foreground) !important;
  border-bottom: 1px solid var(--dark-border) !important;
  border-radius: 0 !important;
  margin-top: 16px;
}

.tabs {
  min-height: 48px !important;
}

.tabs :global(.MuiTab-root) {
  text-transform: none !important;
  font-family: var(--font-family-base) !important;
  min-height: 48px !important;
  color: var(--dark-text) !important;
}

.tabs :global(.Mui-selected) {
  color: var(--dark-primary) !important;
}

.expensesList {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.expenseCard {
  background-color: var(--dark-foreground) !important;
  border: 1px solid var(--dark-border) !important;
  border-radius: var(--border-radius-base) !important;
}

.loading {
  display: flex;
  justify-content: center;
  padding: 32px;
}

.emptyCard {
  background-color: var(--dark-foreground) !important;
  border: 1px solid var(--dark-border) !important;
  border-radius: var(--border-radius-base) !important;
  padding: 24px;
}

[data-mui-color-scheme="light"] .tabCard,
[data-mui-color-scheme="light"] .expenseCard,
[data-mui-color-scheme="light"] .emptyCard {
  background-color: var(--light-foreground) !important;
  border-color: var(--light-border) !important;
}

[data-mui-color-scheme="light"] .tabs :global(.MuiTab-root) {
  color: var(--light-text-light) !important;
}

[data-mui-color-scheme="light"] .tabs :global(.Mui-selected) {
  color: var(--light-primary) !important;
}