/* /src/styles/TaskBoard.module.css */
/* Can not add in scrollbars or use overflow */

/* Base Layout */
.task-board {
  display: flex;
  flex-direction: column;
  background-color: var(--dark-background);
  color: var(--dark-text);
  padding: 10px;
  -webkit-overflow-scrolling: touch; /* For smooth scrolling on iOS */
}

[data-mui-color-scheme="light"] .task-board {
  background-color: var(--light-foreground) !important;
}

/* Board content - This will enable horizontal scrolling */
.board-content {
  flex: 1;
  width: 100%;
  min-width: fit-content;
  position: relative;
}

.task-board h1 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
} 

/* Headers */
.pipelineHeader,
.stagesHeader,
.availableCardsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Pipeline Section */
.pipelinesContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.pipeline {
  flex: 0 0 auto;
  width: auto;
  min-width: 280px;
  background-color: var(--dark-foreground);
  border-radius: var(--border-radius-base);
  padding: 5px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 0px;
  box-shadow: var(--shadow-sm-dark);
}

[data-mui-color-scheme="light"] .pipeline {
  background-color: var(--light-foreground) !important;
  box-shadow: var(--shadow-sm-light) !important;
}

/* Pipeline Header */
.pipelineHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 0px;
}

[data-mui-color-scheme="light"] .pipelineHeader {
  background-color: var(--light-foreground) !important;
  box-shadow: var(--shadow-sm-light) !important;
}

/* Stages Container */
.stagesHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
}

[data-mui-color-scheme="light"] .stagesHeader {
  background-color: var(--light-foreground) !important;
}

/* Title row */
.stage-title-row {
  display: flex;
  align-items: center;
  gap: 8px;
}

.stage-header h3 {
  margin: 0;
  flex: 1;
}

.stagesContainer {
  padding: 10px;
  margin-top: 0px;
  min-height: 300px;
  background-color: var(--dark-background);
  border-radius: 8px;
}

[data-mui-color-scheme="light"] .stagesContainer {
  background-color: var(--light-foreground) !important;
  box-shadow: var(--shadow-sm-light) !important;
}

.stages {
  display: flex;
  flex-direction: row;
  padding: 0px;
  margin:0;
  gap: 12px; /* Increased gap for better separation */
}

/* Individual stage styling */
.stage {
  flex: 0 0 auto;
  min-width: 300px;
  background-color: var(--dark-foreground); /* Move background here */
  border-radius: var(--border-radius-base);
  padding: 5px;
  display: flex;
  flex-direction: column;
}

[data-mui-color-scheme="light"] .stage {
  background-color: var(--light-foreground-accent) !important;
  box-shadow: var(--shadow-sm-light) !important;
}

/* Optional: Add hover effect to make stages feel more independent */
.stage:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
  transition: all 0.2s ease;
}

/* Actions row */
.stage-actions {
  display: flex;
  align-items: center;
  padding-top: 0px;
}

/* Bucket Section */

/* Bucket Header */
.bucket-header {
  display: flex;
  flex-direction: column;
}

/* Title row */
.bucket-title-row {
  display: flex;
  align-items: center;
}

.bucket-title-row h4 {
  margin: 0;
  flex: 1;
}

/* Actions row */
.bucket-actions {
  display: flex;
  align-items: center;
}

/* Bucket Section */
.bucket {
  background-color: var(--dark-foreground-accent);
  border-radius: var(--border-radius-base);
  padding: 5px;
  margin: 2px;
  width: 28nm0px;
  /* min-height: 100%; */
}

[data-mui-color-scheme="light"] .bucket {
  background-color: var(--light-accent) !important;
  box-shadow: var(--shadow-sm-light) !important;
}

/* Available Cards Section */
.availableCardsContainer {
  margin-top: 30px;
  background-color: var(--dark-background);
  border-radius: var(--border-radius-base);
  padding: 20px;
  width: 100%; /* Take full width of parent */
  max-width: 100%; /* Ensure it doesn't overflow */
}

[data-mui-color-scheme="light"] .availableCardsContainer {
  background-color: var(--light-foreground) !important;
  box-shadow: var(--shadow-sm-light) !important;
}

.availableCards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 8px;
  padding: 10px;
  max-height: 300px;
  width: 240px;
}

/* Card Styles */
.card {
  background-color: var(--dark-accent);
  border-radius: var(--border-radius-base);
  padding: 2px;
  width: 100%; /* Make cards fill their grid cell */
  min-width: 240px; /* Maintain minimum width */
  height: fit-content;
  transition: transform 0.2s ease;
}

/* Priority Cards */
.card.priority-high {
  background-color: var(--error) !important;
  color: var(--error-content) !important;
}

.card.priority-medium {
  background-color: var(--warning) !important;
  color: var(--warning-content) !important;
}

.card.priority-low {
  background-color: var(--success) !important;
  color: var(--success-content) !important;
}

/* Action button containers */
.pipeline-actions,
.stage-actions,
.bucket-actions,
.card-actions {
  display: flex;
  align-items: center;
}

.pipeline-action-button,
.stagesHeaderButton,
.availableCardsHeaderButton {
  width: 30px !important;
  height: 30px !important;
  min-width: 20px !important;
  min-height: 20px !important;
  padding-right: 6px !important;
}

/* Base Action Button */
.action-button {
  background-color: var(--dark-primary);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  height: 36px !important;
  width: 30px !important;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.edit-button,
.delete-button {
  background: none;
  border: none;
  color: var(--dark-text-light);
  cursor: pointer;
  padding: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease;
  padding: 4px !important;
  width: 30px !important;
  height: 30px !important;
  min-width: 20px !important;
  min-height: 20px !important;
}

.edit-button:hover,
.delete-button:hover {
  color: var(--dark-primary);
}
/* Light mode styles */
[data-mui-color-scheme="light"] .action-button, .edit-button, .delete-button,
[data-mui-color-scheme="light"] .MuiIconButton-root {
  background-color: var(--light-primary);
}

/* Hover states */
.action-button:hover,
.MuiIconButton-root:hover {
  background-color: var(--dark-accent) !important;
}

[data-mui-color-scheme="light"] .action-button:hover {
  background-color: var(--light-accent);
}

/* Icon button specific styles */
.action-button.icon-button,
.MuiIconButton-root {
  padding: 4px !important;
  width: 30px !important;
  height: 30px !important;
  min-width: 20px !important;
  min-height: 20px !important;
}

/* Icon sizing within buttons */
.MuiIconButton-root .MuiSvgIcon-root {
  font-size: 20px !important;
}

[data-mui-color-scheme="light"] .delete-button,
[data-mui-color-scheme="light"] .edit-button {
  color: var(--dark-text);
}

.delete-button:hover,
.edit-button:hover {
  color: var(--dark-primary);
}

[data-mui-color-scheme="light"] .delete-button:hover,
[data-mui-color-scheme="light"] .edit-button:hover {
  color: var(--light-primary);
}

/* Typography */
.task-board h1,
.task-board h2,
.task-board h3,
.task-board h4 {
  margin: 0;
  color: inherit;
}

[data-mui-color-scheme="light"] .task-board h1,
[data-mui-color-scheme="light"] .task-board h2,
[data-mui-color-scheme="light"] .task-board h3,
[data-mui-color-scheme="light"] .task-board h4 {
  color: var(--light-text) !important;
}

/* Loading and Error States */
.loading-container,
.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
}

/* Override MUI Dialog styles when necessary */
:global(.MuiDialog-paper) {
  background-color: var(--dark-background) !important;
}

[data-mui-color-scheme="light"] :global(.MuiDialog-paper) {
  background-color: var(--light-background) !important;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .pipeline {
    width: 600px;
  }
}

@media (max-width: 768px) {
  .pipeline {
    width: 100%;
    min-width: 500px;
  }
  
  .stage-wrapper {
    width: 240px;
  }
  
  .card {
    width: 220px;
  }
}

/* Break Away Stage Styles */
.break-away-stage {
  border-left: 4px solid var(--dark-primary);
}

[data-mui-color-scheme="light"] .break-away-stage {
  border-left: 4px solid var(--light-primary);
}

.break-away-indicator {
  font-size: 0.8rem;
  padding: 4px 8px;
  border-radius: var(--border-radius-base);
  background-color: var(--dark-primary);
  color: white;
  margin-right: 8px;
}

[data-mui-color-scheme="light"] .break-away-indicator {
  background-color: var(--light-primary);
}

/* Dragging States */
.dragging-over {
  background-color: var(--dark-foreground-accent);
  border-radius: var(--border-radius-base);
}

[data-mui-color-scheme="light"] .dragging-over {
  background-color: var(--light-foreground-accent);
}