/* JobDialog.module.css */

.dialog {
  max-width: 1200px;
  width: 100%;
  margin: auto !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-height: 100vh !important;
}

.dialogPaper {
  margin: 24px !important;
  width: calc(100% - 48px) !important;
  position: relative !important;
  transform: none !important;
}

.dialogTitle {
  margin: 0;
  padding: 20px 24px;
  background-color: var(--light-foreground);
  color: var(--light-text);
}

[data-mui-color-scheme='dark'] .dialogTitle {
  background-color: var(--dark-foreground);
  color: var(--dark-text);
}

.dialogContent {
  padding: 24px !important;
  overflow-x: hidden;
  background-color: var(--light-background);
}

[data-mui-color-scheme='dark'] .dialogContent {
  background-color: var(--dark-background);
}

.dialogActions {
  padding: 16px 24px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  background: var(--light-background);
  border-top: 1px solid var(--light-border);
}

[data-mui-color-scheme='dark'] .dialogActions {
  background: var(--dark-background);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.actionButton {
  padding: 8px 16px !important;
  border-radius: 6px !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  text-transform: none !important;
  min-width: auto !important;
  transition: all 0.2s ease !important;
  background-color: var(--light-foreground-accent) !important;
  color: var(--light-text) !important;
  border: 1px solid var(--light-border) !important;
}

.actionButton:hover {
  background-color: var(--light-accent) !important;
  color: white !important;
  border-color: var(--light-accent) !important;
}

[data-mui-color-scheme='dark'] .actionButton {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: var(--dark-text) !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
}

[data-mui-color-scheme='dark'] .actionButton:hover {
  background-color: rgba(255, 255, 255, 0.15) !important;
  color: white !important;
}

.closeButton {
  text-transform: none !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: var(--light-text-light) !important;
}

[data-mui-color-scheme='dark'] .closeButton {
  color: var(--dark-text-light) !important;
}

.jobLink {
  margin: 16px 24px;
  padding: 12px 16px;
  background: var(--light-foreground-accent);
  border: 1px solid var(--light-border);
  border-radius: 8px;
  font-size: 0.875rem;
  color: var(--light-text);
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  transition: all 0.2s ease;
}

.jobLink:hover {
  background: var(--light-accent);
  color: white;
  border-color: var(--light-accent);
}

[data-mui-color-scheme='dark'] .jobLink {
  background: var(--dark-foreground-accent);
  border-color: var(--dark-border);
  color: var(--dark-text);
}

[data-mui-color-scheme='dark'] .jobLink:hover {
  background: var(--dark-accent);
  color: white;
  border-color: var(--dark-accent);
}

.generateEmail {
  background-color: var(--light-primary) !important;
  color: white !important;
  border: none !important;
}

.generateEmail:hover {
  background-color: var(--light-accent) !important;
}

[data-mui-color-scheme='dark'] .generateEmail {
  background-color: var(--dark-primary) !important;
}

[data-mui-color-scheme='dark'] .generateEmail:hover {
  background-color: var(--dark-accent) !important;
}