/* src/styles/CreateRecurringService.module.css */

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5rem;
  background-color: var(--light-foreground);
  border-radius: var(--border-radius-base);
  box-shadow: var(--shadow-md-light);
}

[data-mui-color-scheme='dark'] .container {
  background-color: var(--dark-foreground);
  box-shadow: var(--shadow-md-dark);
}

.section {
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  background-color: var(--light-foreground);
  border-radius: var(--border-radius-base);
  border: 1px solid var(--light-border);
}

[data-mui-color-scheme='dark'] .section {
  background-color: var(--dark-foreground);
  border-color: var(--dark-border);
}

/* Form Fields */
.section :global(.MuiFormControl-root) {
  margin-bottom: 1rem;
}

.section :global(.MuiInputBase-root) {
  background-color: var(--light-foreground);
}

[data-mui-color-scheme='dark'] .section :global(.MuiInputBase-root) {
  background-color: var(--dark-text-area);
}

/* Select Dropdowns */
.section :global(.MuiSelect-select) {
  padding: 1rem;
}

.section :global(.MuiSelect-icon) {
  color: var(--light-text);
}

[data-mui-color-scheme='dark'] .section :global(.MuiSelect-icon) {
  color: var(--dark-text);
}

/* Google Places Autocomplete */
.section :global(.pac-container) {
  z-index: 9999;
  margin-top: 2px;
  padding: 0.5rem;
  background-color: var(--light-foreground);
  border: 1px solid var(--light-border);
  border-radius: var(--border-radius-base);
  box-shadow: var(--shadow-md-light);
}

[data-mui-color-scheme='dark'] .section :global(.pac-container) {
  background-color: var(--dark-foreground);
  border-color: var(--dark-border);
  box-shadow: var(--shadow-md-dark);
}

.section :global(.pac-item) {
  padding: 0.75rem 1rem;
  cursor: pointer;
  border-bottom: 1px solid var(--light-border);
}

[data-mui-color-scheme='dark'] .section :global(.pac-item) {
  border-color: var(--dark-border);
  color: var(--dark-text);
}

.section :global(.pac-item:hover) {
  background-color: var(--light-focus-ring);
}

[data-mui-color-scheme='dark'] .section :global(.pac-item:hover) {
  background-color: var(--dark-focus-ring);
}

/* Typography */
.section h6 {
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--light-text);
}

[data-mui-color-scheme='dark'] .section h6 {
  color: var(--dark-text);
}

/* Cleaner Items */
.cleanerItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
  background-color: var(--light-foreground);
  border: 1px solid var(--light-border);
  border-radius: var(--border-radius-base);
}

[data-mui-color-scheme='dark'] .cleanerItem {
  background-color: var(--dark-text-area);
  border-color: var(--dark-border);
}

/* Form Actions */
.formActions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid var(--light-border);
}

[data-mui-color-scheme='dark'] .formActions {
  border-color: var(--dark-border);
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .section {
    padding: 1rem;
  }

  .formActions {
    flex-direction: column;
  }

  .formActions button {
    width: 100%;
  }
}

/* Input Focus States */
.section :global(.MuiOutlinedInput-root.Mui-focused) {
  border-color: var(--light-primary);
}

[data-mui-color-scheme='dark'] .section :global(.MuiOutlinedInput-root.Mui-focused) {
  border-color: var(--dark-primary);
}

/* Select Menu Items */
.section :global(.MuiMenu-paper) {
  margin-top: 4px;
  background-color: var(--light-foreground);
  border: 1px solid var(--light-border);
  border-radius: var(--border-radius-base);
  box-shadow: var(--shadow-md-light);
}

[data-mui-color-scheme='dark'] .section :global(.MuiMenu-paper) {
  background-color: var(--dark-foreground);
  border-color: var(--dark-border);
  box-shadow: var(--shadow-md-dark);
}

.section :global(.MuiMenuItem-root) {
  padding: 0.75rem 1rem;
}

.section :global(.MuiMenuItem-root:hover) {
  background-color: var(--light-focus-ring);
}

[data-mui-color-scheme='dark'] .section :global(.MuiMenuItem-root:hover) {
  background-color: var(--dark-focus-ring);
}

/* Form Sections */
.formSection {
  margin-bottom: 24px;
  padding: 16px;
  background-color: var(--light-foreground);
  border-radius: var(--border-radius-base);
  box-shadow: var(--shadow-sm-light);
}

[data-mui-color-scheme="dark"] .formSection {
  background-color: var(--dark-foreground);
  box-shadow: var(--shadow-sm-dark);
}

.sectionTitle {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 16px;
  color: var(--light-text);
}

[data-mui-color-scheme="dark"] .sectionTitle {
  color: var(--dark-text);
}

/* Form Controls */
.inputGroup {
  margin-bottom: 16px;
}

.label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: var(--light-text);
}

[data-mui-color-scheme="dark"] .label {
  color: var(--dark-text);
}

/* Date Time Container */
.dateTimeContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

/* Dropdown Styling */
.select {
  width: 100%;
  background-color: var(--light-foreground);
  border: 1px solid var(--light-border);
  border-radius: 4px;
  padding: 8px;
}

[data-mui-color-scheme="dark"] .select {
  background-color: var(--dark-text-area);
  border-color: var(--dark-border);
}

/* Cleaner List Styling */
.cleanerList {
  margin-top: 8px;
  margin-bottom: 16px;
}

/* Room List Styling */
.roomsList {
  margin-top: 16px;
}

.roomCard {
  background-color: var(--light-foreground);
  border: 1px solid var(--light-border);
  border-radius: 4px;
  margin-bottom: 8px;
  overflow: hidden;
}

[data-mui-color-scheme="dark"] .roomCard {
  background-color: var(--dark-text-area);
  border-color: var(--dark-border);
}

.roomHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: var(--light-foreground-accent);
  cursor: pointer;
}

[data-mui-color-scheme="dark"] .roomHeader {
  background-color: var(--dark-foreground-accent);
}

/* Button Styling */
.button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  background-color: var(--light-primary);
  color: white;
  transition: background-color 0.2s ease;
}

.button:hover {
  background-color: var(--light-accent);
}

[data-mui-color-scheme="dark"] .button {
  background-color: var(--dark-primary);
}

[data-mui-color-scheme="dark"] .button:hover {
  background-color: var(--dark-accent);
}

/* Error and Success Messages */
.errorMessage {
  color: var(--error);
  background-color: rgba(235, 70, 70, 0.1);
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.successMessage {
  color: var(--success);
  background-color: rgba(70, 235, 70, 0.1);
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 16px;
}

/* Add these new styles to your existing CSS module */

.dialogPaper {
  background-color: var(--light-background) !important;
  max-height: 90vh !important;
  z-index: 1300 !important;
}

[data-mui-color-scheme='dark'] .dialogPaper {
  background-color: var(--dark-background) !important;
}

.dialogTitle {
  background-color: var(--light-foreground);
  padding: 1rem 1.5rem;
  border-bottom: 1px solid var(--light-border);
}

[data-mui-color-scheme='dark'] .dialogTitle {
  background-color: var(--dark-foreground);
  border-color: var(--dark-border);
}

.dialogContent {
  padding: 0 !important;
  overflow-x: hidden;
  background-color: var(--light-background);
}

[data-mui-color-scheme='dark'] .dialogContent {
  background-color: var(--dark-background);
}

/* Update container styles for dialog */
.container {
  width: 100%;
  padding: 1.5rem;
  background-color: var(--light-background);
}

[data-mui-color-scheme='dark'] .container {
  background-color: var(--dark-background);
}

/* Ensure Google Places autocomplete appears above dialog */
.section :global(.pac-container) {
  z-index: 9999 !important;
}

.recurrenceContainer {
  position: relative;
  z-index: 1;
}

.recurrencePopover {
  z-index: 9999 !important;
}