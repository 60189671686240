/* AddRoomsModal.module.css */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: var(--light-foreground);
  border-radius: 12px;
  box-shadow: var(--shadow-lg-light);
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.modalHeader {
  background: linear-gradient(135deg, var(--light-primary), var(--light-accent));
  padding: 1.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalTitle {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
}

.closeButton {
  background: var(--light-secondary) !important;
  border: 1px solid var(--light-secondary) !important;
  color: white !important;
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease;
  border-radius: 4px;
}

.closeButton:hover {
  background: var(--light-secondary-accent) !important;
  box-shadow: var(--shadow-md-light) !important;
}

.modalContent {
  padding: 1.5rem;
  overflow-y: auto;
  flex: 1;
}

.modalFooter {
  padding: 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  background: var(--light-foreground-accent);
  border-top: 1px solid var(--light-border);
}

.roomsList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.roomItem {
  background: var(--light-foreground-accent);
  border: 1px solid var(--light-border);
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.2s ease;
}

.roomHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  background: var(--light-background);
  transition: all 0.2s ease;
}

.roomHeader:hover {
  background: var(--light-foreground);
}

.roomHeader.selected {
  background: var(--form-highlight-light);
  border-color: var(--light-primary);
}

.roomInfo {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.roomName {
  font-weight: 500;
  color: var(--light-text);
}

.roomControls {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.quantityControls {
  background: var(--light-background);
  border: 1px solid var(--light-border);
  border-radius: 6px;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.quantityButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.25rem;
  color: var(--light-text);
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quantityButton:hover:not(:disabled) {
  color: var(--light-primary);
}

.quantityButton:disabled {
  color: var(--light-text-lighter);
  cursor: not-allowed;
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  color: var(--light-text);
  transition: all 0.2s ease;
  border-radius: 4px;
}

.iconButton:hover {
  color: var(--light-primary);
  background: var(--light-background);
}

.quantityDisplay {
  min-width: 24px;
  text-align: center;
  font-weight: 500;
}

.chevronContainer {
  cursor: pointer;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chevron {
  transition: transform var(--transition-duration) var(--transition-ease);
}

.chevronOpen {
  transform: rotate(180deg);
}

.roomContent {
  padding: 1rem;
  border-top: 1px solid var(--light-border);
  background-color: var(--light-background);
}

.tasksTitle {
  margin: 0 0 0.5rem 0;
  font-size: 1rem;
  color: var(--light-text);
}

.tasksList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.taskInput {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--light-border);
  border-radius: var(--border-radius-base);
  background-color: var(--light-background);
  color: var(--light-text);
}

.taskInput:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--light-focus-ring);
}

.button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: var(--border-radius-base);
  cursor: pointer;
  font-family: var(--font-family-base);
  transition: all var(--transition-duration) var(--transition-ease);
}

.submitButton {
  background: linear-gradient(135deg, var(--light-primary), var(--light-accent)) !important;
  color: white !important;
  border: none !important;
  transition: all 0.2s ease !important;
  box-shadow: var(--shadow-sm-light) !important;
}

.submitButton:hover {
  background: linear-gradient(135deg, var(--light-accent), var(--light-primary)) !important;
  box-shadow: var(--shadow-md-light) !important;
  transform: translateY(-1px);
}

.cancelButton {
  background-color: var(--error) !important;
  color: white !important;
  border: 1px solid var(--error) !important;
  transition: all 0.2s ease;
}

.cancelButton:hover {
  background-color: #f06c6c !important; /* Increase visibility */
  border-color: var(--error) !important;
  box-shadow: var(--shadow-md-light) !important;
}

.noTasks, .noRooms {
  color: var(--light-text-light);
  text-align: center;
  padding: 1rem;
}

.searchContainer {
  position: relative;
  margin-bottom: 1rem;
}

.searchInput {
  width: 100%;
  padding: 0.75rem;
  border-radius: 4px;
  background-color: var(--light-foreground);
  border: 1px solid var(--light-border);
  color: var(--light-text);
  font-size: 14px;
  transition: all 0.2s ease;
}

.searchInput:hover {
  background-color: var(--light-foreground);
  border-color: var(--light-border);
}

.searchInput:focus {
  outline: none;
  border-color: var(--light-primary);
  box-shadow: 0 0 0 2px var(--light-focus-ring);
}

/* Dark mode search styles */
[data-mui-color-scheme="dark"] .searchInput {
  background-color: var(--dark-text-area);
  border-color: var(--dark-border);
  color: var(--dark-text);
}

[data-mui-color-scheme="dark"] .searchInput:hover {
  background-color: var(--dark-text-hover);
  border-color: var(--dark-border);
}

[data-mui-color-scheme="dark"] .searchInput:focus {
  border-color: var(--dark-primary);
  box-shadow: 0 0 0 2px var(--dark-focus-ring);
}

/* Search icon styling */
.searchIcon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--light-text-light);
}

[data-mui-color-scheme="dark"] .searchIcon {
  color: var(--dark-text-light);
}

/* Adjust input padding when icon is present */
.searchInput.withIcon {
  padding-left: 2.5rem;
}

/* Dark mode styles */
[data-mui-color-scheme='dark'] .modal {
  background-color: var(--dark-foreground);
  color: var(--dark-text);
  box-shadow: var(--shadow-lg-dark);
}

[data-mui-color-scheme='dark'] .modalHeader,
[data-mui-color-scheme='dark'] .modalFooter {
  background-color: var(--dark-foreground);
  border-color: var(--dark-border);
}

[data-mui-color-scheme='dark'] .modalContent {
  background-color: var(--dark-foreground);
}

[data-mui-color-scheme='dark'] .roomsList {
  border-color: var(--dark-border);
}

[data-mui-color-scheme='dark'] .roomItem {
  border-color: var(--dark-border);
  background-color: var(--dark-foreground);
  box-shadow: var(--shadow-sm-dark);
}

[data-mui-color-scheme='dark'] .roomHeader {
  background-color: var(--dark-text-area);
}

[data-mui-color-scheme='dark'] .roomHeader:hover {
  background-color: var(--dark-text-hover);
}

[data-mui-color-scheme='dark'] .roomHeader.selected {
  background: var(--form-highlight-dark);
  border-color: var(--dark-primary);
}

[data-mui-color-scheme='dark'] .roomName {
  color: var(--dark-text);
}

[data-mui-color-scheme='dark'] .quantityInput,
[data-mui-color-scheme='dark'] .taskInput {
  background-color: var(--dark-text-area);
  border-color: var(--dark-border);
  color: var(--dark-text);
}

[data-mui-color-scheme='dark'] .quantityInput:focus,
[data-mui-color-scheme='dark'] .taskInput:focus {
  box-shadow: 0 0 0 2px var(--dark-focus-ring);
}

[data-mui-color-scheme='dark'] .closeButton,
[data-mui-color-scheme='dark'] .iconButton {
  color: var(--dark-text-light);
}

[data-mui-color-scheme='dark'] .iconButton:hover {
  color: var(--dark-accent);
}

[data-mui-color-scheme='dark'] .roomContent {
  background-color: var(--dark-foreground);
  border-color: var(--dark-border);
}

[data-mui-color-scheme='dark'] .tasksTitle {
  color: var(--dark-text);
}

[data-mui-color-scheme='dark'] .submitButton {
  background: linear-gradient(135deg, var(--dark-primary), var(--dark-accent)) !important;
  box-shadow: var(--shadow-sm-dark) !important;
}

[data-mui-color-scheme='dark'] .submitButton:hover {
  background: linear-gradient(135deg, var(--dark-accent), var(--dark-primary)) !important;
  box-shadow: var(--shadow-md-dark) !important;
}

[data-mui-color-scheme="dark"] .cancelButton {
  background-color: var(--error) !important;
  color: white !important;
  border: 1px solid var(--error) !important;
  transition: all 0.2s ease;
}

[data-mui-color-scheme="dark"] .cancelButton:hover {
  background-color: #f06c6c !important; /* Increase visibility */
  border-color: var(--error) !important;
  box-shadow: var(--shadow-md-dark) !important;
}

[data-mui-color-scheme='dark'] .noTasks,
[data-mui-color-scheme='dark'] .noRooms {
  color: var(--dark-text-light);
}

[data-mui-color-scheme='dark'] .searchContainer {
  border-color: var(--dark-border);
  background-color: var(--dark-foreground);
}

[data-mui-color-scheme="dark"] .quantityControls {
  background: var(--dark-text-area);
  border-color: var(--dark-border);
}

[data-mui-color-scheme="dark"] .quantityButton {
  color: var(--dark-text);
}

[data-mui-color-scheme="dark"] .quantityButton:hover:not(:disabled) {
  color: var(--dark-primary);
}

[data-mui-color-scheme="dark"] .quantityButton:disabled {
  color: var(--dark-text-lighter);
}

/* Animation */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modalContent {
  animation: slideIn 0.3s ease-out;
}

/* Common Button Styles */
.createButton,
.closeButton,
.cancelButton,
.submitButton {
  border-radius: 4px !important;
  text-transform: none !important;
  font-weight: 500 !important;
  padding: 8px 16px !important;
  height: 36px !important;
  min-width: 40px !important;
  font-size: 14px !important;
}

.createButton {
  background: linear-gradient(135deg, var(--light-secondary), var(--light-secondary-accent)) !important;
  color: white !important;
  border: none !important;
  transition: all 0.2s ease !important;
  box-shadow: var(--shadow-sm-light) !important;
}

.createButton:hover {
  background: linear-gradient(135deg, var(--light-secondary-accent), var(--light-secondary)) !important;
  box-shadow: var(--shadow-md-light) !important;
  transform: translateY(-1px);
}

[data-mui-color-scheme="dark"] .createButton {
  background: linear-gradient(135deg, var(--dark-secondary), var(--dark-secondary-accent)) !important;
  box-shadow: var(--shadow-sm-dark) !important;
}

[data-mui-color-scheme="dark"] .createButton:hover {
  background: linear-gradient(135deg, var(--dark-secondary-accent), var(--dark-secondary)) !important;
  box-shadow: var(--shadow-md-dark) !important;
}

/* Close and Create Room Button Styles */
.closeButton {
  background: var(--light-secondary) !important;
  border: 1px solid var(--light-secondary) !important;
  color: white !important;
  cursor: pointer;
  transition: all 0.2s ease;
}

.closeButton:hover {
  background: var(--light-secondary-accent) !important;
  box-shadow: var(--shadow-md-light) !important;
}

/* Footer Button Styles */
.cancelButton {
  background-color: var(--error) !important;
  color: white !important;
  border: 1px solid var(--error) !important;
  transition: all 0.2s ease;
}

.cancelButton:hover {
  background-color: #f06c6c !important;
  border-color: var(--error) !important;
  box-shadow: var(--shadow-md-light) !important;
}

.submitButton {
  background: linear-gradient(135deg, var(--light-primary), var(--light-accent)) !important;
  color: white !important;
  border: none !important;
  transition: all 0.2s ease !important;
  box-shadow: var(--shadow-sm-light) !important;
}

.submitButton:hover {
  background: linear-gradient(135deg, var(--light-accent), var(--light-primary)) !important;
  box-shadow: var(--shadow-md-light) !important;
  transform: translateY(-1px);
}

/* Dark Mode Button Styles */
[data-mui-color-scheme="dark"] .closeButton {
  background: var(--dark-secondary) !important;
  border-color: var(--dark-secondary) !important;
}

[data-mui-color-scheme="dark"] .closeButton:hover {
  background: var(--dark-secondary-accent) !important;
  box-shadow: var(--shadow-md-dark) !important;
}

[data-mui-color-scheme="dark"] .cancelButton {
  background-color: var(--error) !important;
  color: white !important;
  border: 1px solid var(--error) !important;
}

[data-mui-color-scheme="dark"] .cancelButton:hover {
  background-color: #f06c6c !important;
  border-color: var(--error) !important;
  box-shadow: var(--shadow-md-dark) !important;
}

[data-mui-color-scheme="dark"] .submitButton {
  background: linear-gradient(135deg, var(--dark-primary), var(--dark-accent)) !important;
  box-shadow: var(--shadow-sm-dark) !important;
}

[data-mui-color-scheme="dark"] .submitButton:hover {
  background: linear-gradient(135deg, var(--dark-accent), var(--dark-primary)) !important;
  box-shadow: var(--shadow-md-dark) !important;
}
