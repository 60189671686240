.container {
    width: 100%;
    padding: 1rem;
  }
  
  .metricsGrid {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .metricCard {
    background-color: var(--dark-foreground);
    border-radius: var(--border-radius-base);
    padding: 1rem;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    box-shadow: var(--shadow-md-dark);
    transition: background-color var(--transition-duration) var(--transition-ease);
  }
  
  [data-mui-color-scheme="light"] .metricCard {
    background-color: var(--light-foreground);
    box-shadow: var(--shadow-md-light);
  }
  
  .metricHeader {
    color: var(--dark-text-light);
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
  
  [data-mui-color-scheme="light"] .metricHeader {
    color: var(--light-text-light);
  }
  
  .metricValue {
    color: var(--dark-text);
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  
  [data-mui-color-scheme="light"] .metricValue {
    color: var(--light-text);
  }
  
  .trendContainer {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-bottom: 0.25rem;
  }
  
  .trendValue {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .trend-positive {
    color: var(--success);
  }
  
  .trend-negative {
    color: var(--error);
  }
  
  .trendIcon {
    width: 16px;
    height: 16px;
  }
  
  .metricPeriod {
    color: var(--dark-text-lighter);
    font-size: 0.75rem;
    margin-top: auto;
  }
  
  [data-mui-color-scheme="light"] .metricPeriod {
    color: var(--light-text-lighter);
  }
  
  .timeManagementCard {
    margin-top: 1rem;
    background-color: var(--dark-foreground);
    border-radius: var(--border-radius-base);
    overflow: hidden;
    box-shadow: var(--shadow-md-dark);
    margin-bottom: 80px;
  }
  
  [data-mui-color-scheme="light"] .timeManagementCard {
    background-color: var(--light-foreground);
    box-shadow: var(--shadow-md-light);
  }
  
  .cardHeader {
    padding: 1rem;
    border-bottom: 1px solid var(--dark-border);
  }
  
  [data-mui-color-scheme="light"] .cardHeader {
    border-bottom-color: var(--light-border);
  }
  
  .cardTitle {
    color: var(--dark-text);
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0;
  }
  
  [data-mui-color-scheme="light"] .cardTitle {
    color: var(--light-text);
  }
  
  .cardContent {
    padding: 1rem;
  }
  
  .timeManagement {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .timeLabel {
    color: var(--dark-text-light);
    font-size: 0.875rem;
    margin: 0;
  }
  
  [data-mui-color-scheme="light"] .timeLabel {
    color: var(--light-text-light);
  }
  
  .timeValue {
    color: var(--dark-text);
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
  }
  
  [data-mui-color-scheme="light"] .timeValue {
    color: var(--light-text);
  }
  
  /* Media Queries */
  @media (min-width: 768px) {
    .metricsGrid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 1024px) {
    .metricsGrid {
      grid-template-columns: repeat(4, 1fr);
    }
  }