
.sectionTitle {
    color: var(--light-primary);
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  
  [data-mui-color-scheme="dark"] .sectionTitle {
    color: var(--dark-primary);
  }
  
  .section {
    padding: 1rem 0;
    border-bottom: 1px solid var(--light-border);
  }
  
  [data-mui-color-scheme="dark"] .section {
    border-bottom: 1px solid var(--dark-border);
  }
  
  .section:last-child {
    border-bottom: none;
  }
  
  .infoLabel {
    font-weight: 500;
    color: var(--light-text);
    margin-right: 0.5rem;
  }
  
  [data-mui-color-scheme="dark"] .infoLabel {
    color: var(--dark-text);
  }
  
  .closeButton {
    padding: 4px;
    margin-right: -8px;
    color: var(--light-text-light);
    background-color: var(--light-focus-ring);
    border-radius: 50%;
  }
  
  [data-mui-color-scheme="dark"] .closeButton {
    color: var(--dark-text-light);
    background-color: var(--dark-focus-ring);
  }
  
  .closeButton:hover {
    background-color: var(--light-border);
  }
  
  [data-mui-color-scheme="dark"] .closeButton:hover {
    background-color: var(--dark-border);
  }