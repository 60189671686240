/* EditRecurringService.js */

.container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 1.5rem;
  border-radius: var(--border-radius-base);
  box-shadow: var(--shadow-md-light);
  transition: all var(--transition-duration) var(--transition-ease);
}

[data-mui-color-scheme='dark'] .container {
  box-shadow: var(--shadow-md-dark);
}

.section {
  padding: 1.5rem;
  background-color: var(--light-foreground);
  border-radius: var(--border-radius-base);
  margin-bottom: 1.5rem;
  border: 1px solid var(--light-border);
  transition: all var(--transition-duration) var(--transition-ease);
}

[data-mui-color-scheme='dark'] .section {
  background-color: var(--dark-foreground);
  border-color: var(--dark-border);
}

.section h6 {
  color: var(--light-text);
  margin-bottom: 1.5rem;
  font-family: var(--font-family-base);
  font-weight: 600;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

[data-mui-color-scheme='dark'] .section h6 {
  color: var(--dark-text);
}

.section:hover {
  box-shadow: var(--shadow-lg-light);
  transform: translateY(-2px);
}

[data-mui-color-scheme='dark'] .section:hover {
  box-shadow: var(--shadow-lg-dark);
}

/* Grid Layout for Forms */
.formGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
}

/* Room List Styling */
.roomList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.roomItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: var(--light-foreground-accent);
  border-radius: var(--border-radius-base);
  border: 1px solid var(--light-border);
  transition: all var(--transition-duration) var(--transition-ease);
}

[data-mui-color-scheme='dark'] .roomItem {
  background-color: var(--dark-foreground-accent);
  border-color: var(--dark-border);
}

.roomItem:hover {
  transform: translateY(-1px);
  box-shadow: var(--shadow-md-light);
}

[data-mui-color-scheme='dark'] .roomItem:hover {
  box-shadow: var(--shadow-md-dark);
}

/* Actions Section */
.actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: var(--light-foreground);
  border-radius: var(--border-radius-base);
  border: 1px solid var(--light-border);
}

[data-mui-color-scheme='dark'] .actions {
  background-color: var(--dark-foreground);
  border-color: var(--dark-border);
}

/* Responsive Design */
@media (max-width: 1200px) {
  .container {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .section {
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .formGrid {
    grid-template-columns: 1fr;
  }

  .roomList {
    grid-template-columns: 1fr;
  }
}