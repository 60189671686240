/* src/styles/Calendar.module.css */
.container {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  padding-top: 10px;
}

.header {
  display: flex;
  margin-bottom: 16px;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
}

.headerActions { 
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.todayActionsButton {
  display: flex;
  padding: 4px 16px;
  font-size: 14px;
  color: #9DCBFC;
  border: 1px solid #9DCBFC;
  border-radius: 12px;
  cursor: pointer;
  align-items: center;
}


.todayActionsButton:hover {
  color: #000000;
  background-color: #9DCBFC;
}

.headerActionsButton {
  display: flex;
  padding: 8px;
  font-size: 24px;
  color: #9DCBFC !important;
  background-color: transparent !important;
  cursor: pointer;
}

.headerMonth {
  font-size: 28px;
}

.calendar {
  display: flex;
  flex-direction: column;
  background-color: #191C20;
  border-radius: 12px;
  overflow: hidden;
}

.calendarCleaners {
  height: 80vh;
  max-height: 80vh;
  overflow-y: scroll;
  margin-left: 3px;
}

.row {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  border-bottom: 1px solid #32353A;
}

.columnDivider {
  border-left: 1px solid #32353A;
}

.rowItem {
  position: relative;
  display: flex;
  flex-direction: row;
  border-right: 1px solid #32353A;
}

.rowItem:last-child {
  border-right: none;
}

.rowItemHourly {
  position: relative;
  height: 40px;
  width: 14%;
  max-width: 14%;
  min-width: 14%;
}

.rowHeading {
  width: 100%;
  padding: 12px;
  word-wrap: break-word;
}

.rowHeading p {
  margin: 0;
}

.columnDate {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  text-align: center;
  border-bottom: 1px solid #32353A;
}

.columnDate p {
  margin: 0;
  width: 100%;
  text-align: center;
}

.columnDateToday {
  background-color: #9DCBFC;
  padding: 2px;
  max-width: 15%;
  border-radius: 16px;
  color: #191C20;
}

.columnWeekDayToday {
  color: #9DCBFC;
}

.dayJobs {
  width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}