.orgContainer {
    max-width: 1200px;
    margin: 1rem auto;
    padding: 1rem;
    background-color: var(--light-foreground);
    color: var(--light-text);
    border-radius: var(--border-radius-base);
  }
  
  .orgTitle {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .orgContent {
    background-color: var(--light-foreground);
    padding: 1rem;
    border-radius: var(--border-radius-base);
  }
  
  .orgSection {
    margin-bottom: 2rem;
  }
  
  .label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .orgInputGroup {
    display: flex;
    align-items: center;
  }
  
  .input {
    flex: 1;
    padding: 0.5rem;
    border: 1px solid var(--light-border);
    border-radius: var(--border-radius-base);
  }
  
  .orgButton {
    padding: 0.5rem 1rem;
    margin-left: 0.5rem;
    border: none;
    border-radius: var(--border-radius-base);
    cursor: pointer;
    text-decoration: none !important;
  }
  
  .orgButtonPrimary {
    background-color: var(--light-primary);
    color: white;
  }
  
  .orgButtonSecondary {
    background-color: var(--light-secondary);
    color: white;
  }
  
  .buttonLink {
    background: none;
    color: var(--light-primary);
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    margin: 0 0.5rem;
  }
  
  .buttonDanger {
    background-color: var(--error);
    color: white;
  }
  
  .orgSectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .orgSectionTitle {
    margin: 0;
  }
  
  .usersList {
    margin-top: 1rem;
  }
  
  .userCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 1px solid var(--light-border);
  }
  
  .userInfo {
    flex: 1;
  }
  
  .userName {
    font-weight: bold;
  }
  
  .userRole {
    color: var(--light-text-light);
  }
  
  .userDetails {
    font-size: 0.9rem;
    color: var(--light-text-lighter);
  }
  
  .userActions {
    display: flex;
    align-items: center;
  }
  
  .errorMessage {
    color: var(--error);
    margin-top: 1rem;
  }
  
  .successMessage {
    color: var(--success);
    margin-top: 1rem;
  }
  
  .loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }
  
  .loader {
    border: 4px solid var(--light-border);
    border-top: 4px solid var(--light-primary);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  .errorContainer {
    max-width: 800px;
    margin: 2rem auto;
    padding: 1rem;
    background-color: var(--error);
    color: var(--error-content);
    border-radius: var(--border-radius-base);
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

    /* Icon Button Base Styles */
.orgIconButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 0;
    border: none;
    border-radius: var(--border-radius-base);
    cursor: pointer;
    transition: all var(--transition-duration) var(--transition-ease);
    background: transparent;
  }
  
  .orgIconButton:hover {
    transform: translateY(-1px);
  }
  
  .orgIconButton:active {
    transform: translateY(0);
  }
  
  /* Primary Icon Button */
  .orgIconButtonPrimary {
    color: var(--light-primary);
  }
  
  .orgIconButtonPrimary:hover {
    color: var(--light-accent);
    background-color: var(--light-focus-ring);
  }
  
  /* Danger Icon Button */
  .orgIconButtonDanger {
    color: var(--error);
  }
  
  .orgIconButtonDanger:hover {
    color: var(--error);
    background-color: rgba(235, 70, 70, 0.1);
  }
  
  /* Dark Mode Overrides */
  [data-mui-color-scheme='dark'] .orgContainer {
    background-color: var(--dark-foreground);
    color: var(--dark-text);
  }
  
  [data-mui-color-scheme='dark'] .orgContent {
    background-color: var(--dark-foreground-accent);
  }
  
  [data-mui-color-scheme='dark'] .input {
    border: 1px solid var(--dark-border);
    background-color: var(--dark-text-area);
    color: var(--dark-text);
  }

  [data-mui-color-scheme='dark'] .orgButton {
    background-color: var(--dark-primary);
    color: var(--dark-text);
  }
  
  [data-mui-color-scheme='dark'] .orgButtonPrimary {
    background-color: var(--dark-primary);
    color: var(--dark-text);
  }
  
  [data-mui-color-scheme='dark'] .orgButtonSecondary {
    background-color: var(--dark-secondary);
  }
  
  [data-mui-color-scheme='dark'] .buttonLink {
    color: var(--dark-text);
  }
  
  [data-mui-color-scheme='dark'] .buttonDanger {
    background-color: var(--error);
  }
  
  [data-mui-color-scheme='dark'] .userRole {
    color: var(--dark-text-light);
  }
  
  [data-mui-color-scheme='dark'] .userDetails {
    color: var(--dark-text-lighter);
  }
  
  [data-mui-color-scheme='dark'] .loader {
    border: 4px solid var(--dark-border);
    border-top: 4px solid var(--dark-primary);
  }
  
  [data-mui-color-scheme='dark'] .errorContainer {
    background-color: var(--error);
    color: var(--error-content);
  }
  
  /* Dark Mode Overrides */

  [data-mui-color-scheme='dark'] .orgButtonPrimary:hover {
    color: var(--dark-accent);
    background-color: var(--dark-focus-ring);
  }

  [data-mui-color-scheme='dark'] .orgButtonDanger {
    color: var(--error);
  }

  [data-mui-color-scheme='dark'] .orgButtonDanger:hover {
    background-color: rgba(235, 70, 70, 0.2);
  }

  /* Add these to your existing OrgSettings.module.css file */

.sectionTitle {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.sectionDescription {
  color: var(--light-text-light);
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.textarea {
  font-family: monospace;
  padding: 1rem;
  line-height: 1.5;
  background-color: var(--light-text-area);
  resize: vertical;
}

.placeholderList {
  list-style: none;
  padding: 0;
  margin: 0 0 1rem 0;
}

.placeholderList li {
  color: var(--light-text-light);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  position: relative;
}

.placeholderList li:before {
  content: "•";
  position: absolute;
  left: 0;
}

.previewSection {
  margin: 2rem 0;
  padding: 1rem;
  background-color: var(--light-foreground-accent);
  border-radius: var(--border-radius-base);
}

.previewSection h4 {
  margin: 0 0 1rem 0;
  font-size: 1rem;
  font-weight: 500;
}

.previewBox {
  background-color: var(--light-text-area);
  padding: 1rem;
  border-radius: var(--border-radius-base);
  white-space: pre-wrap;
}

.previewBox p {
  margin: 0 0 0.5rem 0;
}

.feeInputGroup {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.feeInputGroup .input {
  max-width: 200px;
}

.saveButtonContainer {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid var(--light-border);
}

/* Dark mode overrides */
[data-mui-color-scheme='dark'] .textarea {
  background-color: var(--dark-text-area);
  color: var(--dark-text);
  border-color: var(--dark-border);
}

[data-mui-color-scheme='dark'] .sectionDescription {
  color: var(--dark-text-light);
}

[data-mui-color-scheme='dark'] .previewSection {
  background-color: var(--dark-foreground-accent);
}

[data-mui-color-scheme='dark'] .previewBox {
  background-color: var(--dark-text-area);
  color: var(--dark-text);
}

[data-mui-color-scheme='dark'] .placeholderList li {
  color: var(--dark-text-light);
}
