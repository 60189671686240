/* /src/styles/global.css */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

:root {
  /* Light Theme Colors - Refined for better contrast and harmony */
  --light-text: #1a2634;
  --light-text-secondary: #2d3846;
  --light-background: #f5f8fa;
  --light-foreground: #ffffff;
  --light-foreground-accent: #eef2f6;
  --light-primary: #3b8de4;
  --light-secondary: #f39342;
  --light-accent: #6baaed;
  --light-text-light: #4a6785;
  --light-text-lighter: #7291b3;
  
  /* Dark Theme Colors - Improved depth and contrast */
  --dark-text: #ffffff;
  --dark-text-secondary: #e6e9ec;
  --dark-text-area: #3d5d82;
  --dark-text-hover: #34526f;
  --dark-dropdowns: #3d5d82;
  --dark-background: #1a324d;
  --dark-foreground: #2d4661;
  --dark-foreground-accent: #456485;
  --dark-primary: #3b8de4;
  --dark-divider: #385778;
  --dark-secondary: #f39342;
  --dark-secondary-accent: #f4aa6c;
  --dark-accent: #6baaed;
  --dark-text-light: #ccd9e6;
  --dark-text-lighter: #9db5cc;
  
  /* Message Colors - Adjusted for better readability */
  --message-contact: #4ed672;
  --message-user: #3b8de4;
  
  /* Border Colors - Refined for better definition */
  --light-border: #dde5ed;
  --dark-border: #3f6083;
  
  /* Focus Rings - Maintained with slight adjustment */
  --light-focus-ring: rgba(59, 141, 228, 0.5);
  --dark-focus-ring: rgba(59, 141, 228, 0.5);
  
  /* Status Colors - Enhanced for better visibility */
  --success: #4ed672;
  --warning: #f3d942;
  --error: #e65a5a;
  --success-content: #0a3517;
  --warning-content: #332d09;
  --error-content: #ffffff;
  
  /* Shadows - Refined for better depth perception */
  --shadow-sm-light: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --shadow-md-light: 0 4px 6px -1px rgba(0, 0, 0, 0.08), 0 2px 4px -1px rgba(0, 0, 0, 0.04);
  --shadow-lg-light: 0 10px 15px -3px rgba(0, 0, 0, 0.08), 0 4px 6px -2px rgba(0, 0, 0, 0.04);
  --shadow-xl-light: 0 20px 25px -5px rgba(0, 0, 0, 0.08), 0 10px 10px -5px rgba(0, 0, 0, 0.03);
  
  --shadow-sm-dark: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
  --shadow-md-dark: 0 4px 6px -1px rgba(0, 0, 0, 0.45), 0 2px 4px -1px rgba(0, 0, 0, 0.4);
  --shadow-lg-dark: 0 10px 15px -3px rgba(0, 0, 0, 0.45), 0 4px 6px -2px rgba(0, 0, 0, 0.4);
  --shadow-xl-dark: 0 20px 25px -5px rgba(0, 0, 0, 0.45), 0 10px 10px -5px rgba(0, 0, 0, 0.38);
  
  /* System Variables - Kept the same */
  --font-family-base: 'Roboto', sans-serif;
  --border-radius-base: 0.5rem;
  --transition-duration: 0.3s;
  --transition-ease: ease;
  --sidebar-width: 55px;
  --header-height: 40px;
  --light-texture: rgba(236, 225, 225, 0.199);
  --dark-texture: rgba(0, 0, 0, 0.1);

  /* CreateJobForm specific colors */
  --form-highlight-light: #f0f7ff;
  --form-highlight-dark: #1e3a5a;
  --form-section-hover-light: #f8fafc;
  --form-section-hover-dark: #2d4661;
  --form-input-focus-ring: rgba(59, 141, 228, 0.15);
  --form-required-light: #e65a5a;
  --form-required-dark: #ff7b7b;
  --form-label-light: #4a6785;
  --form-label-dark: #9db5cc;
  --form-placeholder-light: #94a3b8;
  --form-placeholder-dark: #64748b;
  --form-button-hover-light: #2d80e0;
  --form-button-hover-dark: #5299e9;
  --form-button-active-light: #1c69c9;
  --form-button-active-dark: #3b8de4;

  /* Form specific variables */
  --switch-hover-opacity: 0.08;
  --card-header-padding: 16px;
  --section-spacing: 24px;

  /* Animation Variables */
  --animation-duration-fast: 150ms;
  --animation-duration-normal: 300ms;
  --animation-duration-slow: 450ms;
  --animation-easing: cubic-bezier(0.4, 0, 0.2, 1);
  
  /* Typography */
  --heading-font-weight: 600;
  --heading-font-size: 1.25rem;
  
  /* RGB values for opacity variations */
  --light-primary-rgb: 59, 141, 228;
  --dark-primary-rgb: 59, 141, 228;
}

body {
  font-family: var(--font-family-base);
  background-color: var(--light-background);
  color: var(--light-text);
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  transition: background-color var(--transition-duration) var(--transition-ease), 
              color var(--transition-duration) var(--transition-ease);
}

body.dark-mode {
  background-color: var(--dark-background);
  color: var(--dark-text);
}

.app-layout { display: flex; }

/* Srollbar Styles */
.call-list-container, .message-list-container, .client-dropdown, 
.transcription-container, .transcription-processtext-container,
.transcription-output, .transcription-textarea {
  scrollbar-width: thin;
  scrollbar-color: var(--light-primary) var(--light-background);
}

.call-list-container::-webkit-scrollbar,
.message-list-container::-webkit-scrollbar,
.client-dropdown::-webkit-scrollbar,
.transcription-container::-webkit-scrollbar,
.transcription-processtext-container::-webkit-scrollbar,
.transcription-output::-webkit-scrollbar,
.transcription-textarea::-webkit-scrollbar {
  width: 8px;
}

.call-list-container::-webkit-scrollbar-track,
.message-list-container::-webkit-scrollbar-track,
.client-dropdown::-webkit-scrollbar-track,
.transcription-container::-webkit-scrollbar-track,
.transcription-processtext-container::-webkit-scrollbar-track,
.transcription-output::-webkit-scrollbar-track,
.transcription-textarea::-webkit-scrollbar-track {
  background: var(--light-background);
  border-radius: 4px;
}

.call-list-container::-webkit-scrollbar-thumb,
.message-list-container::-webkit-scrollbar-thumb,
.client-dropdown::-webkit-scrollbar-thumb,
.transcription-container::-webkit-scrollbar-thumb,
.transcription-processtext-container::-webkit-scrollbar-thumb,
.transcription-output::-webkit-scrollbar-thumb,
.transcription-textarea::-webkit-scrollbar-thumb {
  background-color: var(--light-primary);
  border-radius: 4px;
  border: 2px solid var(--light-background);
}

.call-list-container::-webkit-scrollbar-thumb:hover,
.message-list-container::-webkit-scrollbar-thumb:hover,
.client-dropdown::-webkit-scrollbar-thumb:hover,
.transcription-container::-webkit-scrollbar-thumb:hover,
.transcription-processtext-container::-webkit-scrollbar-thumb:hover,
.transcription-output::-webkit-scrollbar-thumb:hover,
.transcription-textarea::-webkit-scrollbar-thumb:hover {
  background-color: var(--light-accent);
}

/* Dark mode scrollbar styles */
body.dark-mode .call-list-container,
body.dark-mode .message-list-container,
body.dark-mode .client-dropdown,
body.dark-mode .transcription-container,
body.dark-mode .transcription-processtext-container,
body.dark-mode .transcription-output,
body.dark-mode .transcription-textarea {
  scrollbar-color: var(--dark-foreground) var(--dark-background);
}

body.dark-mode .call-list-container::-webkit-scrollbar-track,
body.dark-mode .message-list-container::-webkit-scrollbar-track,
body.dark-mode .client-dropdown::-webkit-scrollbar-track,
body.dark-mode .transcription-container::-webkit-scrollbar-track,
body.dark-mode .transcription-processtext-container::-webkit-scrollbar-track,
body.dark-mode .transcription-output::-webkit-scrollbar-track,
body.dark-mode .transcription-textarea::-webkit-scrollbar-track {
  background: var(--dark-background);
}

body.dark-mode .call-list-container::-webkit-scrollbar-thumb,
body.dark-mode .message-list-container::-webkit-scrollbar-thumb,
body.dark-mode .client-dropdown::-webkit-scrollbar-thumb,
body.dark-mode .transcription-container::-webkit-scrollbar-thumb,
body.dark-mode .transcription-processtext-container::-webkit-scrollbar-thumb,
body.dark-mode .transcription-output::-webkit-scrollbar-thumb,
body.dark-mode .transcription-textarea::-webkit-scrollbar-thumb {
  background-color: var(--dark-accent);
  border: 2px solid var(--dark-background);
}

/* Header styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--header-height);
  padding: 0 20px;
  background-color: var(--light-foreground);
  box-shadow: var(--shadow-sm-light);
  border-radius: 4px;
  height: var(--header-height);
}

body.dark-mode .header {
  background-color: var(--dark-foreground);
  box-shadow: var(--shadow-sm-dark);
}

.header h1 {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.nav-right {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Switch styles */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input { opacity: 0; width: 0; height: 0; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0; left: 0; right: 0; bottom: 0;
  background-color: var(--light-background);
  transition: .4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: var(--light-background);
  transition: .4s;
  border-radius: 50%;
}

.slider .icon {
  position: absolute;
  left: 2px;
  bottom: 2px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  transition: transform .4s;
}

input:checked + .slider { background-color: var(--light-accent); }
input:checked + .slider:before { transform: translateX(20px); }
input:checked + .slider .icon { transform: translateX(20px); }

/* Button styles */
.btn, button {
  padding: 0 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color var(--transition-duration) var(--transition-ease),
              color var(--transition-duration) var(--transition-ease);
  line-height: 18px;
  font-size: 14px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  margin: 0 5px;
  vertical-align: middle;
  white-space: nowrap;
  min-width: 90px;
}

.btn-secondary { background-color: var(--light-secondary); color: var(--secondary-content); }
.btn-primary { background-color: var(--light-primary); color: var(--primary-content); }

.btn-primary:hover, button:hover { background-color: var(--dark-primary); }
.btn-secondary:hover { background-color: var(--dark-secondary); }

body.dark-mode .btn-secondary { background-color: var(--dark-secondary); }
body.dark-mode .btn-primary { background-color: var(--dark-accent); }

body.dark-mode .btn-primary:hover { background-color: var(--dark-accent); }
body.dark-mode .btn-secondary:hover { background-color: var(--dark-primary); }

/* Sidebar styles */
.sidebar {
  background-color: var(--light-secondary);
  height: 100vh;
  width: var(--sidebar-width);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  box-shadow: var(--shadow-sm-light);
  transition: background-color var(--transition-duration) var(--transition-ease);
  z-index: 1000;
  overflow: visible;
}

.relative {
    position: relative;
}

body.dark-mode .sidebar {
  background-color: var(--dark-foreground);
  box-shadow: var(--shadow-sm-dark);
}

.logo {
  width: 48px;
  height: 48px;
  margin-bottom: 40px;
}

.sidebar a {
  margin-bottom: 20px;
  color: var(--light-text);
  font-size: 24px;
  transition: color var(--transition-duration) var(--transition-ease);
}

.sidebar a:hover,
.sidebar a.active { color: var(--light-primary); }

body.dark-mode .sidebar a { color: var(--dark-text); }
body.dark-mode .sidebar a:hover,
body.dark-mode .sidebar a.active { color: var(--dark-primary); }

/* Tooltip styles */
.tooltip-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  isolation: isolate; /* Ensures stacking context */
}

.tooltip {
  position: absolute; /* or 'fixed' for global positioning */
  left: calc(100% + 7px);
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--light-secondary);
  padding: 8px 12px;
  border-radius: 6px;
  box-shadow: var(--shadow-sm-light);
  z-index: 1050; /* Higher than other elements */
  min-width: 200px;
  white-space: nowrap;
  pointer-events: auto;
}

body.dark-mode .tooltip {
  background-color: var(--dark-foreground);
  box-shadow: var(--shadow-sm-dark);
}

.tooltip-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--light-text);
  border-bottom: 1px solid var(--light-border) !important;
  margin: 0 -12px 8px -12px;
  padding: 0 12px 8px 12px;
}

/* Add an invisible hover bridge */
.tooltip::before {
  content: '';
  position: absolute;
  top: 0;
  left: -10px;
  width: 20px; /* Width of the bridge */
  height: 100%;
  background: transparent;
}

.tooltip-links {
  display: flex;
  flex-direction: column;
}

.tooltip-link {
  color: var(--light-text);
  font-size: 12px !important;
  text-decoration: none;
  transition: color var(--transition-duration) var(--transition-ease);
}

.tooltip-link:hover {
  color: var(--light-primary);
  z-index: 1000;
}

body.dark-mode .tooltip-title {
  color: var(--dark-text);
  border-bottom-color: var(--dark-foreground-accent) !important;
}

body.dark-mode .tooltip-link {
  color: var(--dark-text);
}

body.dark-mode .tooltip-link:hover {
  color: var(--dark-primary);
}



/* Main content styles */
.main-content {
  flex-grow: 1;
  padding: 20px;
  margin-left: var(--sidebar-width);
}

.client-sales-tools-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Card styles */
.card {
  background-color: var(--light-foreground);
  border-radius: 8px;
  padding: 10px;
  box-shadow: var(--shadow-sm-light);
}

.top-section {
  display: flex;
  gap: 10px;
  margin: 10px 0;
}

.contacts-section, .messages-section, .transcription-section, .profile-section, .custom-fields-section {
  flex: 1;
}

.card input[type="text"], 
.card textarea {
  background-color: var(--light-background);
  border: 1px solid var(--light-border);
  border-radius: 8px;
  padding: 8px;
  width: 98%;
  color: var(--light-text);
}

.card input[type="text"]:focus, 
.card textarea:focus {
  outline: none;
  border-color: var(--light-accent);
}

.card h2 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: var(--light-text);
}

.card, .card input, .card textarea {
  color: var(--light-text);
}

.no-contact-selected {
  color: var(--light-text-light);
  font-style: italic;
}

/* Button styles within cards */
.btn, button {
  padding: 1px 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  display: inline-flex;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin: 5px;
}

.btn-primary {
  background-color: var(--light-primary);
  color: white;
}

.btn-secondary {
  background-color: var(--light-secondary);
  color: white;
}

.btn-primary:hover {
  background-color: var(--light-accent);
}

.btn-secondary:hover {
  background-color: var(--dark-secondary-accent);
}

.top-section-card {
  background-color: var(--light-accent);
  border-radius: 8px;
  box-shadow: var(--shadow-sm-light);
  padding: 20px;
  flex: 1;
  border: 1px solid var(--light-border);
}

.card-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  color: var(--light-text);
}

/* Form element styles */
/* input[type="text"], input[type="date"] {
  width: 80%;
  padding: 10px;
  border: 1px solid var(--light-border);
  border-radius: 4px;
  font-size: 16px;
  background-color: var(--light-background);
  color: var(--light-text);
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
}

input[type="text"]:focus, input[type="date"]:focus {
  outline: none;
  border-color: var(--light-accent);
  box-shadow: 0 0 0 3px var(--light-focus-ring);
} */

button {
  background-color: var(--light-primary);
  color: var(--light-text);
  border: none;
  border-radius: 4px;
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin-bottom: 10px;
}

button:focus {
  outline: none;
  box-shadow: 0 0 0 3px var(--light-focus-ring);
}

/* Dark mode styles */
body.dark-mode .card {
  background-color: var(--dark-foreground);
  box-shadow: var(--shadow-sm-dark);
}

body.dark-mode .card input[type="text"], 
body.dark-mode .card textarea {
  background-color: var(--dark-text-area);
  border-color: var(--dark-border);
  color: var(--dark-text);
}

body.dark-mode .card input[type="text"]:focus, 
body.dark-mode .card textarea:focus {
  border-color: var(--dark-accent);
}

body.dark-mode .card h2,
body.dark-mode .card,
body.dark-mode .card input,
body.dark-mode .card textarea {
  color: var(--dark-text);
}

body.dark-mode .no-contact-selected {
  color: var(--dark-text-light);
}

/* Dark Mode Buttoms */
body.dark-mode .card .btn {
  background-color: var(--dark-accent);
  color: var(--dark-text);
}

/* Dark mode button styles */
body.dark-mode .btn-primary {
  background-color: var(--dark-primary);
}

body.dark-mode .btn-secondary {
  background-color: var(--dark-secondary);
}

body.dark-mode .card .btn:hover {
  background-color: var(--dark-primary);
}

body.dark-mode .card .btn-secondary {
  background-color: var(--dark-secondary);
}

body.dark-mode .card .btn-secondary:hover {
  background-color: var(--dark-secondary-accent);
}

body.dark-mode .top-section-card {
  background-color: var(--dark-foreground);
  box-shadow: var(--shadow-sm-dark);
  border-color: var(--dark-border);
}

body.dark-mode .card-title {
  color: var(--dark-text);
}

body.dark-mode input[type="text"],
body.dark-mode input[type="date"] {
  border-color: var(--dark-border);
  background-color: var(--dark-text-area);
  color: var(--dark-text);
}

body.dark-mode input[type="text"]:focus,
body.dark-mode input[type="date"]:focus {
  border-color: var(--dark-accent);
  box-shadow: 0 0 0 3px var(--dark-focus-ring);
}

body.dark-mode button {
  background-color: var(--dark-accent);
  color: var(--dark-text);
}

body.dark-mode button:focus {
  box-shadow: 0 0 0 3px var(--dark-focus-ring);
}

/* Client list styles */
.client-list {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
}

.client-list li {
  padding: 12px;
  border-bottom: 1px solid var(--light-border);
  cursor: pointer;
}

body.dark-mode .client-list li {
  border-bottom: 1px solid var(--dark-border);
}

.client-list li:last-child { border-bottom: none; }

/* Search and date picker styles */
.search-section, .datepicker-section {
  background-color: var(--light-foreground);
  border-radius: 8px;
  padding: 15px;
  box-shadow: var(--shadow-sm-light);
}

.search-section { flex: 3; }
.datepicker-section { flex: 1; min-width: 200px; }

body.dark-mode .search-section,
body.dark-mode .datepicker-section {
  background-color: var(--dark-foreground);
  box-shadow: var(--shadow-sm-dark);
}

.search-clients-container {
  position: relative;
  width: 100%;
}

.search-input input, .date-input input {
  width: 98%;
  padding: 10px;
  background-color: var(--light-background);
  border: 1px solid var(--light-border);
  border-radius: 4px;
  color: var(--copy);
}

.search-input input::placeholder { color: var(--copy-lighter); }

/* Client Dropdown */
.client-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: var(--light-foreground);
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: var(--shadow-md-light);
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
}

.client-list-item {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.client-list-item:hover { background-color: var(--background); }

.client-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--primary);
  color: var(--primary-content);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 12px;
}

.client-info { flex-grow: 1; }
.client-name { font-weight: bold; color: var(--light-text); }
.client-email, .client-phone { font-size: 0.9em; color: var(--light-text-secondary); }

.loading-message, .error-message, .no-results {
  padding: 8px 12px;
  color: var(--copy-light);
}

.error-message { color: var(--error); }

/* Dark mode adjustments */
body.dark-mode .client-dropdown {
  background-color: var(--dark-dropdowns);
  border-color: var(--dark-border);
  box-shadow: var(--shadow-md-dark);
}

body.dark-mode .client-list-item:hover { background-color: var(--dark-accent); }
body.dark-mode .client-name { color: var(--dark-text); }
body.dark-mode .client-email, 
body.dark-mode .client-phone { color: var(--dark-text-secondary); }
body.dark-mode .loading-message, 
body.dark-mode .no-results { color: var(--dark-copy-light); }

.client-name.fallback {
  font-style: italic;
  color: var(--copy-lighter);
}

body.dark-mode .client-name.fallback { color: var(--dark-copy-lighter); }

/* React Datepicker Styles */
.react-datepicker {
  font-family: var(--font-family-base);
  border-radius: 4px;
  border: 1px solid var(--border);
  background-color: var(--light-foreground);
  font-size: 1rem;
  width: 320px;  /* Added for larger picker */
}

.react-datepicker__header {
  background-color: var(--background);
  border-bottom: 1px solid var(--light-border);
}

.react-datepicker__month-container {
  float: none;
  width: 100%;
}

.react-datepicker__month {
  margin: 0.8rem;  /* Slightly reduced margin */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-datepicker__month-container .react-datepicker__week {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.react-datepicker__day-names {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.react-datepicker__day,
.react-datepicker__day-name {
  width: 2.5rem;  /* Increased from 2rem */
  height: 2.5rem;
  line-height: 2.5rem;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.react-datepicker__current-month {
  font-size: 1.2rem;
  padding: 0.5rem 0;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day { color: var(--light-text); }

.react-datepicker__day:hover { background-color: var(--background); }
.react-datepicker__day--selected {
  background-color: var(--primary);
  color: var(--light-text);
}
.react-datepicker__day--disabled { color: var(--copy-lighter); }

/* Dark mode datepicker */
body.dark-mode .react-datepicker {
  background-color: var(--dark-dropdowns);
  border-color: var(--dark-border);
}

body.dark-mode .react-datepicker__header {
  background-color: var(--dark-accent);
  border-bottom-color: var(--dark-border);
}

body.dark-mode .react-datepicker__current-month,
body.dark-mode .react-datepicker__day-name,
body.dark-mode .react-datepicker__day { color: var(--dark-text); }

body.dark-mode .react-datepicker__day:hover { background-color: var(--dark-accent); }
body.dark-mode .react-datepicker__day--disabled { color: var(--dark-text); }
body.dark-mode .react-datepicker__navigation-icon::before { border-color: var(--dark-text); }
body.dark-mode .react-datepicker__year-read-view--down-arrow,
body.dark-mode .react-datepicker__month-read-view--down-arrow,
body.dark-mode .react-datepicker__month-year-read-view--down-arrow { border-top-color: var(--dark-text); }

body.dark-mode .react-datepicker__input-container input {
  background-color: var(--dark-text-area);
  color: var(--dark-text);
  border-color: var(--dark-border);
}

.react-datepicker__day--today { font-weight: bold; }
body.dark-mode .react-datepicker__day--today { color: var(--dark-foreground); }

/* Bottom Section Styles */
.bottom-section {
  display: flex;
  gap: 10px;
  height: calc(100vh - 200px);
}

.contacts-section, .messages-section {
  flex: 1;
  background-color: var(--light-foreground);
  border-radius: 8px;
  padding: 20px;
  box-shadow: var(--shadow-sm-light);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

body.dark-mode .contacts-section,
body.dark-mode .messages-section {
  background-color: var(--dark-foreground);
  box-shadow: var(--shadow-sm-dark);
}

.contacts-section h2, .messages-section h2 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: var(--copy);
}

/* Call List Styles */
.call-list-container {
  flex-grow: 1;
  overflow-y: auto;
}

.call-list-item {
  padding: 12px 16px;
  border-bottom: 1px solid var(--light-border);
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
}

.call-list-item:last-child { border-bottom: none; }
.call-list-item:hover { background-color: var(--background); }

.call-list-item-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--primary);
  color: var(--primary-content);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 12px;
}

.call-list-item-content { flex-grow: 1; }
.call-list-item-name {
  font-weight: bold;
  margin-bottom: 4px;
  color: var(--copy);
}
.call-list-item-date {
  font-size: 0.8em;
  color: var(--copy-light);
}

/* Message List Styles */
.message-list-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
  background-color: var(--light-background);
  background-image: 
    linear-gradient(45deg, var(--light-texture) 25%, transparent 25%),
    linear-gradient(-45deg, var(--light-texture) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, var(--light-texture) 75%),
    linear-gradient(-45deg, transparent 75%, var(--light-texture) 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  border: 2px solid var(--light-border);
  border-radius: 8px;
  transition: background-color 0.3s ease, border-color 0.3s ease, background-image 0.3s ease;
}

/* Dark mode styles */
body.dark-mode .message-list-container {
  background-color: var(--dark-background);
  background-image: 
    linear-gradient(45deg, var(--dark-texture) 25%, transparent 25%),
    linear-gradient(-45deg, var(--dark-texture) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, var(--dark-texture) 75%),
    linear-gradient(-45deg, transparent 75%, var(--dark-texture) 75%);
  border-color: var(--dark-border);
}

.message-list-item {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.message-bubble {
  max-width: 70%;
  padding: 12px 16px;
  border-radius: 18px;
  position: relative;
  word-wrap: break-word;
}

.message-bubble-user {
  align-self: flex-end;
  background-color: var(--message-user);
  color: var(--dark-text);
  border-bottom-right-radius: 4px;
}

.message-bubble-contact {
  align-self: flex-start;
  background-color: var(--message-contact);
  color: var(--light-text);
  border-bottom-left-radius: 4px;
}

.message-sender {
  font-weight: bold;
  margin-bottom: 4px;
  font-size: 0.9em;
  color: var(--copy);
}

.message-content { margin-bottom: 8px; }

.message-timestamp {
  font-size: 0.7em;
  color: var(--copy-lighter);
  margin-top: 4px;
  align-self: flex-end;
}

.message-recording {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.compact-audio-player {
  margin-bottom: 8px;
  width: 100%;
}

.compact-audio-player audio {
  width: 100%;
}

.fetch-recording-button,
.transcribe-button {
  background-color: var(--dark-accent); 
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  outline: none;
  font-size: 14px;
  text-align: center;
  margin-top: 8px;
  width: auto;
  min-width: 120px;
  white-space: nowrap;
}

.fetch-recording-button,
.transcribe-button {
  background-color: var(--dark-text-area) !important; /* Use the --light-primary color */
  color: #ffffff !important;
  font-weight: bold !important;
  padding: .00rem 1rem !important;
  border-radius: 0.25rem !important;
  transition: background-color 0.2s ease-in-out !important;
  cursor: pointer !important;
  border: none !important;
  outline: none !important;
  font-size: 14px !important;
  text-align: center !important;
  margin-top: 8px !important;
  width: auto !important;
  min-width: 120px !important;
  white-space: nowrap !important;
  display: inline-block !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.fetch-recording-button:hover,
.transcribe-button:hover {
  background-color: #2779bd !important; /* Darker shade for hover */
}

.fetch-recording-button:disabled,
.transcribe-button:disabled {
  background-color: #a0aec0 !important; /* Light grey when disabled */
  color: #718096 !important; /* Darker grey text when disabled */
  cursor: not-allowed !important;
}

.fetch-recording-button.loading {
  position: relative !important;
  color: transparent !important;
}

.fetch-recording-button.loading::after {
  content: "Loading..." !important;
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  color: #ffffff !important;
  font-size: 14px !important;
}

.message-opportunity {
  margin-top: 8px;
}

/* Audio Player Styles */
.audio-player-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 12px;
  width: 100%;
}

.custom-audio-player {
  width: 100%;
  height: 40px;
  margin-bottom: 8px;
}

.custom-audio-player::-webkit-media-controls-panel {
  background-color: var(--background);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 10px;
}

.custom-audio-player::-webkit-media-controls-play-button {
  background-color: var(--primary);
  border-radius: 50%;
  margin-right: 10px;
}

.custom-audio-player::-webkit-media-controls-current-time-display,
.custom-audio-player::-webkit-media-controls-time-remaining-display {
  color: var(--copy);
  margin: 0 5px;
}

.custom-audio-player::-webkit-media-controls-timeline,
.custom-audio-player::-webkit-media-controls-volume-slider {
  background-color: var(--primary);
  border-radius: 25px;
  margin: 0 10px;
  height: 8px;
}

.custom-audio-player::-webkit-media-controls-volume-slider {
  width: 80px;
}

/* Dark mode audio player */
body.dark-mode .audio-player-container,
body.dark-mode .custom-audio-player::-webkit-media-controls-panel { background-color: var(--dark-background); }
body.dark-mode .custom-audio-player::-webkit-media-controls-current-time-display,
body.dark-mode .custom-audio-player::-webkit-media-controls-time-remaining-display { color: var(--dark-copy); }

/* Transcription Styles */
.transcription-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 40px;
}

.transcription-section .btn {
  background-color: var(--dark-accent);
  color: var(--dark-text);
  border: none;
  padding: 1px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.transcription-section .btn:hover {
  background-color: var(--dark-primary);
}

.transcription-processtext-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.transcription-card {
  background-color: var(--light-foreground);
  border-radius: 8px;
  box-shadow: var(--shadow-sm-light);
  padding: 20px;
}

.transcription-textarea,
.transcription-output {
  width: 98%;
  padding: 10px;
  border: 1px solid var(--light-border);
  border-radius: 4px;
  font-size: 16px;
  resize: vertical;
  background-color: var(--light-background);
  color: var(--copy);
}

.transcription-textarea { min-height: 300px; }
.transcription-output { min-height: 300px; }

body.dark-mode .transcription-container { color: var(--dark-copy); }
body.dark-mode .transcription-card {
  background-color: var(--dark-foreground);
  box-shadow: var(--shadow-sm-dark);
}

body.dark-mode .transcription-textarea,
body.dark-mode .transcription-output {
  background-color: var(--dark-text-area);
  color: var(--dark-copy);
  border-color: var(--dark-border);
}

body.dark-mode .transcription-textarea::placeholder,
body.dark-mode .transcription-output::placeholder { color: var(--dark-copy-lighter); }

/* Custom Fields Manager Styles */
.custom-fields-manager {
  background-color: var(--light-foreground);
  color: var(--light-text);
  padding: 20px;
  border-radius: var(--border-radius-base);
  box-shadow: var(--shadow-sm-light);
}

.dark-mode .custom-fields-manager {
  background-color: var(--dark-foreground);
  color: var(--dark-text);
  box-shadow: var(--shadow-sm-dark);
}

.custom-fields-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.custom-fields-section {
  margin-bottom: 0px;
}

.section-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  background-color: var(--light-foreground-accent);
  border-radius: 4px;
}

.dark-mode .section-header {
  background-color: var(--dark-foreground-accent);
}

.expand-icon {
  margin-right: 10px;
  transition: transform 0.3s ease;
}

.expand-icon.expanded {
  transform: rotate(90deg);
}

.fields {
  padding: 20px;
  background-color: var(--light-background);
  border-radius: 0 0 4px 4px;
}

.dark-mode .fields {
  background-color: var(--dark-background);
}

.field {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.field:last-child {
  margin-bottom: 0;
}

.field .form-group {
  flex-grow: 1;
}

.field label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: var(--light-text);
}

.dark-mode .field label {
  color: var(--dark-text);
}

.field input[type="text"],
.field input[type="number"],
.field input[type="date"],
.field textarea,
.field select {
  width: 98%;
  padding: 10px;
  background-color: var(--light-background);
  color: var(--light-text);
  border: 1px solid var(--light-border);
  border-radius: 4px;
  font-size: 1rem;
}

.dark-mode .field input[type="text"],
.dark-mode .field input[type="number"],
.dark-mode .field input[type="date"],
.dark-mode .field textarea,
.dark-mode .field select {
  background-color: var(--dark-text-area);
  color: var(--dark-text);
  border-color: var(--dark-border);
}

.field textarea {
  resize: vertical;
  width: 100%;
  height: auto;
  min-height: 100px; /* Adjust as needed */
  padding: 10px;
  white-space: pre-wrap; /* Ensures text wraps */
  word-wrap: break-word; /* Break long words */
  overflow-wrap: break-word; /* Break long words */
}


.field input[type="checkbox"],
.field input[type="radio"] {
  margin-right: 10px;
}

.add-field-button {
  margin-top: 10px;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  flex-wrap: wrap;  /* Allow buttons to wrap on smaller screens */
}

.action-buttons button {
  margin: 5px;  /* Add some margin around buttons */
}

/* Field Selection Modal Styles */
.field-selection-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.field-selection-modal .modal-content {
  background-color: var(--light-foreground);
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
}

.dark-mode .field-selection-modal .modal-content {
  background-color: var(--dark-foreground);
}

.field-selection-modal h3 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: var(--light-text);
}

.dark-mode .field-selection-modal h3 {
  color: var(--dark-text);
}

.field-selection-modal .field-list {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.field-selection-modal .field-option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.field-selection-modal .field-option label {
  margin-left: 10px;
  color: var(--light-text);
}

.dark-mode .field-selection-modal .field-option label {
  color: var(--dark-text);
}

.field-selection-modal .modal-actions {
  display: flex;
  justify-content: flex-end;
}

/* Customer Profile Styles */
.customer-profile {
  background-color: var(--light-foreground);
  border-radius: 8px;
  box-shadow: var(--shadow-sm-light);
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.profile-fields {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.profile-field {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.profile-field label {
  font-weight: bold;
  color: var(--light-text-light);
}

.profile-field input {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--light-border);
  border-radius: 4px;
  font-size: 14px;
}

.profile-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.profile-actions button {
  margin-left: 10px;
}

/* Dark mode adjustments */
body.dark-mode .customer-profile {
  background-color: var(--dark-foreground);
  box-shadow: var(--shadow-sm-dark);
}

body.dark-mode .profile-field label {
  color: var(--dark-text-light);
}

body.dark-mode .profile-field input {
  background-color: var(--dark-text-area);
  color: var(--dark-copy);
  border-color: var(--dark-border);
}

body.dark-mode .btn-secondary {
  background-color: var(--dark-accent);
}

body.dark-mode .btn-primary {
  background-color: var(--dark-primary);
}

/* Profile and Custom Fields Container */


.profile-and-custom-fields-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* This creates two equal-width columns */
  gap: 20px; /* This adds space between the grid items */
}

.profile-section, .custom-fields-section {
  min-height: 800px; /* Adjust this value as needed */
  display: flex;
  flex-direction: column;
}

.profile-section h2, .custom-fields-section h2 {
  margin-bottom: 15px;
}

/* Ensure content fills the available space */
.profile-section > div, .custom-fields-section > div {
  flex-grow: 1;
  overflow-y: auto; /* Adds scroll if content is too long */
}

.field-selection-popout {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 1rem;
  z-index: 1000;
  max-width: 400px;
  width: 100%;
}

.field-list {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 1rem;
}

.field-option {
  display: block;
  margin-bottom: 0.5rem;
}

.popout-actions {
  display: flex;
  justify-content: flex-end;
}

.popout-actions button {
  margin-left: 0.5rem;
}

/* QuoteTool Styles */
.quote-tool {
  background-color: var(--light-foreground);
  border-radius: var(--border-radius-base);
  padding: 20px;
  box-shadow: var(--shadow-sm-light);
  color: var(--light-text);
}

body.dark-mode .quote-tool {
  background-color: var(--dark-foreground);
  box-shadow: var(--shadow-sm-dark);
  color: var(--dark-text);
}

.quote-tool h2, .quote-tool h3 {
  color: var(--light-text);
  margin-bottom: 1rem;
}

body.dark-mode .quote-tool h2, body.dark-mode .quote-tool h3 {
  color: var(--dark-text);
}

.room-input textarea {
  width: 98%;
  padding: 10px;
  border: 1px solid var(--light-border);
  border-radius: var(--border-radius-base);
  background-color: var(--light-background);
  color: var(--light-text);
  margin-bottom: 10px;
  min-height: 150px;
}

body.dark-mode .room-input textarea {
  border-color: var(--dark-border);
  background-color: var(--dark-text-area);
  color: var(--dark-text);
}

.pricing-table table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.pricing-table th, .pricing-table td {
  padding: 10px;
  border: 1px solid var(--light-border);
  text-align: left;
}

body.dark-mode .pricing-table th, body.dark-mode .pricing-table td {
  border-color: var(--light-text-light);
}

.pricing-table input[type="number"] {
  width: 60px;
  padding: 5px;
  border: 1px solid var(--light-border);
  border-radius: var(--border-radius-base);
  background-color: var(--light-background);
  color: var(--light-text);
}

body.dark-mode .pricing-table input[type="number"] {
  border-color: var(--dark-accent);
  background-color: var(--dark-text-area);
  color: var(--dark-text);
}

.multiplier select {
  padding: 5px;
  border: 1px solid var(--light-border);
  border-radius: var(--border-radius-base);
  background-color: var(--light-background);
  color: var(--light-text);
}

body.dark-mode .multiplier select {
  border-color: var(--dark-border);
  background-color: var(--dark-text-area);
  color: var(--dark-text);
}

.quote-actions {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 20px;
}

.quote-summary {
  margin-top: 20px;
  padding: 20px;
  background-color: var(--light-background);
  border-radius: var(--border-radius-base);
  border: 1px solid var(--light-border);
}

body.dark-mode .quote-summary {
  background-color: var(--dark-background);
  border-color: var(--dark-border);
}

.quote-summary ul {
  padding-left: 20px;
}

.quote-summary li {
  margin-bottom: 5px;
}

/* AuthHeader Styles */
.auth-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--light-foreground);
  border-bottom: 1px solid var(--light-border);
}

.dark-mode .auth-header {
  background-color: var(--dark-foreground);
  border-bottom-color: var(--dark-border);
}

.auth-header .auth-logo {
  width: 120px;
  height: auto;
}

/* Auth Card Styles */
.auth-card {
  background-color: var(--light-foreground);
  border-radius: 8px;
  box-shadow: var(--shadow-md-light);
  overflow: hidden;
  width: 100%;
  max-width: 400px;
}

.dark-mode .auth-card {
  background-color: var(--dark-foreground);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

body.dark-mode .auth-card {
  background-color: var(--dark-foreground);
  box-shadow: var(--shadow-md-dark);
}

.auth-card form {
  padding: 2rem;
}

/* Login specific styles */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: var(--light-background);
}

.dark-mode .login-container .auth-card {
  background-color: var(--dark-foreground);
  box-shadow: var(--shadow-md-dark);
}

.login-container form {
  padding: 2rem;
}

.login-container h2 {
  color: var(--light-text);
  margin-bottom: 1.5rem;
}

.dark-mode .login-container h2 {
  color: var(--dark-text);
}

.login-container .input-group {
  margin-bottom: 1.5rem;
}

.login-container .input-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--light-text);
}

.dark-mode .login-container .input-group label {
  color: var(--dark-text);
}

.login-container .input-group input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--light-border);
  border-radius: var(--border-radius-base);
  background-color: var(--light-background);
  color: var(--light-text);
}

.dark-mode .login-container .input-group input {
  border-color: var(--dark-border);
  background-color: var(--dark-text-area);
  color: var(--dark-text);
}

.login-container .checkbox-group {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.login-container .checkbox-group input {
  margin-right: 0.5rem;
}

.login-container .error-message {
  color: var(--error);
  margin-bottom: 1rem;
}

.login-container .btn-primary {
  width: 100%;
  padding: 0.75rem;
  background-color: var(--light-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-base);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dark-mode .login-container .btn-primary {
  background-color: var(--dark-primary);
}

.login-container .btn-primary:hover {
  background-color: var(--light-accent);
}

.dark-mode .login-container .btn-primary:hover {
  background-color: var(--dark-accent);
}

.login-container .links {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.login-container .links a {
  color: var(--light-primary);
  text-decoration: none;
}

.dark-mode .login-container .links a {
  color: var(--dark-primary);
}

.login-container .links a:hover {
  text-decoration: underline;
}

