.container {
  padding: 2rem;
  max-width: 1400px;
  margin: 2rem auto 0;
}

.pageTitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--light-text);
  margin-bottom: 2rem;
}

[data-mui-color-scheme="dark"] .pageTitle {
  color: var(--dark-text);
}

.actionsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  gap: 1rem;
}

.searchContainer {
  position: relative;
  flex: 1;
  max-width: 400px;
}

.searchIcon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--light-text-light);
}

[data-mui-color-scheme="dark"] .searchIcon {
  color: var(--dark-text-light);
}

.searchInput {
  width: 100%;
  padding: 0.75rem 0.75rem 0.75rem 2.5rem;
  border-radius: 4px;
  background-color: var(--light-foreground);
  border: 1px solid var(--light-border);
  color: var(--light-text);
  font-size: 14px;
  transition: all 0.2s ease;
}

[data-mui-color-scheme="dark"] .searchInput {
  background-color: var(--dark-text-area);
  border-color: var(--dark-border);
  color: var(--dark-text);
}

.searchInput:hover {
  background-color: var(--light-foreground);
  border-color: var(--light-border);
}

[data-mui-color-scheme="dark"] .searchInput:hover {
  background-color: var(--dark-text-hover);
  border-color: var(--dark-border);
}

.searchInput:focus {
  outline: none;
  border-color: var(--light-primary);
  box-shadow: 0 0 0 2px var(--light-focus-ring);
}

[data-mui-color-scheme="dark"] .searchInput:focus {
  border-color: var(--dark-primary);
  box-shadow: 0 0 0 2px var(--dark-focus-ring);
}

.buttonGroup {
  display: flex;
  gap: 1rem;
}

.createButton {
  background: var(--light-secondary) !important;
  border: 1px solid var(--light-secondary) !important;
  color: white !important;
  border-radius: 4px !important;
  text-transform: none !important;
  font-weight: 500 !important;
  padding: 8px 16px !important;
  height: 36px !important;
  font-size: 14px !important;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
}

[data-mui-color-scheme="dark"] .createButton {
  background: var(--dark-secondary) !important;
  border-color: var(--dark-secondary) !important;
}

.createButton:hover {
  background: var(--light-secondary-accent) !important;
  box-shadow: var(--shadow-md-light) !important;
}

[data-mui-color-scheme="dark"] .createButton:hover {
  background: var(--dark-secondary-accent) !important;
  box-shadow: var(--shadow-md-dark) !important;
}

.tableContainer {
  background: var(--light-foreground);
  /* border: 1px solid var(--light-border); */
  /* border-radius: 8px; */
  overflow: hidden;
}

[data-mui-color-scheme="dark"] .tableContainer {
  background: var(--dark-foreground);
  /* border-color: var(--dark-border); */
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.accordionRow {
  border-bottom: 1px solid var(--light-border);
  border-radius: 8px;
  background: var(--light-foreground-accent);
  transition: background-color 0.2s ease;
  margin-bottom: 1rem;
}

[data-mui-color-scheme="dark"] .accordionRow {
  border-color: var(--dark-border);
  background: var(--dark-foreground-accent);
}

.accordionRow:last-child {
  border-bottom: none;
}

.accordionHeader {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.accordionHeader:hover {
  background: var(--light-background);
}

[data-mui-color-scheme="dark"] .accordionHeader:hover {
  background: var(--dark-text-area);
}

.roomInfo {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: var(--light-text);
  font-weight: 500;
}

[data-mui-color-scheme="dark"] .roomInfo {
  color: var(--dark-text);
}

.chevronIcon {
  color: var(--light-text-light);
  transition: transform 0.2s ease;
}

[data-mui-color-scheme="dark"] .chevronIcon {
  color: var(--dark-text-light);
}

.chevronIcon.open {
  transform: rotate(90deg);
}

.accordionContent {
  background: var(--light-background);
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}

[data-mui-color-scheme="dark"] .accordionContent {
  background: var(--dark-text-area);
}

.accordionContent.open {
  padding: 1rem;
  max-height: 500px;
}

.tasksList {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 0.5rem;
}

.taskItem {
  font-size: 0.875rem;
  color: var(--light-text);
  padding: 0.75rem;
  background: var(--light-foreground);
  border-radius: 4px;
  border: 1px solid var(--light-border);
  transition: all 0.2s ease;
}

[data-mui-color-scheme="dark"] .taskItem {
  color: var(--dark-text);
  background: #4f81b6 !important;
  border-color: var(--dark-border) !important;
}

.taskItem:hover {
  border-color: var(--light-border-hover);
  background: var(--light-foreground-accent);
}

[data-mui-color-scheme="dark"] .taskItem:hover {
  border-color: var(--dark-accent) !important;
  background: var(--dark-foreground-accent);
}

.roomActions {
  display: flex;
  gap: 0.5rem;
}

.iconButton {
  padding: 8px !important;
  background: none;
  border: none;
  color: var(--light-text-light);
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

[data-mui-color-scheme="dark"] .iconButton {
  color: var(--dark-text-light);
}

.iconButton:hover {
  color: var(--light-primary);
  background: var(--light-background);
}

[data-mui-color-scheme="dark"] .iconButton:hover {
  color: var(--dark-primary);
  background: var(--dark-text-area);
}

/* Dark mode styles */
[data-mui-color-scheme="dark"] .tableContainer {
  background: var(--dark-foreground);
  border-color: var(--dark-border);
}

[data-mui-color-scheme="dark"] .tableHeader {
  background: var(--dark-foreground-accent);
  border-color: var(--dark-border);
  color: var(--dark-text);
}

[data-mui-color-scheme="dark"] .accordionRow {
  border-color: var(--dark-border);
}

[data-mui-color-scheme="dark"] .accordionHeader:hover {
  background-color: var(--dark-text-area);
}

[data-mui-color-scheme="dark"] .accordionContent {
  background: var(--dark-text-area);
}

[data-mui-color-scheme="dark"] .taskItem {
  background: var(--dark-foreground);
  color: var(--dark-text);
}

/* ... keep other dark mode styles ... */ 