.formContainer {
  max-width: 100%;
  margin: 1.5rem auto;
  padding: 1.5rem;
  background: var(--form-highlight-light);
  color: var(--light-text);
  border-radius: 12px;
  box-shadow: var(--shadow-lg-light);
}

.nav {
  display: flex;
  justify-content: center;
  margin: 5px 0px;
  border-radius: 8px;
}


.navContent {
  display: flex;
  justify-content: center;
}



.tabs {
  display: flex;
  gap: 0.25rem;
  padding: 0.25rem;
  margin: -1.5rem -1.5rem 1.5rem -1.5rem;
  background-color: var(--light-background);
  border-bottom: 1px solid var(--light-border);
  border-radius: 8px 8px 0 0;
}

.tabButton {
  position: relative;
  padding: 0.75rem 1.25rem;
  border: none;
  background: none;
  color: var(--light-text-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
  font-size: 0.875rem;
  text-decoration: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.tabButton:hover {
  background-color: var(--light-focus-ring);
  color: var(--light-text);
}

.activeTab {
  color: var(--light-primary);
  background-color: var(--light-focus-ring);
  font-weight: 600;
}

.activeTab::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: var(--light-primary);
  border-radius: 2px 2px 0 0;
}

.formContent {
  background-color: var(--light-foreground);
  border-radius: 8px;
}

.formHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formTitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--light-text);
  margin-bottom: 1.5rem;
}

.formSection {
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  background-color: var(--light-foreground);
  border-radius: 10px;
  border: 1px solid var(--light-border);
  transition: all 0.3s ease;
}

.formSection:hover {
  transform: translateY(-2px);
  background-color: var(--form-section-hover-light);
  box-shadow: var(--shadow-md-light);
}

.formSection.highlight {
  background: var(--light-foreground);
  border: none;
}

.formSection.highlight .sectionTitle,
.formSection.highlight .label {
  color: var(--light-text);
}

.sectionTitle {
  color: var(--form-label-light);
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.label {
  color: var(--form-label-light);
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.required {
  color: var(--form-required-light);
  margin-left: 0.25rem;
}

.inputGroup {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.input, .textarea, .select {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid var(--light-border);
  border-radius: 6px;
  background-color: white;
  color: var(--light-text);
  transition: all 0.2s ease;
}

.input::placeholder {
  color: var(--form-placeholder-light);
}

.input:focus, .textarea:focus, .select:focus {
  border-color: var(--light-primary);
  box-shadow: 0 0 0 3px var(--form-input-focus-ring);
}

.button {
  background: var(--light-primary);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
}

.button:hover {
  transform: translateY(-1px);
  background: linear-gradient(135deg, var(--form-button-hover-light), var(--light-primary));
  box-shadow: var(--shadow-md-light);
}

.submitButton {
  background: linear-gradient(135deg, var(--light-secondary), var(--dark-secondary-accent));
  font-size: 1.1rem;
  padding: 1rem 2rem;
}

.submitButton:hover {
  background: linear-gradient(135deg, var(--dark-secondary-accent), var(--light-secondary));
}

/* Autocomplete styles */
.autocompleteContainer {
  position: relative;
  flex: 1;
}

.dropdown {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  background-color: var(--light-background);
  border: 1px solid var(--light-border);
  border-radius: 6px;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.dropdownItem {
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropdownItem:hover {
  background-color: var(--light-focus-ring);
}

/* Date and Time Selection Styles */
.dateTimeContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
}

/* Room management styles */
.roomsList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.roomCard {
  background: var(--light-foreground-accent);
  border: 1px solid var(--light-border);
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.2s ease;
}

.roomCard:hover {
  box-shadow: var(--shadow-sm-light);
}

.roomHeader {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--light-border);
}

.roomName {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  color: var(--light-text);
}

.roomActions {
  display: flex;
  gap: 0.5rem;
}

.tasksList {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.taskItem {
  font-size: 0.875rem;
  color: var(--light-text);
  padding: 0.5rem;
  background: var(--light-background);
  border-radius: 4px;
}

/* Message styles */
.successMessage {
  background: linear-gradient(135deg, var(--success), #6ee08d);
  color: white;
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
}

.errorMessage {
  background: linear-gradient(135deg, var(--error), #ff7070);
  color: white;
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
}

/* Dark mode styles */
[data-mui-color-scheme='dark'] .formContainer {
  background: var(--form-highlight-dark);
  box-shadow: var(--shadow-lg-dark);
}

[data-mui-color-scheme='dark'] .formContent {
  background-color: var(--dark-foreground);
  color: var(--dark-text);
}

[data-mui-color-scheme='dark'] .formSection {
  background-color: var(--dark-foreground);
  border-color: var(--dark-border);
}

[data-mui-color-scheme='dark'] .formSection:hover {
  background-color: var(--form-section-hover-dark);
}

[data-mui-color-scheme='dark'] .formSection.highlight {
  background: var(--dark-foreground) !important;
  border: var(--dark-border);
}

[data-mui-color-scheme='dark'] .sectionTitle {
  color: var(--dark-text) !important;
}

[data-mui-color-scheme='dark'] .label {
  color: var(--dark-text) !important;
}

[data-mui-color-scheme='dark'] .required {
  color: var(--form-required-dark);
}

[data-mui-color-scheme='dark'] .input,
[data-mui-color-scheme='dark'] .textarea,
[data-mui-color-scheme='dark'] .select,
[data-mui-color-scheme='dark'] .dropdown {
  background-color: var(--dark-dropdowns);
  border-color: var(--dark-border);
  color: var(--dark-text);
}

[data-mui-color-scheme='dark'] .input::placeholder {
  color: var(--form-placeholder-dark);
}

[data-mui-color-scheme='dark'] .label,
[data-mui-color-scheme='dark'] .formTitle,
[data-mui-color-scheme='dark'] .roomName {
  color: var(--dark-text);
}

[data-mui-color-scheme='dark'] .tabs {
  border-bottom-color: var(--dark-border);
}

[data-mui-color-scheme='dark'] .input:focus,
[data-mui-color-scheme='dark'] .textarea:focus,
[data-mui-color-scheme='dark'] .select:focus {
  border-color: var(--dark-primary);
  box-shadow: 0 0 0 2px rgba(var(--dark-primary-rgb), 0.2);
}

[data-mui-color-scheme='dark'] .roomCard,
[data-mui-color-scheme='dark'] .roomHeader {
  background-color: var(--dark-foreground-accent);
  border-color: var(--dark-border);
}

[data-mui-color-scheme='dark'] .tasksList {
  background-color: var(--dark-foreground);
}

[data-mui-color-scheme='dark'] .button {
  background: linear-gradient(135deg, var(--dark-primary), var(--form-button-hover-dark));
}

[data-mui-color-scheme='dark'] .button:hover {
  background: linear-gradient(135deg, var(--form-button-hover-dark), var(--dark-primary));
}

[data-mui-color-scheme='dark'] .dropdownItem:hover {
  background-color: var(--dark-focus-ring);
}

[data-mui-color-scheme='dark'] .tabs {
  background-color: var(--dark-background);
  border-bottom-color: var(--dark-border);
}

[data-mui-color-scheme='dark'] .tabButton {
  color: var(--dark-text-secondary);
}

[data-mui-color-scheme='dark'] .tabButton:hover {
  background-color: var(--dark-focus-ring);
  color: var(--dark-text);
}

[data-mui-color-scheme='dark'] .activeTab {
  color: var(--dark-primary);
  background-color: var(--dark-focus-ring);
}

[data-mui-color-scheme='dark'] .activeTab::after {
  background-color: var(--dark-primary);
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
}

.modalTitle {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
}

[data-mui-color-scheme='dark'] .modalOverlay {
  background-color: rgba(0, 0, 0, 0.5);
}

[data-mui-color-scheme='dark'] .modalContent {
  background-color: var(--dark-foreground);
  color: var(--dark-text);
}

[data-mui-color-scheme='dark'] .modalTitle {
  color: var(--dark-text);
}

/* Container and Layout */
.container {
  min-height: 100vh;
  background-color: var(--light-background);
}

[data-mui-color-scheme='dark'] .container {
  background-color: var(--dark-background);
}

/* Navigation */
.nav {
  background-color: var(--light-foreground);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 0.75rem 1rem;
}

[data-mui-color-scheme='dark'] .nav {
  background-color: var(--dark-foreground);
}

.navContent {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  gap: 1rem;
}

.tabButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease;
  color: var(--light-text);
}

.tabButton:hover {
  background-color: var(--light-foreground-accent);
}

.tabButton.active {
  background-color: var(--light-primary);
  color: white;
}

[data-mui-color-scheme='dark'] .tabButton {
  color: var(--dark-text);
}

[data-mui-color-scheme='dark'] .tabButton:hover {
  background-color: var(--dark-foreground-accent);
}

[data-mui-color-scheme='dark'] .tabButton.active {
  background-color: var(--dark-primary);
}

/* Form Section */
.formSection {
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
  background-color: var(--light-foreground);
}

.formSection.highlight {
  background-color: var(--light-foreground-accent);
  border-left: 4px solid var(--light-primary);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

[data-mui-color-scheme='dark'] .formSection {
  background-color: var(--dark-foreground);
}

[data-mui-color-scheme='dark'] .formSection.highlight {
  background-color: var(--dark-foreground-accent);
  border-left-color: var(--dark-primary);
}

.sectionHeader {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.sectionTitle {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--light-text);
}

[data-mui-color-scheme='dark'] .sectionTitle {
  color: var(--dark-text);
}

/* Grid Layouts */
.grid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

@media (max-width: 768px) {
  .grid2, .grid3 {
    grid-template-columns: 1fr;
  }
}

/* Content Area */
.contentArea {
  max-width: 1280px;
  margin: 0 auto;
  padding: 1.5rem;
}

.pageTitle {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--light-text);
  margin-bottom: 0.5rem;
}

[data-mui-color-scheme='dark'] .pageTitle {
  color: var(--dark-text);
}

.pageDescription {
  color: var(--light-text-secondary);
  margin-bottom: 2rem;
}

[data-mui-color-scheme='dark'] .pageDescription {
  color: var(--dark-text-secondary);
}

/* Action Buttons */
.actionButton {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem;
  font-weight: 500;
  transition: all 0.2s ease;
  background-color: var(--light-primary);
  color: white;
  border: none;
  cursor: pointer;
}

.actionButton:hover {
  background-color: var(--light-accent);
}

[data-mui-color-scheme='dark'] .actionButton {
  background-color: var(--dark-primary);
}

[data-mui-color-scheme='dark'] .actionButton:hover {
  background-color: var(--dark-accent);
}

/* Form Actions */
.formActions {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}

/* Button Styles */
.button,
.iconButton,
.actionButton {
  border-radius: 4px !important;
  text-transform: none !important;
  font-weight: 500 !important;
  padding: 8px 16px !important;
  height: 36px !important;
  min-width: 40px !important;
  font-size: 14px !important;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.button {
  background: var(--light-secondary) !important;
  border: 1px solid var(--light-secondary) !important;
  color: white !important;
}

.button:hover {
  background: var(--light-secondary-accent) !important;
  box-shadow: var(--shadow-md-light) !important;
}

.iconButton {
  padding: 8px !important;
  background: none;
  border: none;
  color: var(--light-text-light);
}

.iconButton:hover {
  color: var(--light-primary);
}

.actionButton {
  background: linear-gradient(135deg, var(--light-primary), var(--light-accent)) !important;
  color: white !important;
  border: none !important;
  box-shadow: var(--shadow-sm-light) !important;
}

.actionButton:hover {
  background: linear-gradient(135deg, var(--light-accent), var(--light-primary)) !important;
  box-shadow: var(--shadow-md-light) !important;
  transform: translateY(-1px);
}

/* Dark mode styles */
[data-mui-color-scheme="dark"] .formTitle {
  color: var(--dark-text);
}

[data-mui-color-scheme="dark"] .roomCard {
  background: var(--dark-foreground-accent);
  border-color: var(--dark-border);
}

[data-mui-color-scheme="dark"] .roomHeader {
  border-color: var(--dark-border);
}

[data-mui-color-scheme="dark"] .roomName {
  color: var(--dark-text);
}

[data-mui-color-scheme="dark"] .taskItem {
  background: var(--dark-text-area);
  color: var(--dark-text);
}

[data-mui-color-scheme="dark"] .button {
  background: var(--dark-secondary) !important;
  border-color: var(--dark-secondary) !important;
}

[data-mui-color-scheme="dark"] .button:hover {
  background: var(--dark-secondary-accent) !important;
  box-shadow: var(--shadow-md-dark) !important;
}

[data-mui-color-scheme="dark"] .iconButton {
  color: var(--dark-text-light);
}

[data-mui-color-scheme="dark"] .iconButton:hover {
  color: var(--dark-primary);
}

[data-mui-color-scheme="dark"] .actionButton {
  background: linear-gradient(135deg, var(--dark-primary), var(--dark-accent)) !important;
  box-shadow: var(--shadow-sm-dark) !important;
}

[data-mui-color-scheme="dark"] .actionButton:hover {
  background: linear-gradient(135deg, var(--dark-accent), var(--dark-primary)) !important;
  box-shadow: var(--shadow-md-dark) !important;
}
