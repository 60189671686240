.card {
  width: 100%;
  padding: 12px;
  border-radius: 12px;
  background-color: transparent;
  cursor: pointer;

  p {
    margin: 0;
    font-size: 14px;
  }
}

.card--status {
  font-size: 14px;
  border-radius: 4px;
  margin-top: 8px;
  padding: 4px 8px;
  width: min-content;
}
