/* CleanerDashboard.module.css */
.container {
  min-height: 100vh;
  background-color: #f9fafb;
  transition: background-color 0.2s ease;
}

[data-mui-color-scheme="dark"] .container {
  background-color: #111827;
}

.content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.card {
  background-color: var(--light-background);
  border-radius: 0.5rem;
  box-shadow: var(--shadow-lg-light);
  overflow: hidden;
  transition: all 0.2s ease;
}

[data-mui-color-scheme="dark"] .card {
  background-color: var(--dark-background);
  box-shadow: var(--shadow-lg-dark);
}

.timeManagementCard {
  margin-bottom: 4rem;
  background-color: var(--light-background);
  border-radius: 0.5rem;
  box-shadow: var(--shadow-lg-light);
  overflow: hidden;
  transition: all 0.2s ease;
}

[data-mui-color-scheme="dark"] .timeManagementCard {
  background-color: var(--dark-background);
  box-shadow: var(--shadow-lg-dark);
}

.cardHeader {
  padding: 1rem;
  border-bottom: 1px solid var(--light-border);
}

[data-mui-color-scheme="dark"] .cardHeader {
  border-bottom-color: var(--dark-border);
}

.cardTitle {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--light-text);
}

[data-mui-color-scheme="dark"] .cardTitle {
  color: var(--dark-text);
}

.cardContent {
  padding: 1rem;
}

.metricsGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
}

@media (min-width: 768px) {
  .metricsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.metricItem {
  display: flex;
  align-items: center;
  flex-direction: center;
  padding: 1rem;
  background-color: var(--light-foreground);
  border-radius: 0.5rem;
  transition: all 0.2s ease;
  gap: 0.75rem;
}

[data-mui-color-scheme="dark"] .metricItem {
  background-color: var(--dark-foreground);
}

.metricIconContainer {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
}

.metricIconContainer {
  padding: 0.5rem;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.metricContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.metricTitle {
  font-size: 0.875rem;
  color: var(--light-text);
  margin-bottom: 0.25rem;
}

[data-mui-color-scheme="dark"] .metricTitle {
  color: var(--dark-text);
}

.metricValue {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--light-text);
  align-items: center;
}

[data-mui-color-scheme="dark"] .metricValue {
  color: var(--dark-text);
}

.timeManagement {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}

.timeLabel {
  color: var(--light-text);
}

[data-mui-color-scheme="dark"] .timeLabel {
  color: var(--dark-text);
}

.timeValue {
  font-weight: 600;
  color: var(--light-text);
}

[data-mui-color-scheme="dark"] .timeValue {
  color: var(--dark-text);
}

/* Color variants for metric icons */
.iconBlue {
  background-color: rgba(37, 99, 235, 0.15);
  color: #60A5FA;
}

.iconGreen {
  background-color: rgba(5, 150, 105, 0.15);
  color: #34D399;
}

.iconYellow {
  background-color: rgba(217, 119, 6, 0.15);
  color: #FCD34D;
}

.iconRed {
  background-color: rgba(220, 38, 38, 0.15);
  color: #F87171;
}

.iconPurple {
  background-color: rgba(124, 58, 237, 0.15);
  color: #A78BFA;
}

.iconOrange {
  background-color: rgba(234, 88, 12, 0.15);
  color: #FB923C;
}

[data-mui-color-scheme="dark"] .iconBlue,
[data-mui-color-scheme="dark"] .iconGreen,
[data-mui-color-scheme="dark"] .iconYellow,
[data-mui-color-scheme="dark"] .iconRed,
[data-mui-color-scheme="dark"] .iconPurple,
[data-mui-color-scheme="dark"] .iconOrange {
  background-color: rgba(17, 24, 39, 0.4);
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.errorContainer {
  padding: 1rem;
}

.errorText {
  color: #dc2626;
}