/* Base Modal Styling */
:global(.MuiDialog-paper) {
  background-color: var(--light-foreground);
  border-radius: 12px;
  box-shadow: var(--shadow-lg-light);
  overflow: hidden;
  margin: 32px;
  max-height: calc(100% - 64px);
}

/* Title Section with Gradient */
.modalTitle {
  background: linear-gradient(135deg, var(--light-primary), var(--light-accent));
  color: white;
  padding: 1.5rem 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}

/* Content Layout */
.modalContent {
  position: relative;
  padding: 1.5rem;
  display: grid;
  gap: 1.5rem;
}

/* Form Section */
.formSection {
  background: var(--light-foreground-accent);
  border: 1px solid var(--light-border);
  border-radius: 8px;
  padding: 1.5rem;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

/* Cleaner Items */
.cleanerItem {
  background: var(--light-background);
  border: 1px solid var(--light-border);
  border-radius: 8px;
  padding: 0.75rem;
  transition: all 0.2s ease;
}

.cleanerItem:hover {
  transform: translateY(-1px);
  box-shadow: var(--shadow-sm-light);
  background: var(--light-foreground);
}

.cleanerItem.selected {
  background: var(--form-highlight-light);
  border-color: var(--light-primary);
}

.cleanerName {
  color: var(--light-text);
  font-size: 1rem;
}

/* Checkbox Styling */
.checkbox {
  color: var(--light-primary) !important;
}

/* Modal Actions */
.modalActions {
  padding: 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  background: var(--light-foreground-accent);
  border-top: 1px solid var(--light-border);
}

/* Button Styles */
.cancelButton {
  background-color: var(--error) !important;
  color: white !important;
  border: 1px solid var(--error) !important;
  transition: all 0.2s ease;
}

.cancelButton:hover {
  background-color: #f06c6c !important; /* Increase visibility */
  border-color: var(--error) !important;
  box-shadow: var(--shadow-md-light) !important;
}

[data-mui-color-scheme="dark"] .cancelButton {
  background-color: var(--error) !important;
  color: white !important;
  border: 1px solid var(--error) !important;
  transition: all 0.2s ease;
}

[data-mui-color-scheme="dark"] .cancelButton:hover {
  background-color: #f06c6c !important; /* Increase visibility */
  border-color: var(--error) !important;
  box-shadow: var(--shadow-md-dark) !important;
}

.submitButton {
  background: linear-gradient(135deg, var(--light-primary), var(--light-accent)) !important;
  color: white !important;
  border: none !important;
  transition: all 0.2s ease !important;
  box-shadow: var(--shadow-sm-light) !important;
}

.submitButton:hover {
  background: linear-gradient(135deg, var(--light-accent), var(--light-primary)) !important;
  box-shadow: var(--shadow-md-light) !important;
  transform: translateY(-1px);
}

/* Dark Mode Styles */
[data-mui-color-scheme="dark"] :global(.MuiDialog-paper) {
  background-color: var(--dark-foreground);
  box-shadow: var(--shadow-lg-dark);
}

[data-mui-color-scheme="dark"] .modalTitle {
  background: linear-gradient(135deg, var(--dark-primary), var(--dark-accent));
}

[data-mui-color-scheme="dark"] .formSection {
  background: var(--dark-foreground-accent);
  border-color: var(--dark-border);
}

[data-mui-color-scheme="dark"] .cleanerItem {
  background: var(--dark-text-area);
  border-color: var(--dark-border);
}

[data-mui-color-scheme="dark"] .cleanerItem:hover {
  background: var(--dark-text-hover);
  box-shadow: var(--shadow-sm-dark);
}

[data-mui-color-scheme="dark"] .cleanerItem.selected {
  background: var(--form-highlight-dark);
  border-color: var(--dark-primary);
}

[data-mui-color-scheme="dark"] .cleanerName {
  color: var(--dark-text);
}

[data-mui-color-scheme="dark"] .modalActions {
  background: var(--dark-foreground-accent);
  border-color: var(--dark-border);
}

[data-mui-color-scheme="dark"] .submitButton {
  background: linear-gradient(135deg, var(--dark-primary), var(--dark-accent)) !important;
  box-shadow: var(--shadow-sm-dark) !important;
}

[data-mui-color-scheme="dark"] .submitButton:hover {
  background: linear-gradient(135deg, var(--dark-accent), var(--dark-primary)) !important;
  box-shadow: var(--shadow-md-dark) !important;
}