/* src/styles/ExpenseSubmission.module.css */
.container {
  min-height: 100vh;
  background-color: var(--light-background);
  transition: background-color var(--transition-duration) var(--transition-ease);
  padding-bottom: 10px;
}

.content {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 60px;
  padding-bottom: 10px;
}
.card {
  border-radius: var(--border-radius-base) !important;
  background-color: var(--light-foreground) !important;
  box-shadow: var(--shadow-sm-light) !important;
  border: 1px solid var(--light-border) !important;
  transition: all var(--transition-duration) var(--transition-ease) !important;
}

.cardContent {
  padding: 24px !important;
}

.header {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--light-border);
  margin-bottom: 24px;
  color: var(--light-text);
}

.headerIcon {
  width: 24px;
  height: 24px;
  color: var(--light-primary);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.formGroup :global(.MuiTypography-subtitle2) {
  color: var(--light-text-secondary);
}

.dateInput {
  width: 100%;
  padding: 12px;
  border: 1px solid var(--light-border);
  border-radius: var(--border-radius-base);
  font-size: 16px;
  background-color: var(--light-foreground);
  color: var(--light-text);
  font-family: var(--font-family-base);
}

.dateInput:focus {
  outline: none;
  border-color: var(--light-primary);
  box-shadow: 0 0 0 2px var(--light-focus-ring);
}

.select {
  height: 48px;
}

.select :global(.MuiOutlinedInput-notchedOutline) {
  border-color: var(--light-border) !important;
}

.select:hover :global(.MuiOutlinedInput-notchedOutline) {
  border-color: var(--light-primary) !important;
}

.select :global(.MuiOutlinedInput-input) {
  padding: 12px 16px;
  color: var(--light-text);
}

.input :global(.MuiOutlinedInput-root),
.textArea :global(.MuiOutlinedInput-root) {
  background-color: var(--light-foreground);
}

.input :global(.MuiOutlinedInput-notchedOutline),
.textArea :global(.MuiOutlinedInput-notchedOutline) {
  border-color: var(--light-border) !important;
}

.input:hover :global(.MuiOutlinedInput-notchedOutline),
.textArea:hover :global(.MuiOutlinedInput-notchedOutline) {
  border-color: var(--light-primary) !important;
}

.fileUpload {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  border: 2px dashed var(--light-border);
  border-radius: var(--border-radius-base);
  background-color: var(--light-background);
  transition: all var(--transition-duration) var(--transition-ease);
}

.uploadIcon {
  width: 24px;
  height: 24px;
  color: var(--light-text-light);
}

.fileInput {
  flex: 1;
  font-size: 14px;
  color: var(--light-text);
  font-family: var(--font-family-base);
}

.fileSelected {
  color: var(--success) !important;
  margin-top: 8px;
}

.error {
  padding: 16px;
  background-color: var(--error);
  border-radius: var(--border-radius-base);
  color: var(--error-content);
}

.submitButton {
  height: 48px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  background-color: var(--dark-primary) !important;
  border-radius: var(--border-radius-base) !important;
  font-family: var(--font-family-base) !important;
  transition: background-color var(--transition-duration) var(--transition-ease) !important;
  margin-bottom: 16px !important;
}

.submitButtonWrapper {
  margin-top: auto;
  padding: 0 24px 16px;
  position: sticky;
  bottom: 60px;
  background-color: var(--dark-background);
}

.submitButton:hover {
  background-color: var(--light-accent) !important;
}

.submitButton:disabled {
  background-color: var(--light-foreground-accent) !important;
  color: var(--light-text-light) !important;
}

.loadingState {
  display: flex;
  align-items: center;
  gap: 8px;
}

.required {
  color: var(--error);
  margin-left: 4px;
}

.fileSelected {
  color: var(--success) !important;
  margin-top: 8px;
}

.submitButton:disabled {
  background-color: var(--dark-foreground-accent) !important;
  color: var(--dark-text-light) !important;
}

/* Dark Mode Styles */
[data-mui-color-scheme="dark"] .container {
  background-color: var(--dark-background);
}

[data-mui-color-scheme="dark"] .card {
  background-color: var(--dark-foreground) !important;
  border-color: var(--dark-border) !important;
  box-shadow: var(--shadow-sm-dark) !important;
}

[data-mui-color-scheme="dark"] .header {
  border-color: var(--dark-border);
  color: var(--dark-text);
}

[data-mui-color-scheme="dark"] .headerIcon {
  color: var(--dark-primary);
}

[data-mui-color-scheme="dark"] .formGroup :global(.MuiTypography-subtitle2) {
  color: var(--dark-text-light);
}

[data-mui-color-scheme="dark"] .dateInput {
  background-color: var(--dark-foreground);
  border-color: var(--dark-border);
  color: var(--dark-text);
}

[data-mui-color-scheme="dark"] .dateInput:focus {
  border-color: var(--dark-primary);
  box-shadow: 0 0 0 2px var(--dark-focus-ring);
}

[data-mui-color-scheme="dark"] .select :global(.MuiOutlinedInput-notchedOutline) {
  border-color: var(--dark-border) !important;
}

[data-mui-color-scheme="dark"] .select:hover :global(.MuiOutlinedInput-notchedOutline) {
  border-color: var(--dark-primary) !important;
}

[data-mui-color-scheme="dark"] .select :global(.MuiOutlinedInput-input) {
  color: var(--dark-text);
}

[data-mui-color-scheme="dark"] .input :global(.MuiOutlinedInput-root),
[data-mui-color-scheme="dark"] .textArea :global(.MuiOutlinedInput-root) {
  background-color: var(--dark-foreground);
}

[data-mui-color-scheme="dark"] .input :global(.MuiOutlinedInput-input),
[data-mui-color-scheme="dark"] .textArea :global(.MuiOutlinedInput-input) {
  color: var(--dark-text);
}

[data-mui-color-scheme="dark"] .fileUpload {
  background-color: var(--dark-background);
  border-color: var(--dark-border);
}

[data-mui-color-scheme="dark"] .uploadIcon {
  color: var(--dark-text-light);
}

[data-mui-color-scheme="dark"] .fileInput {
  color: var(--dark-text);
}

[data-mui-color-scheme="dark"] .submitButton {
  background-color: var(--dark-primary) !important;
}

[data-mui-color-scheme="dark"] .submitButton:hover {
  background-color: var(--dark-accent) !important;
}

[data-mui-color-scheme="dark"] .submitButton:disabled {
  background-color: var(--dark-foreground-accent) !important;
  color: var(--dark-text-light) !important;
}

[data-mui-color-scheme="light"] .submitButton:disabled {
  background-color: var(--light-foreground-accent) !important;
  color: var(--light-text-light) !important;
}