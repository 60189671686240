.card {
  width: 200px;
  border-radius: 12px;
  color: #C2C7CF;
  background-color: #1D2024;
  padding: 16px;
  cursor: pointer;
}

.label {
  margin-top: 8px;
  font-size: 12px;
  border-radius: 4px;
  padding: 4px 10px;
  width: fit-content;
}

.subHeading {
  font-size: 14px;
}

.actionsContainer {
  width: 100%;
  display: flex;
  margin-top: 24px;
  flex-direction: row-reverse;
}

.action {
  width: fit-content;
  color: #1D2024;
}