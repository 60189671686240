/* src/styles/MobileNavigation.module.css */
.mobileNav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 64px;
  background-color: var(--light-foreground);
  display: flex;
  justify-content: space-around;
  padding: 8px 0;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

[data-mui-color-scheme="dark"] .mobileNav {
  background-color: var(--dark-foreground) !important;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.2);
}

.navItem {
  display: flex;
  flex-direction: column; /* Stack icon and label vertically */
  align-items: center; /* Center align horizontally */
  justify-content: center; /* Center align vertically within the container */
  text-decoration: none;
  color: var(--light-text);
  padding: 0; /* Remove extra padding to center better */
  border: none;
  background: none; /* Remove background */
  cursor: pointer;
  width: auto;
  min-width: 56px;
  height: 100%; /* Make it span the full height of the .mobileNav */
  transition: color 0.2s ease; /* Smooth transition for hover and active states */
}

[data-mui-color-scheme="dark"] .navItem {
  color: var(--dark-primary);
  background: none; /* Explicitly ensure no background in dark mode */
}

.icon {
  font-size: 24px;
  margin-bottom: 4px;
}

.label {
  font-size: 12px;
  text-align: center;
}

.active {
  color: var(--light-primary); /* Highlight active item */
}

[data-mui-color-scheme="dark"] .active {
  color: var(--dark-accent); /* Highlight active item in dark mode */
}

/* Ensure no background is added on hover */
.navItem:hover {
  background: none;
}

/* Style for the Menu Dialog */
.menuDialog {
  margin-bottom: 56px;
}

  
  /* iOS safe area support */
  @supports (padding: env(safe-area-inset-bottom)) {
    .mobileNav {
      padding-bottom: calc(8px + env(safe-area-inset-bottom));
    }
  }
  
  /* Hide on desktop */
  @media (min-width: 1024px) {
    .mobileNav {
      display: none;
    }
  }