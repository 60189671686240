/* Base Modal Styling */
.modalTitle {
  background: linear-gradient(135deg, var(--light-primary), var(--light-accent));
  color: white;
  padding: 1.5rem 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Content Layout */
.modalContent {
  padding: 1.5rem;
  display: grid;
  gap: 1.5rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Form Sections */
.formSection {
  background: var(--light-foreground-accent);
  border: 1px solid var(--light-border);
  border-radius: 8px;
  padding: 1.5rem;
}

.sectionTitle {
  font-size: 1rem;
  font-weight: 500;
  color: var(--light-text);
  margin-bottom: 1rem;
}

/* Task List */
.taskList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0;
}

.taskItem {
  display: flex;
  gap: 1rem;
  align-items: center;
}

/* Input Fields */
.inputField {
  background-color: var(--light-foreground);
  border-radius: 4px;
  transition: all 0.2s ease;
}

.inputField:hover {
  background-color: var(--light-foreground);
}

/* Buttons */
.closeButton,
.cancelButton,
.submitButton,
.addTaskButton {
  border-radius: 4px !important;
  text-transform: none !important;
  font-weight: 500 !important;
  padding: 8px 16px !important;
  height: 36px !important;
  min-width: 40px !important;
  font-size: 14px !important;
}

.closeButton {
  background: var(--light-secondary) !important;
  border: 1px solid var(--light-secondary) !important;
  color: white !important;
  margin: -8px !important;
}

.closeButton:hover {
  background: var(--light-secondary-accent) !important;
  box-shadow: var(--shadow-md-light) !important;
}

.addTaskButton {
  background: var(--light-secondary) !important;
  color: white !important;
  margin-top: 1rem !important;
}

.addTaskButton:hover {
  background: var(--light-secondary-accent) !important;
  box-shadow: var(--shadow-md-light) !important;
}

.iconButton {
  color: var(--light-text-light) !important;
}

.iconButton:hover {
  color: var(--error) !important;
}

/* Modal Actions */
.modalActions {
  padding: 1.5rem !important;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  background: var(--light-foreground-accent);
  border-top: 1px solid var(--light-border);
}

.cancelButton {
  background-color: var(--error) !important;
  color: white !important;
  border: 1px solid var(--error) !important;
  transition: all 0.2s ease;
}

.cancelButton:hover {
  background-color: #f06c6c !important;
  border-color: var(--error) !important;
  box-shadow: var(--shadow-md-light) !important;
}

.submitButton {
  background: linear-gradient(135deg, var(--light-primary), var(--light-accent)) !important;
  color: white !important;
  border: none !important;
  transition: all 0.2s ease !important;
  box-shadow: var(--shadow-sm-light) !important;
}

.submitButton:hover {
  background: linear-gradient(135deg, var(--light-accent), var(--light-primary)) !important;
  box-shadow: var(--shadow-md-light) !important;
  transform: translateY(-1px);
}

/* Dark Mode Styles */
[data-mui-color-scheme="dark"] .modalTitle {
  background: linear-gradient(135deg, var(--dark-primary), var(--dark-accent));
}

[data-mui-color-scheme="dark"] .formSection {
  background: var(--dark-foreground-accent);
  border-color: var(--dark-border);
}

[data-mui-color-scheme="dark"] .sectionTitle {
  color: var(--dark-text);
}

[data-mui-color-scheme="dark"] .inputField {
  background-color: var(--dark-text-area);
}

[data-mui-color-scheme="dark"] .inputField:hover {
  background-color: var(--dark-text-hover);
}

[data-mui-color-scheme="dark"] .closeButton,
[data-mui-color-scheme="dark"] .addTaskButton {
  background: var(--dark-secondary) !important;
  border-color: var(--dark-secondary) !important;
}

[data-mui-color-scheme="dark"] .closeButton:hover,
[data-mui-color-scheme="dark"] .addTaskButton:hover {
  background: var(--dark-secondary-accent) !important;
  box-shadow: var(--shadow-md-dark) !important;
}

[data-mui-color-scheme="dark"] .modalActions {
  background: var(--dark-foreground-accent);
  border-color: var(--dark-border);
}

[data-mui-color-scheme="dark"] .submitButton {
  background: linear-gradient(135deg, var(--dark-primary), var(--dark-accent)) !important;
  box-shadow: var(--shadow-sm-dark) !important;
}

[data-mui-color-scheme="dark"] .submitButton:hover {
  background: linear-gradient(135deg, var(--dark-accent), var(--dark-primary)) !important;
  box-shadow: var(--shadow-md-dark) !important;
} 