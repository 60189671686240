/* src/styles/EmailTemplateModal.module.css */

.userModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(4px);
    padding: 1rem;
  }
  
  .userModalContent {
    background-color: var(--light-background);
    color: var(--light-text);
    padding: 2rem;
    border-radius: var(--border-radius-base);
    max-width: 800px;
    width: 100%;
    max-height: 90vh;
    position: relative;
    box-shadow: var(--shadow-lg-light);
    border: 1px solid var(--light-border);
    transition: all var(--transition-duration) var(--transition-ease);
    display: flex;
    flex-direction: column;
  }
  
  .userModalTitle {
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--light-text);
    flex-shrink: 0;
  }
  
  .userForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: auto;
    padding-right: 1rem;
  }
  
  .userFormGroup {
    margin-bottom: 1rem;
  }
  
  .userFormGroup label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--light-text);
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .userFormGroup input,
  .userFormGroup select {
    width: 100%;
    padding: 0.75rem;
    background-color: var(--light-foreground);
    color: var(--light-text);
    border: 1px solid var(--light-border);
    border-radius: var(--border-radius-base);
    font-size: 0.875rem;
    transition: all var(--transition-duration) var(--transition-ease);
  }
  
  .userFormGroup input:focus,
  .userFormGroup select:focus {
    outline: none;
    border-color: var(--light-primary);
    box-shadow: 0 0 0 2px var(--light-focus-ring);
  }
  
  .variablesSection {
    margin-bottom: 1rem;
  }
  
  .variableChips {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }
  
  .variableChip {
    cursor: pointer;
    transition: all var(--transition-duration) var(--transition-ease);
  }
  
  .variableChip:hover {
    background-color: var(--light-primary);
    color: white;
  }
  
  .editor {
    height: 300px;
    margin-bottom: 2rem;
  }
  
  .editor :global(.ql-editor) {
    min-height: 200px;
    font-size: 0.875rem;
  }
  
  .userButtonGroup {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid var(--light-border);
    flex-shrink: 0;
  }
  
  .button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: var(--border-radius-base);
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 500;
    transition: background-color var(--transition-duration) var(--transition-ease);
  }
  
  .userButtonPrimary {
    background-color: var(--light-primary);
    color: white;
  }
  
  .userButtonPrimary:hover {
    background-color: var(--light-accent);
  }
  
  .userButtonSecondary {
    background-color: var(--light-secondary);
    color: white;
  }
  
  .userButtonSecondary:hover {
    background-color: var(--dark-secondary-accent);
  }
  
  .button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  .inputWithVariable {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .inputWithVariable input {
    flex: 1;
  }
  
  .variablesSection {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  /* Dark Mode */
  [data-mui-color-scheme='dark'] .userModalContent {
    background-color: var(--dark-background);
    color: var(--dark-text);
    box-shadow: var(--shadow-lg-dark);
    border-color: var(--dark-border);
  }
  
  [data-mui-color-scheme='dark'] .userModalTitle {
    color: var(--dark-text);
  }
  
  [data-mui-color-scheme='dark'] .userFormGroup label {
    color: var(--dark-text);
  }
  
  [data-mui-color-scheme='dark'] .userFormGroup input,
  [data-mui-color-scheme='dark'] .userFormGroup select {
    background-color: var(--dark-text-area);
    color: var(--dark-text);
    border-color: var(--dark-border);
  }
  
  [data-mui-color-scheme='dark'] .userFormGroup input:focus,
  [data-mui-color-scheme='dark'] .userFormGroup select:focus {
    border-color: var(--dark-primary);
    box-shadow: 0 0 0 2px var(--dark-focus-ring);
  }
  
  [data-mui-color-scheme='dark'] .userButtonPrimary {
    background-color: var(--dark-primary);
  }
  
  [data-mui-color-scheme='dark'] .userButtonPrimary:hover {
    background-color: var(--dark-accent);
  }
  
  [data-mui-color-scheme='dark'] .userButtonSecondary {
    background-color: var(--dark-secondary);
  }
  
  [data-mui-color-scheme='dark'] .userButtonSecondary:hover {
    background-color: var(--dark-secondary-accent);
  }
  
  [data-mui-color-scheme='dark'] .editor {
    background-color: var(--dark-text-area);
    border-color: var(--dark-border);
  }
  
  [data-mui-color-scheme='dark'] .editor :global(.ql-toolbar),
  [data-mui-color-scheme='dark'] .editor :global(.ql-container) {
    border-color: var(--dark-border) !important;
    background-color: transparent !important;
  }
  
  [data-mui-color-scheme='dark'] .variableChip {
    background-color: var(--dark-secondary);
    color: var(--dark-text);
  }
  
  [data-mui-color-scheme='dark'] .variableChip:hover {
    background-color: var(--dark-primary);
  }

  [data-mui-color-scheme='dark'] .editor :global(.ql-toolbar .ql-stroke) {
    stroke: var(--dark-text) !important;
  }
  
  [data-mui-color-scheme='dark'] .editor :global(.ql-toolbar .ql-fill) {
    fill: var(--dark-text) !important;
  }
  
  [data-mui-color-scheme='dark'] .editor :global(.ql-toolbar .ql-picker) {
    color: var(--dark-text) !important;
  }

  [data-mui-color-scheme='dark'] .editor :global(.ql-editor) {
    background-color: var(--dark-text-area);
    color: var(--dark-text);
  }
  
  /* Remove the extra border */
  [data-mui-color-scheme='dark'] .editor {
    border: none !important;
    background-color: transparent !important;
  }

  [data-mui-color-scheme='dark'] .variablesSection .MuiOutlinedInput-root {
    background-color: var(--dark-text-area);
    color: var(--dark-text);
  }
  
  [data-mui-color-scheme='dark'] .variablesSection .MuiInputLabel-root {
    color: var(--dark-text);
  }
  
  [data-mui-color-scheme='dark'] .variablesSection .MuiOutlinedInput-notchedOutline {
    border-color: var(--dark-border);
  }
  
  [data-mui-color-scheme='dark'] .variablesSection .MuiSvgIcon-root {
    color: var(--dark-text);
  }
  
  [data-mui-color-scheme='dark'] .MuiMenu-paper {
    background-color: var(--dark-background);
    color: var(--dark-text);
  }
  
  [data-mui-color-scheme='dark'] .MuiMenuItem-root {
    color: var(--dark-text);
  }
  
  [data-mui-color-scheme='dark'] .MuiMenuItem-root.Mui-disabled {
    color: var(--dark-text-secondary);
  }