.cleanerItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    margin: 4px 0;
    background-color: var(--light-foreground);
    border-radius: 4px;
  }
  
  [data-mui-color-scheme='dark'] .cleanerItem {
    background-color: var(--dark-text-area);
  }
  
  .removeButton {
    padding: 4px 8px;
    background-color: var(--error);
    color: var(--error-content);
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .removeButton:hover {
    opacity: 0.9;
  }
  
  .button {
    margin-bottom: 16px;
  }