/* src/styles/CleanerJobsOverview.module.css */

.container {
  padding: 0;
  min-height: 100vh;
  background-color: var(--light-background);
  color: var(--light-text);
  font-family: var(--font-family-base);
  padding-bottom: 72px;
}

[data-mui-color-scheme="dark"] .container {
  background-color: var(--dark-background);
  color: var(--dark-text);
}

.dateHeader {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: var(--light-foreground);
  border-bottom: 1px solid var(--light-border);
  box-shadow: var(--shadow-sm-light);
}

[data-mui-color-scheme="dark"] .dateHeader {
  background-color: var(--dark-foreground) !important;
  border-bottom: 1px solid var(--dark-border) !important;
  box-shadow: var(--shadow-sm-dark) !important;
}

.monthRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: var(--light-foreground); /* Match job card background in light mode */
}

[data-mui-color-scheme="dark"] .monthRow {
  background-color: var(--dark-foreground) !important; /* Override in dark mode */
}

.monthTitle {
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--light-text);
  margin-right: auto; /* Push title to the left */
}

[data-mui-color-scheme="dark"] .monthTitle {
  color: var(--dark-text);
}

.headerControls {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: auto; /* Push controls to the right */
}

.todayButton {
  background-color: var(--light-primary);
  color: white;
  border: none;
  padding: 6px 16px;
  border-radius: var(--border-radius-base);
  cursor: pointer;
  transition: background-color var(--transition-duration) var(--transition-ease);
}

[data-mui-color-scheme="dark"] .todayButton {
  background-color: var(--dark-primary);
}

.navigationButtons {
  display: flex;
  gap: 4px;
}

.navButton {
  width: 25px; /* Set square dimensions */
  height: 25px;
  padding: 8px;
  background: none;
  border: 1px solid var(--light-border); /* Match card style in light mode */
  cursor: pointer;
  color: var(--light-text);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px; /* Make buttons square */
  transition: background-color var(--transition-duration) var(--transition-ease);
}

[data-mui-color-scheme="dark"] .navButton {
  color: var(--dark-text);
  border: 1px solid var(--dark-border); /* Match card style in dark mode */
}

.navButton:hover {
  background-color: var(--light-foreground-accent); /* Hover effect in light mode */
}

[data-mui-color-scheme="dark"] .navButton:hover {
  background-color: var(--dark-foreground-accent); /* Hover effect in dark mode */
}

.weekDays {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  background-color: var(--light-background);
}

[data-mui-color-scheme="dark"] .weekDays {
  background-color: var(--dark-background);
}

.dayColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.dayName {
  font-size: 0.75rem;
  color: var(--light-text-light);
  margin-bottom: 4px;
}

[data-mui-color-scheme="dark"] .dayName {
  color: var(--dark-text-light);
}

.dayNumber {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 0.875rem;
  color: var(--light-text); /* Ensure text is visible */
  background-color: transparent; /* No background by default */
  transition: all var(--transition-duration) var(--transition-ease);
}

[data-mui-color-scheme="dark"] .dayNumber {
  color: var(--dark-text); /* Ensure text is visible in dark mode */
}

.dayNumber.today {
  color: var(--light-primary); /* Primary color for today's text */
  font-weight: 500;
}

[data-mui-color-scheme="dark"] .dayNumber.today {
  color: var(--dark-primary);
}

.dayNumber.selected {
  background-color: var(--light-primary); /* Circle background for selected */
  color: white; /* Ensure the number is visible on the background */
  font-weight: 600; /* Make the number bold for better readability */
}

[data-mui-color-scheme="dark"] .dayNumber.selected {
  background-color: var(--dark-primary);
  color: white; /* Same in dark mode */
}

.jobCard {
  margin: 12px 4px;
  background-color: var(--light-foreground);
  border: 1px solid var(--light-border);
  border-radius: var(--border-radius-base);
  transition: transform var(--transition-duration) var(--transition-ease),
              box-shadow var(--transition-duration) var(--transition-ease);
  box-shadow: var(--shadow-sm-light);
}

/* [data-mui-color-scheme="dark"] .jobCard {
  background-color: var(--dark-foreground);
  border: 1px solid var(--dark-border);
  box-shadow: var(--shadow-sm-dark);
} */

.jobCard:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md-light);
}

[data-mui-color-scheme="dark"] .jobCard:hover {
  box-shadow: var(--shadow-md-dark);
}

.status {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: calc(var(--border-radius-base) / 2);
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
}

.statusCompleted {
  background-color: rgba(70, 235, 70, 0.1);
  color: var(--success);
}

.emptyDay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  background-color: var(--light-foreground-accent);
  border-radius: var(--border-radius-base);
  color: var(--light-text-light);
  font-size: 0.875rem;
}

[data-mui-color-scheme="dark"] .emptyDay {
  background-color: var(--dark-foreground-accent);
  color: var(--dark-text-light);
}
