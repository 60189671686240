/* CleanersReporting.module.css */

:root {
    /* Spacing */
    --spacing-xs: 0.5rem;
    --spacing-sm: 1rem;
    --spacing-md: 1.5rem;
    --spacing-lg: 2rem;
  
    /* Typography */
    --font-sm: 0.875rem;
    --font-base: 1rem;
    --font-lg: 1.25rem;
    
    /* Layout */
    --border-radius-sm: 4px;
    --border-radius-md: 8px;
    --min-column-width: 180px;
    --mobile-breakpoint: 768px;
  }
  
  /* Layout Components */
  .container {
    padding: var(--spacing-md);
    background-color: var(--background);
  }
  
  .headerSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-block-end: var(--spacing-md);
    gap: var(--spacing-md);
  }
  
  .monthSelect {
    min-width: 200px;
  }
  
  /* Cleaner Components */
  .cleanersList {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  .cleanerSection {
    background-color: var(--card-background);
    padding: var(--spacing-md);
    border-radius: var(--border-radius-md);
    border: 1px solid var(--border);
  }
  
  .cleanerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-block-end: var(--spacing-md);
  }
  
  .cleanerInfo {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
  }
  
  /* Metrics Grid */
  .metricsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--min-column-width), 1fr));
    gap: var(--spacing-sm);
    margin-block-end: var(--spacing-md);
  }
  
  .metricCard {
    background-color: var(--background);
    border: 1px solid var(--border);
    border-radius: var(--border-radius-sm);
  }
  
  .metricCardContent {
    padding: var(--spacing-sm);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: var(--spacing-xs);
  }
  
  .metricTitle {
    color: var(--text-secondary);
    font-size: var(--font-sm);
  }
  
  .metricValue {
    color: var(--text-primary);
    font-size: var(--font-lg);
    font-weight: 600;
  }
  
  /* Table Styles */
  .tableContainer {
    background-color: var(--background);
    border: 1px solid var(--border);
    border-radius: var(--border-radius-sm);
    overflow: auto;
    margin-block-start: var(--spacing-sm);
  }
  
  /* Profit Indicators */
  .negativeProfit,
  .positiveProfit {
    font-weight: 600;
  }
  
  .negativeProfit {
    color: var(--error);
  }
  
  .positiveProfit {
    color: var(--success);
  }
  
  /* Loading States */
  .loadingContainer {
    display: grid;
    place-items: center;
    min-height: 16rem;
  }
  
  .loader {
    --loader-size: 2rem;
    --loader-border: 4px;
    
    width: var(--loader-size);
    height: var(--loader-size);
    border: var(--loader-border) solid var(--border);
    border-top-color: var(--primary);
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  
  .errorMessage {
    padding: var(--spacing-sm);
    color: var(--error);
  }
  
  /* Dark Mode */
  @media (prefers-color-scheme: dark) {
    .tableContainer {
      background-color: var(--card-background-dark);
    }
  
    .tableContainer :is(th, td) {
      color: var(--text-primary);
      border-color: var(--border-dark);
    }
  }
  
  /* Responsive Design */
  @media (max-width: var(--mobile-breakpoint)) {
    .container {
      padding: var(--spacing-sm);
    }
  
    .headerSection {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .monthSelect {
      width: 100%;
    }
  
    .metricsGrid {
      --min-column-width: 150px;
    }
  }