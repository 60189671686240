/* PhotoViewer.module.css */
.completionPhotos {
    width: 100%;
    margin-top: 1rem;
  }
  
  .headerActions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
  
  .downloadButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #3B82F6;
    color: white;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .downloadButton:hover {
    background-color: #2563EB;
  }
  
  .downloadButton:disabled {
    background-color: #9CA3AF;
    cursor: not-allowed;
  }

  
.helperText {
    font-size: 0.75rem;
    color: #9CA3AF;
    margin-bottom: 0.5rem;
  }
  
  .photoGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
    margin-bottom: 1rem !important;
  }
  
  .photoThumbnail {
    position: relative;
    aspect-ratio: 1;
    cursor: pointer;
    border-radius: 0.375rem;
    overflow: hidden;
  }
  
  .photoThumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.2s;
  }
  
  .photoThumbnail:hover img {
    opacity: 0.9;
  }
  
  .modalOverlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  
  .modalDialog {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
    max-width: 48rem;
    width: 100%;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .modalTitle {
    font-size: 1.125rem;
    font-weight: 600;
  }
  
  .closeButton {
    padding: 0.25rem;
    border-radius: 9999px;
  }
  
  .closeButton:hover {
    background-color: #f3f4f6;
  }
  
  .imageContainer {
    position: relative;
    flex: 1;
    min-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: #f9fafb;
  }
  
  .mainImage {
    max-height: 60vh;
    object-fit: contain;
    margin: auto;
  }
  
  .navigationButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    border-radius: 9999px;
    padding: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .navigationButton:hover {
    background-color: #f3f4f6;
  }
  
  .prevButton {
    left: 1rem;
  }
  
  .nextButton {
    right: 1rem;
  }
  
  .modalFooter {
    border-top: 1px solid #e5e7eb;
    padding: 1rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  .zoomButton {
    padding: 0.5rem;
    border-radius: 9999px;
  }
  
  .zoomButton:hover {
    background-color: #f3f4f6;
  }
  
  .loadingText {
    text-align: center;
    padding: 1rem;
    color: #6b7280;
  }
  
  .noPhotosText {
    color: #6b7280;
  }

  [data-mui-color-scheme='dark'] .noPhotosText {
    color: #9db5cc;
  }

  .showMoreButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 1rem auto;
    padding: 0.5rem 1rem;
    background-color: transparent;
    color: rgb(156 163 175);
    border: 1px solid rgb(209 213 219);
    border-radius: 0.375rem;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .showMoreButton:hover {
    background-color: rgb(243 244 246);
    border-color: rgb(156 163 175);
  }