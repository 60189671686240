.filterCenter {
  min-width: 200px;
  background-color: #191C20;
  padding: 16px 24px;
  border-radius: 12px;
  margin: 10px;
}

.statusSection {
  h3 {
    margin: 0;
    font-weight: 400;
  }

  ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;

    li {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }
}