/* JobsOverview.module.css */

.JobsOverview {
  max-width: 100%;
  margin: 1.5rem auto;
  background-color: var(--light-foreground);
  color: var(--light-text);
  border-radius: 8px;
}

/* Filter Section */
.searchSection {
  margin-bottom: 1.5rem;
}

.filterContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem;
  background-color: var(--light-background);
  border: 1px solid var(--light-border);
  border-radius: 6px;
}

.inputGroup {
  flex: 2;
}

.label {
  font-weight: 500;
  color: var(--light-text);
}

.dateGroup {
  /* width: 400px; */
  flex: 1;
}

.statusGroup {
  /* min-width: 300px; */
  flex: 2;
}

.input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--light-border);
  border-radius: 4px;
  background-color: var(--light-foreground-accent);
  color: var(--light-text);
}

.input:focus {
  outline: none;
  border-color: var(--light-primary);
  box-shadow: 0 0 0 2px rgba(var(--light-primary-rgb), 0.1);
}

/* Status Filter */
.statusSelect {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
}

.statusCheckbox {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
}

.statusCheckbox input {
  display: none;
}

.statusLabel {
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  font-size: 0.875rem;
  color: white;
  opacity: 0.6;
  transition: opacity 0.2s ease;
}

.statusCheckbox input:checked + .statusLabel {
  opacity: 1;
}

/* Weekly View */
.weeklyView {
  margin-top: 1.5rem;
  background-color: var(--light-background);
  border: 1px solid var(--light-border);
  border-radius: 6px;
  padding: 1rem;
}

/* Modal Styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Message Styles */
.errorMessage, .successMessage {
  padding: 1rem;
  border-radius: 6px;
  margin-bottom: 1rem;
}

.errorMessage {
  background-color: var(--error);
  color: var(--error-content);
}

.successMessage {
  background-color: var(--success);
  color: var(--success-content);
}

/* Dark Mode */
[data-mui-color-scheme="dark"] .JobsOverview {
  background-color: var(--dark-foreground);
  color: var(--dark-text);
}

[data-mui-color-scheme="dark"] .filterContainer,
[data-mui-color-scheme="dark"] .weeklyView {
  background-color: var(--dark-foreground-accent);
  border-color: var(--dark-border);
}

[data-mui-color-scheme="dark"] .input {
  background-color: var(--dark-foreground-accent);
  border-color: var(--dark-border);
  color: var(--dark-text);
}

[data-mui-color-scheme="dark"] .input:focus {
  border-color: var(--dark-primary);
  box-shadow: 0 0 0 2px rgba(var(--dark-primary-rgb), 0.2);
}

[data-mui-color-scheme="dark"] .label {
  color: var(--dark-text);
}

.JobsOverview :global(.MuiDialogTitle-root) {
  padding: 20px 24px;
  background-color: var(--light-foreground);
  color: var(--light-text);
}

[data-mui-color-scheme="dark"] :global(.MuiDialogTitle-root) {
  background-color: var(--dark-foreground);
  color: var(--dark-text);
}

.JobsOverview :global(.MuiDialogContent-root) {
  padding: 24px;
}

.JobsOverview :global(.MuiDialogActions-root) {
  padding: 16px 24px;
  background-color: var(--light-foreground);
}

[data-mui-color-scheme="dark"] :global(.MuiDialogActions-root) {
  background-color: var(--dark-foreground);
}

/* Job Details */

.jobDetailsPaper {
  padding: 24px;
  border-radius: var(--border-radius-base);
  background-color: var(--light-background);
  color: var(--light-text);
}

[data-mui-color-scheme='dark'] .jobDetailsPaper {
  background-color: var(--dark-background);
  color: var(--dark-text);
}

.jobDetails {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.jobDetails ul {
  list-style-type: none;
  padding-left: 16px;
  margin: 8px 0;
}

.jobDetails li {
  margin-bottom: 8px;
}

/* Error Message */
.errorMessage {
  color: var(--error-content);
  padding: 16px;
  margin: 16px 0;
  border-radius: var(--border-radius-base);
  background-color: var(--error);
}

/* Icon Button */
.JobsOverview :global(.MuiIconButton-root) {
  color: var(--light-text);
}

[data-mui-color-scheme="dark"] :global(.MuiIconButton-root) {
  color: var(--dark-text-light);
}

/* Chip */
.JobsOverview :global(.MuiChip-root) {
  border-radius: 4px;
  font-weight: 500;
}

.container {
  gap: 16px;
  display: flex;
  flex-direction: row;
}

/* Responsive Design */
@media (max-width: 960px) {
  .filterContainer {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 600px) {
  .JobsOverview {
    padding: 1rem;
    margin: 1rem;
  }
  
  .statusSelect {
    flex-direction: column;
  }
}

/* Notification base styles */
.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  max-width: 100px;
  padding: 12px;
  border-radius: 6px;
  font-size: 14px;
  z-index: 1000;
  animation: slideIn 0.3s ease-out, fadeOut 0.5s ease-out 2.5s;
  opacity: 0.9;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.notificationContent {
  display: flex;
  align-items: center;
  gap: 8px;
}

.notificationIcon {
  font-size: 16px;
  flex-shrink: 0;
}

/* Success message specific styles */
.successMessage {
  background-color: var(--success);
  color: var(--success-content);
}

.successMessage .notificationIcon {
  color: var(--success-content);
}

/* Error message specific styles */
.errorMessage {
  background-color: var(--error);
  color: var(--error-content);
}

.errorMessage .notificationIcon {
  color: var(--error-content);
}

/* Animations */
@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 0.9;
  }
}

@keyframes fadeOut {
  from {
    opacity: 0.9;
  }
  to {
    opacity: 0;
  }
}