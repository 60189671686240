/* /src/styles/UserModal.module.css */

.userModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
  padding: 1rem; 
  }
  
  .userModalContent {
    background-color: var(--light-background);
    color: var(--light-text);
    padding: 2rem;
    border-radius: var(--border-radius-base);
    max-width: 600px;
    width: 100%;
    max-height: 90vh; /* Limit height to 90% of viewport height */
    position: relative;
    box-shadow: var(--shadow-lg-light);
    border: 1px solid var(--light-border);
    transition: all var(--transition-duration) var(--transition-ease);
    display: flex;
    flex-direction: column;
}
  
  .userModalTitle {
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--light-text);
    flex-shrink: 0;
  }
  
  .userForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: auto;
    padding-right: 1rem;
  }
  
  .userFormRow {
    display: flex;
    gap: 1rem;
  }
  
  .userFormGroup {
    flex: 1;
    margin-bottom: 0.5rem;
  }
  
  .userFormGroup label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--light-text);
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .userFormGroup input,
  .userFormGroup select {
    width: 100%;
    padding: 0.75rem;
    background-color: var(--light-foreground);
    color: var(--light-text);
    border: 1px solid var(--light-border);
    border-radius: var(--border-radius-base);
    font-size: 0.875rem;
    transition: all var(--transition-duration) var(--transition-ease);
  }
  
  .userFormGroup input:focus,
  .userFormGroup select:focus {
    outline: none;
    border-color: var(--light-primary);
    box-shadow: 0 0 0 2px var(--light-focus-ring);
  }
  
  .userFormGroup input:disabled {
    background-color: var(--light-foreground-accent);
    cursor: not-allowed;
  }
  
  .userButtonGroup {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid var(--light-border);
    flex-shrink: 0;
}
  
  .button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: var(--border-radius-base);
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 500;
    transition: background-color var(--transition-duration) var(--transition-ease);
  }
  
  .userButtonPrimary {
    background-color: var(--light-primary);
    color: white;
  }
  
  .userButtonPrimary:hover {
    background-color: var(--light-accent);
  }
  
  .userButtonSecondary {
    background-color: var(--light-secondary);
    color: white;
  }
  
  .userButtonSecondary:hover {
    background-color: var(--dark-secondary-accent);
  }
  
  .button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  /* Icon Button Base Styles */
.iconButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 0;
    border: none;
    border-radius: var(--border-radius-base);
    cursor: pointer;
    transition: all var(--transition-duration) var(--transition-ease);
    background: transparent;
  }
  
  .iconButton:hover {
    transform: translateY(-1px);
  }
  
  .iconButton:active {
    transform: translateY(0);
  }
  
  /* Primary Icon Button */
  .iconButtonPrimary {
    color: var(--light-primary);
  }
  
  .iconButtonPrimary:hover {
    color: var(--light-accent);
    background-color: var(--light-focus-ring);
  }
  
  /* Danger Icon Button */
  .iconButtonDanger {
    color: var(--error);
  }
  
  .iconButtonDanger:hover {
    color: var(--error);
    background-color: rgba(235, 70, 70, 0.1);
  }

  /* Add styling for the scrollbar */
.userForm::-webkit-scrollbar {
  width: 8px;
}

.userForm::-webkit-scrollbar-track {
  background: var(--light-foreground);
  border-radius: 4px;
}

.userForm::-webkit-scrollbar-thumb {
  background: var(--light-secondary);
  border-radius: 4px;
}

.userForm::-webkit-scrollbar-thumb:hover {
  background: var(--light-accent);
}

.workingHours {
  margin-top: 1rem;
  padding: 1rem;
}

.workingHoursRow {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.workingHoursRow input {
  flex: 1;
  padding: 0.5rem;
}

.copyButton {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: var(--light-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-base);
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
}

.copyButton:hover {
  background-color: var(--light-accent);
}

.colorPickerContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.colorPicker {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.colorPicker::-webkit-color-swatch-wrapper {
  padding: 0;
}

.colorPicker::-webkit-color-swatch {
  border: 2px solid #e5e7eb;
  border-radius: 8px;
}

.colorPicker::-moz-color-swatch {
  border: 2px solid #e5e7eb;
  border-radius: 8px;
}

.colorValue {
  font-size: 0.875rem;
  color: #6b7280;
}
  
  /* Dark Mode */
  [data-mui-color-scheme='dark'] .userModalContent {
    background-color: var(--dark-background);
    color: var(--dark-text);
    box-shadow: var(--shadow-lg-dark);
    border-color: var(--dark-border);
  }
  
  [data-mui-color-scheme='dark'] .userModalTitle {
    color: var(--dark-text);
  }
  
  [data-mui-color-scheme='dark'] .userFormGroup label {
    color: var(--dark-text);
  }
  
  [data-mui-color-scheme='dark'] .userFormGroup input,
  [data-mui-color-scheme='dark'] .userFormGroup select {
    background-color: var(--dark-text-area);
    color: var(--dark-text);
    border-color: var(--dark-border);
  }
  
  [data-mui-color-scheme='dark'] .userFormGroup input:focus,
  [data-mui-color-scheme='dark'] .userFormGroup select:focus {
    border-color: var(--dark-primary);
    box-shadow: 0 0 0 2px var(--dark-focus-ring);
  }
  
  [data-mui-color-scheme='dark'] .userFormGroup input:disabled {
    background-color: var(--dark-foreground-accent);
  }
  
  [data-mui-color-scheme='dark'] .userButtonPrimary {
    background-color: var(--dark-primary);
  }
  
  [data-mui-color-scheme='dark'] .userButtonPrimary:hover {
    background-color: var(--dark-accent);
  }
  
  [data-mui-color-scheme='dark'] .userButtonSecondary {
    background-color: var(--dark-secondary);
  }
  
  [data-mui-color-scheme='dark'] .userButtonSecondary:hover {
    background-color: var(--dark-secondary-accent);
  }

  /* Dark Mode Overrides */
  [data-mui-color-scheme='dark'] .iconButtonPrimary {
    color: var(--dark-primary);
  }
  
  [data-mui-color-scheme='dark'] .iconButtonPrimary:hover {
    color: var(--dark-accent);
    background-color: var(--dark-focus-ring);
  }
  
  [data-mui-color-scheme='dark'] .iconButtonDanger {
    color: var(--error);
  }
  
  [data-mui-color-scheme='dark'] .iconButtonDanger:hover {
    background-color: rgba(235, 70, 70, 0.2);
  }

  [data-mui-color-scheme='dark'] .userForm::-webkit-scrollbar-track {
    background: var(--dark-foreground);
}

[data-mui-color-scheme='dark'] .userForm::-webkit-scrollbar-thumb {
    background: var(--dark-secondary);
}

[data-mui-color-scheme='dark'] .userForm::-webkit-scrollbar-thumb:hover {
    background: var(--dark-accent);
}

/* Dark Mode for Working Hours Section */
[data-mui-color-scheme='dark'] .workingHours {
  background-color: var(--dark-background);
  color: var(--dark-text);
}

[data-mui-color-scheme='dark'] .workingHoursRow input {
  background-color: var(--dark-text-area);
  color: var(--dark-text);
  border: 1px solid var(--dark-border);
}

[data-mui-color-scheme='dark'] .workingHoursRow input:focus {
  border-color: var(--dark-primary);
  box-shadow: 0 0 0 2px var(--dark-focus-ring);
}

[data-mui-color-scheme='dark'] .copyButton {
  background-color: var(--dark-primary);
  color: var(--dark-text);
}

[data-mui-color-scheme='dark'] .copyButton:hover {
  background-color: var(--dark-accent);
}
